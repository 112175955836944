import { IxSpinner } from '@siemens/ix-react'
import { JSX, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

export function LogoutPage(): JSX.Element {
  const { signoutRedirect } = useAuth()

  useEffect(() => {
    signoutRedirect()
  }, [signoutRedirect])

  return <IxSpinner />
}
