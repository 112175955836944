import { DateTime } from 'luxon'

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export interface TrackConfigurationFilterOpts {
  areaName: string
  interlockingName: string
  technicalRoomName: string
  turnoutName: string
}

export interface ThresholdConfigurationFilterOpts {
  thresholdConfiguration: 'complete' | 'incomplete'
}

export interface PaginationFilterOpts {
  page: number
  size: number
  sortBy: string
  sortingDirection: 'asc' | 'desc'
}

export interface DateRangeFilterOpts {
  from: DateTime
  to: DateTime
}
