import { SampleEntry } from 'models'
import type { RouteConfig } from './index'

export const sampleEntriesRoute: RouteConfig<'/sample-entries'> = {
  icon: 'list',
  name: 'Sample Entries',
  pathname: '/sample-entries',
}

export const sampleEntryRoute: RouteConfig<
  `${typeof sampleEntriesRoute.pathname}/:sampleEntryId`,
  typeof sampleEntriesRoute,
  SampleEntry | null
> = {
  icon: 'item-details',
  name: 'Sample Entry',
  parent: sampleEntriesRoute,
  pathname: `${sampleEntriesRoute.pathname}/:sampleEntryId`,
  render: function (sampleEntry) {
    return sampleEntry?.name ?? this.name
  },
}

export const createSampleEntryRoute: RouteConfig<
  `${typeof sampleEntriesRoute.pathname}/create`,
  typeof sampleEntriesRoute,
  SampleEntry | null
> = {
  icon: 'add',
  name: 'Create Sample Entry',
  parent: sampleEntriesRoute,
  pathname: `${sampleEntriesRoute.pathname}/create`,
  render: function () {
    return this.name
  },
}
