import { IxButton, IxContentHeader } from '@siemens/ix-react'
import { QueryPointMachineEventsFilterOpts } from 'api'
import DataFilter, { Filter } from 'components/DataFilter'
import ErrorInfo from 'components/ErrorInfo'
import EventDirectionCell from 'components/PointMachineEventCharts/components/EventDirectionCell'
import Table, { TableProps } from 'components/Table'
import {
  useFilterOpts,
  usePointMachineSearchFields,
  useQueryPointMachineEvents,
  useUpdatePointMachineEvents,
} from 'hooks'
import { DateTime } from 'luxon'
import { PointMachineEvent } from 'models'
import { JSX, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const PAGE_SIZE = 15

export interface ReferenceCurveConfigProps {}

export function ReferenceCurveConfig(props: ReferenceCurveConfigProps): JSX.Element {
  const [selectedPmEvents, setSelectedPmEvents] = useState<PointMachineEvent[]>()
  const { t, i18n } = useTranslation()

  const [filters, setFilters] = useState<Filter[]>([])
  const [sortBy, setSortBy] = useState<string | undefined>('start')
  const [sortingDirection, setSortingDirection] = useState<
    QueryPointMachineEventsFilterOpts['sortingDirection'] | undefined
  >('desc')
  const [pageNo, setPageNo] = useState(0)
  const filterOpts = useFilterOpts<QueryPointMachineEventsFilterOpts>(filters, {
    page: pageNo,
    reference: true,
    size: PAGE_SIZE,
    sortBy,
    sortingDirection,
  })

  const { data: pmEvents, ...pmEventsData } = useQueryPointMachineEvents(filterOpts)
  const { trigger: updatePmEvents, ...updatePmEventsData } = useUpdatePointMachineEvents()

  const onSelectionChanged = useCallback<NonNullable<TableProps<PointMachineEvent>['onSelectionChanged']>>(
    ({ api }) => {
      const pointMachines = api.getSelectedRows()
      setSelectedPmEvents(pointMachines.length === 0 ? undefined : pointMachines)
    },
    [],
  )

  const onSort = useCallback<NonNullable<TableProps<PointMachineEvent>['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortBy(sortedColumn?.colId)
    setSortingDirection(sortedColumn?.sort ?? undefined)
  }, [])

  const onDeleteReferences = useCallback(async () => {
    if (selectedPmEvents != null) {
      await updatePmEvents(selectedPmEvents.map((entry) => ({ ...entry, reference: false })))
      await pmEventsData.mutate()
      setSelectedPmEvents(undefined)
    }
  }, [pmEventsData, selectedPmEvents, updatePmEvents])

  return (
    <>
      <IxContentHeader className="mb-4" headerTitle={t('Manage reference curves') ?? undefined} />

      {updatePmEventsData.error != null && <ErrorInfo className="mb-5" error={updatePmEventsData.error} />}

      <DataFilter
        className="mb-5"
        fields={[
          ...usePointMachineSearchFields(filters),
          {
            icon: 'search',
            label: t('Search for turnout ID or PM ID'),
            path: 'engineeringId',
            type: 'text',
          },
        ]}
        onChange={setFilters}
        value={filters}
      />

      <div className="d-flex justify-content-end">
        <IxButton
          disabled={selectedPmEvents == null}
          loading={updatePmEventsData.isMutating || pmEventsData.isLoading || pmEventsData.isValidating}
          onClick={onDeleteReferences}
          outline
          variant="primary"
        >
          {t('Unmark as "Reference"')}
        </IxButton>
      </div>

      <Table
        className="mt-5"
        columnDefs={[
          {
            field: 'pointMachine.turnout.engineeringId',
            headerName: t('routes.Turnout') ?? undefined,
          },
          {
            field: 'pointMachineId',
            headerName: 'PM ID',
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) => data?.pointMachine?.engineeringId,
          },
          {
            cellRenderer: EventDirectionCell,
            field: 'direction',
            headerName: t('models.event.direction') ?? undefined,
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) => (data == null ? undefined : t(`models.event.direction-${data.direction}`)),
            width: 130,
          },
          {
            comparator: (_, __, nodeA, nodeB) =>
              nodeA.data == null || nodeB.data == null
                ? 0
                : DateTime.fromISO(nodeA.data.start).diff(DateTime.fromISO(nodeB.data.start), 'milliseconds')
                    .milliseconds,
            field: 'start',
            headerName: t('models.event.startTime') ?? undefined,
            initialSort: 'desc',
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) =>
              data == null
                ? null
                : DateTime.fromISO(data.start)
                    .setLocale(i18n.language)
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
          },
          {
            field: 'throwingTime',
            headerName: `${t('models.event.duration') ?? undefined} (ms)`,
            sortable: true,
            unSortIcon: true,
            width: 150,
          },
        ]}
        error={pmEventsData.error}
        isLoading={pmEventsData.isLoading}
        onSelectionChanged={onSelectionChanged}
        onSortChanged={onSort}
        paginationPageSize={PAGE_SIZE}
        paginationProps={{
          count: pmEvents?.totalPages,
          onPageSelected: (event) => setPageNo(event.detail),
          selectedPage: pmEvents?.number,
        }}
        rowData={pmEvents?.content}
        selectable
        sortingOrder={['desc', 'asc']}
      />
    </>
  )
}
