import { ICellRendererParams } from 'ag-grid-community'
import * as routes from 'lib/routes'
import { Turnout } from 'models'
import { JSX } from 'react'
import { generatePath, Link } from 'react-router-dom'

export function IdCell({ data }: ICellRendererParams<Turnout>): JSX.Element | null {
  if (data == null) {
    return null
  }

  return (
    <Link to={generatePath(routes.turnoutRoute.pathname, { turnoutId: String(data.id) })}>{data.engineeringId}</Link>
  )
}
