import { IxApplicationContext, IxToastContainer } from '@siemens/ix-react'
import { PageHeaderProvider } from 'contexts/PageHeader'
import { JSX, memo, PropsWithChildren } from 'react'
import { SWRConfig } from 'swr'
import { swrConfig } from './lib'

export const LastWrapper = memo<PropsWithChildren<{}>>(({ children }) => <>{children}</>)

export interface ContextsProps {}

export function Contexts({ children }: PropsWithChildren<ContextsProps>): JSX.Element {
  return (
    <SWRConfig value={swrConfig}>
      <IxApplicationContext>
        <IxToastContainer />
        <PageHeaderProvider>
          <LastWrapper>{children}</LastWrapper>
        </PageHeaderProvider>
      </IxApplicationContext>
    </SWRConfig>
  )
}
