import { IxTile } from '@siemens/ix-react'
import GlobalPageHeader from 'components/GlobalPageHeader'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import Dashboard from './components/Dashboard'

const route = routes.homeRoute

export function HomePage(): JSX.Element {
  const { isAuthenticated } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader subTitle="Point performance" title={appTitle}>
        {isAuthenticated ? (
          <Dashboard />
        ) : (
          <IxTile>
            <div slot="header">Tile header</div>
            <div className="text-l">Lorem Ipsum</div>
          </IxTile>
        )}
      </GlobalPageHeader>
    </>
  )
}
