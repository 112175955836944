import { ProjectConfigurationApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, ProjectConfiguration, ProjectConfigurationChangeLog, ServiceError } from 'models'
import { Key, mutate } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'
import { useMainProject } from './use-project'

export const getUseProjectConfigurationKey = (projectId?: IdType): Key => {
  if (projectId == null) {
    return null
  }

  return ['useProjectConfiguration', projectId]
}

export const useProjectConfiguration = (): SWRResponse<ProjectConfiguration> => {
  const { data: mainProject } = useMainProject()

  return useSWR(getUseProjectConfigurationKey(mainProject?.id), () => {
    return ProjectConfigurationApiClient.read(mainProject!.id)
  })
}

export const getProjectConfigurationChangeLogsKey = (parentId?: IdType): Key => {
  if (parentId == null) {
    return null
  }

  return ['useProjectConfigurationChangeLogs', parentId]
}

export const useProjectConfigurationChangeLogs = (): SWRResponse<ProjectConfigurationChangeLog[]> => {
  const { data: mainProject } = useMainProject()

  return useSWR(getProjectConfigurationChangeLogsKey(mainProject?.id), () => {
    return ProjectConfigurationApiClient.getChangeLogs(mainProject!.id)
  })
}

interface UpdateProjectConfigurationOpts {
  configuration: Partial<ProjectConfiguration>
  projectId: IdType
}

export const useUpdateProjectConfiguration = (): SWRMutationResponse<
  ProjectConfiguration,
  ServiceError,
  UpdateProjectConfigurationOpts,
  Key
> => {
  return useSWRMutation('useUpdateProjectConfiguration', async (_, { arg }) => {
    const { data: projectConfiguration } = await ProjectConfigurationApiClient.update(arg.projectId, arg.configuration)

    await mutate(getUseProjectConfigurationKey(arg.projectId))

    return projectConfiguration
  })
}
