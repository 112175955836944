import { IxBlind } from '@siemens/ix-react'
import { ServiceError } from 'models'
import { ReactNode } from 'react'

interface ErrorObject {
  title: string
  message: string[]
}

export const createErrorObject = (error: Error | ServiceError | string): ErrorObject => {
  const defaultTitle = 'Error'

  if (error instanceof Error) {
    return { title: error.name, message: [error.message] }
  }

  if (typeof error === 'string') {
    return { title: defaultTitle, message: [error] }
  }

  if (Array.isArray(error.errors)) {
    return { title: defaultTitle, message: error.errors }
  }

  return { title: error.title ?? defaultTitle, message: [error.message ?? ''] }
}

export const createErrorLines = (errorMessage: string | string[] | undefined): ReactNode => {
  if (Array.isArray(errorMessage)) {
    return errorMessage.map((message) => (
      <span key={message}>
        {createErrorLines(message)}
        <br />
      </span>
    ))
  }

  const lines = errorMessage?.split('\n')

  if (lines == null || lines.length === 0) {
    return undefined
  }

  if (lines.length === 1) {
    return errorMessage
  }

  return (
    <IxBlind label={lines[0]}>
      <pre style={{ textAlign: 'left' }}>{lines.join('\n')}</pre>
    </IxBlind>
  )
}
