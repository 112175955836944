import { QueryTurnoutFilterOpts } from 'api'
import ContextWrapper from 'components/ContextWrapper'
import EventKpiTile from 'components/EventKpiTile'
import { useTurnoutStateStatistics } from 'hooks'
import { turnoutStateToColorMap } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

export interface TurnoutsKpiBarProps {
  filterOpts: Partial<QueryTurnoutFilterOpts>
}

export function TurnoutsKpiBar({ filterOpts }: TurnoutsKpiBarProps): JSX.Element {
  const { data: stateStatistics, ...stateStatisticsData } = useTurnoutStateStatistics(filterOpts)
  const { t } = useTranslation()

  return (
    <ContextWrapper data={true} isLoading={stateStatisticsData.isLoading}>
      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          eventColor={turnoutStateToColorMap.alert}
          title={t('models.turnout.state-alert') ?? undefined}
          value={stateStatistics?.operationState.red}
        />
      </div>

      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          eventColor={turnoutStateToColorMap.warning}
          title={t('models.turnout.state-warning') ?? undefined}
          value={stateStatistics?.operationState.yellow}
        />
      </div>

      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          eventColor={turnoutStateToColorMap.healthy}
          title={t('models.turnout.state-healthy') ?? undefined}
          value={stateStatistics?.operationState.green}
        />
      </div>

      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          eventColor={turnoutStateToColorMap.disconnected}
          title={t('models.turnout.state-disconnected') ?? undefined}
          value={stateStatistics?.connectionState.disconnected}
        />
      </div>
    </ContextWrapper>
  )
}
