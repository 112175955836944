import { IxSelect, IxSelectItem } from '@siemens/ix-react'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

export function LanguageSelect(): JSX.Element {
  const { i18n } = useTranslation()

  return (
    <IxSelect
      onItemSelectionChange={(event) =>
        i18n.changeLanguage(typeof event.detail === 'string' ? event.detail : event.detail[0])
      }
      selectedIndices={i18n.language}
    >
      {i18n.languages.map((lng) => (
        <IxSelectItem key={lng} label={i18n.getFixedT(lng)('language') ?? undefined} value={lng} />
      ))}
    </IxSelect>
  )
}
