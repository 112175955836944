import { JSONSchema7Object } from 'json-schema'
import { Project, RawArea, RawAreaCodec } from 'models'
import { CrudApiClient } from './base'
import { ProjectApiClient } from './project'

class AreaApiClient extends CrudApiClient<RawArea, Project> {
  protected getCreateBody(model: RawArea): JSONSchema7Object {
    return { name: model.name }
  }

  protected getUpdateBody(model: RawArea): JSONSchema7Object {
    return this.getCreateBody(model)
  }
}

const instance = new AreaApiClient('areas', RawAreaCodec, ProjectApiClient, 'projectId')
export { instance as AreaApiClient }
