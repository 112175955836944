import { themeSwitcher } from '@siemens/ix'
import { IxSelect, IxSelectItem } from '@siemens/ix-react'
import { storagePrefix } from 'lib/config'
import { JSX, useEffect, useState } from 'react'

export function ThemeSelect(): JSX.Element {
  const [theme, setTheme] = useState<string>()

  useEffect(() => {
    const initialTheme = localStorage.getItem(`${storagePrefix}theme`) ?? 'theme-brand-light'
    themeSwitcher.setTheme(initialTheme)

    themeSwitcher.themeChanged.on((theme) => {
      setTheme(theme)
      localStorage.setItem(`${storagePrefix}theme`, theme)
    })
  }, [])

  return (
    <IxSelect
      onItemSelectionChange={(event) =>
        themeSwitcher.setTheme(typeof event.detail === 'string' ? event.detail : event.detail[0])
      }
      selectedIndices={theme}
    >
      <IxSelectItem label="Siemens Brand Light" value="theme-brand-light" />
      <IxSelectItem label="Siemens Brand Dark" value="theme-brand-dark" />
    </IxSelect>
  )
}
