import { InterlockingApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { BaseModel, IdType, Interlocking } from 'models'
import { useMemo } from 'react'
import { Key } from 'swr'
import { useArea, useAreas } from './use-area'

export const getInterlockingsKey = (parentId: Parameters<typeof useInterlockings>[0], parents?: BaseModel[]): Key => {
  if (parentId == null) {
    if (parents == null) {
      return null
    }

    return ['useInterlockings']
  }

  return ['useInterlockings', parentId]
}

export const useInterlockings = (parentId?: IdType | null): SWRResponse<Interlocking[]> => {
  const { data: parents, ...parentsData } = useAreas()

  const { data: rawEntries, ...rawEntriesData } = useSWR(getInterlockingsKey(parentId, parents), async () => {
    if (parentId == null) {
      const responses = await Promise.all(parents!.map((parent) => InterlockingApiClient.list(parent.id)))
      const allEntries = responses.flatMap((entry) => entry.data)
      return { data: allEntries, dateTime: responses[0].dateTime }
    }

    return InterlockingApiClient.list(parentId)
  })

  const data = useMemo<Interlocking[] | undefined>(() => {
    return rawEntries?.map((entry) => ({ ...entry, _area: parents?.find((parent) => parent.id === entry.area.id) }))
  }, [rawEntries, parents])

  return useMemo(() => {
    return {
      data,
      ...rawEntriesData,
      isLoading: rawEntriesData.isLoading || parentsData.isLoading,
      isValidating: rawEntriesData.isValidating || parentsData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntriesData.mutate(), parentsData.mutate()])
        return undefined
      },
    }
  }, [rawEntriesData, data, parentsData])
}

export const getInterlockingKey = (id: Parameters<typeof useInterlocking>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['useInterlocking', id]
}

export const useInterlocking = (id: IdType | undefined | null): SWRResponse<Interlocking> => {
  const { data: rawEntry, ...rawEntryData } = useSWR(getInterlockingKey(id), () => {
    return InterlockingApiClient.read(id!)
  })

  const { data: parent, ...parentData } = useArea(rawEntry?.area.id)

  const data = useMemo<Interlocking | undefined>(() => {
    if (rawEntry == null) {
      return undefined
    }

    return { ...rawEntry, _area: parent }
  }, [rawEntry, parent])

  return useMemo(() => {
    return {
      data,
      ...rawEntryData,
      isLoading: rawEntryData.isLoading || parentData.isLoading,
      isValidating: rawEntryData.isValidating || parentData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntryData.mutate(), parentData.mutate()])
        return undefined
      },
    }
  }, [data, rawEntryData, parentData])
}
