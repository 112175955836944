import { DateRangeFilterOpts } from 'api'
import { useMemo } from 'react'
import { Encoding } from 'vega-lite/build/src/encoding'

export const useEncoding = (
  filterOpts: Partial<DateRangeFilterOpts> | undefined,
): [Encoding<string>, Encoding<string>] => {
  return useMemo((): [Encoding<string>, Encoding<string>] => {
    const fromString = filterOpts?.from?.toISO()
    const toString = filterOpts?.to?.toISO()

    const diff = filterOpts?.from == null ? undefined : filterOpts?.to?.diff(filterOpts.from, 'hours')
    const absDiff = diff == null ? undefined : Math.abs(diff.hours)

    const baseEncoding: Encoding<string> = {
      x: {
        axis: { labels: false, ticks: true },
        field: 'start',
        scale: fromString == null || toString == null ? {} : { domain: [fromString, toString] },
        title: null,
        type: 'temporal',
      },
    }

    const encoding: Encoding<string> = {
      ...baseEncoding,
      x: {
        ...baseEncoding.x,
        axis: { domain: false, format: absDiff == null || absDiff > 25 ? '%x' : '%X', gridOpacity: 0.5, ticks: false },
      },
    }

    return [baseEncoding, encoding]
  }, [filterOpts])
}
