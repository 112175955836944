import * as t from 'io-ts'
import { BaseModelCodec } from './base'

export const BasePointMachineChangeLogCodec = t.type({
  pointMachineId: t.number,
  oldSerialNumber: t.string,
  newSerialNumber: t.string,
  nameOfTheUser: t.string,
  changeAt: t.string,
})
export const BasePointMachineChangeLogsCodec = t.array(BasePointMachineChangeLogCodec)
export type BasePointMachineChangeLog = t.TypeOf<typeof BasePointMachineChangeLogCodec>

export const PointMachineChangeLogCodec = t.intersection([BaseModelCodec, BasePointMachineChangeLogCodec])
export const PointMachineChangeLogsCodec = t.array(PointMachineChangeLogCodec)
export type PointMachineChangeLog = t.TypeOf<typeof PointMachineChangeLogCodec>
