import { IxIcon, IxInputGroup } from '@siemens/ix-react'
import { JSX, useMemo } from 'react'
import { FieldProps } from '../Field'

export function TextField({ field, onChange, ...props }: FieldProps): JSX.Element {
  const value = useMemo(() => {
    return (typeof props.value?.value === 'string' ? props.value.value : undefined) ?? ''
  }, [props.value])

  return (
    <IxInputGroup>
      {field.icon != null && (
        <span slot="input-start">
          <IxIcon name={field.icon} size="16" />
        </span>
      )}

      <input
        className="form-control"
        type="text"
        onChange={(event) => onChange(field.path, event.target.value)}
        placeholder={field.label ?? field.path}
        value={value}
      />
    </IxInputGroup>
  )
}
