import { RouteConfig } from '..'
import { configurationRoute } from './lib'

export const thresholdConfigurationRoute: RouteConfig<
  `${typeof configurationRoute.pathname}/threshold`,
  typeof configurationRoute
> = {
  icon: 'threshold-on',
  name: 'Threshold configuration',
  parent: configurationRoute,
  pathname: `${configurationRoute.pathname}/threshold`,
}

export const alarmThresholdConfigurationRoute: RouteConfig<
  `${typeof thresholdConfigurationRoute.pathname}/alarm`,
  typeof thresholdConfigurationRoute
> = {
  name: 'Alarm thresholds',
  parent: thresholdConfigurationRoute,
  pathname: `${thresholdConfigurationRoute.pathname}/alarm`,
}

export const parameterThresholdConfigurationRoute: RouteConfig<
  `${typeof thresholdConfigurationRoute.pathname}/parameter`,
  typeof thresholdConfigurationRoute
> = {
  name: 'Parameter thresholds',
  parent: thresholdConfigurationRoute,
  pathname: `${thresholdConfigurationRoute.pathname}/parameter`,
}

export const editParameterThresholdConfigurationRoute: RouteConfig<
  `${typeof parameterThresholdConfigurationRoute.pathname}/edit/:pointMachineId`,
  typeof parameterThresholdConfigurationRoute
> = {
  name: 'Parameter thresholds',
  parent: parameterThresholdConfigurationRoute,
  pathname: `${parameterThresholdConfigurationRoute.pathname}/edit/:pointMachineId`,
}

export const referenceThresholdConfigurationRoute: RouteConfig<
  `${typeof thresholdConfigurationRoute.pathname}/reference`,
  typeof thresholdConfigurationRoute
> = {
  name: 'Reference curves',
  parent: thresholdConfigurationRoute,
  pathname: `${thresholdConfigurationRoute.pathname}/reference`,
}
