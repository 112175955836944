import { IxButton, IxContentHeader, IxSpinner, showToast } from '@siemens/ix-react'
import { QueryPointMachineFilterOpts } from 'api'
import DataFilter, { Filter } from 'components/DataFilter'
import { createErrorObject } from 'components/ErrorInfo'
import Table, { TableProps } from 'components/Table'
import {
  useFilterOpts,
  usePointMachines,
  usePointMachineSearchFields,
  useQueryPointMachines,
  useResetThresholds,
  useTrackLayers,
  useTurnouts,
} from 'hooks'
import * as routes from 'lib/routes'
import { convertError, IdType, PointMachine } from 'models'
import { JSX, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import UpdateParameterThresholdsModal from '../UpdateParameterThresholdsModal'

export interface ParameterThresholdConfigProps {
  editPointMachineIds: IdType[] | undefined
}

export function ParameterThresholdConfig({ editPointMachineIds }: ParameterThresholdConfigProps): JSX.Element {
  const [filters, setFilters] = useState<Filter[]>([])
  const [pageNo, setPageNo] = useState(0)
  const [selectedPMIds, setSelectedPMIds] = useState<string>()
  const [sortBy, setSortBy] = useState<string | undefined>('engineeringId')
  const [sortingDirection, setSortingDirection] = useState<QueryPointMachineFilterOpts['sortingDirection'] | undefined>(
    'asc',
  )
  const { data: layerItems } = useTrackLayers()
  const { data: pointMachines } = usePointMachines()
  const { data: turnouts } = useTurnouts()
  const { t } = useTranslation()
  const { trigger: resetThresholds, ...resetThresholdsData } = useResetThresholds()

  const filterOpts = useFilterOpts<Partial<QueryPointMachineFilterOpts>>(filters)
  const pagedFilterOpts = useMemo<Partial<QueryPointMachineFilterOpts>>(() => {
    return {
      ...filterOpts,
      page: pageNo,
      sortBy,
      sortingDirection,
    }
  }, [filterOpts, pageNo, sortBy, sortingDirection])

  const { data: pagedPointMachines, ...pagedPointMachinesData } = useQueryPointMachines(pagedFilterOpts)

  const onSelectionChanged = useCallback<NonNullable<TableProps<PointMachine>['onSelectionChanged']>>(({ api }) => {
    const pointMachines = api.getSelectedRows()
    setSelectedPMIds(pointMachines.length === 0 ? undefined : pointMachines.map((pm) => pm.id).join(','))
  }, [])

  const onSort = useCallback<NonNullable<TableProps<PointMachine>['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortBy(sortedColumn?.colId)
    setSortingDirection(sortedColumn?.sort ?? undefined)
  }, [])

  const onReset = useCallback(async () => {
    const selectedPointMachines = pointMachines?.filter((pm) => selectedPMIds?.includes(String(pm.id)))

    if (selectedPointMachines == null) {
      return
    }

    let loadingToast: Awaited<ReturnType<typeof showToast>>

    try {
      loadingToast = await showToast({
        message: (
          <div className="d-flex align-items-center">
            {t('Updating thresholds')} <IxSpinner className="ms-1" />
          </div>
        ),
        autoClose: false,
      })
    } catch (error) {
      console.error(error)
    }

    try {
      await resetThresholds({ pointMachines: selectedPointMachines })

      setTimeout(() => {
        loadingToast.close()
        showToast({ message: t('Successfully updated thresholds'), type: 'success' })
      }, 500)
    } catch (error) {
      setTimeout(() => {
        loadingToast.close()
        showToast({
          title: t('Updating thresholds was unsuccessful') ?? undefined,
          message: createErrorObject(convertError(error)).message.join('\n\n'),
          type: 'error',
        })
      }, 500)
    }
  }, [pointMachines, resetThresholds, selectedPMIds, t])

  return (
    <>
      <IxContentHeader
        className="mb-4"
        headerTitle={t('Configure thresholds for performance parameters') ?? undefined}
      />

      <div className="row mb-5">
        <div className="col-8">
          <div>
            <Trans
              components={{
                li: <li />,
                p: <p />,
                ul: <ul />,
              }}
              i18nKey="ParameterThresholdConfig"
            />
          </div>
        </div>
      </div>

      <DataFilter
        className="mb-5"
        fields={[
          ...usePointMachineSearchFields(filters),
          {
            label: t('models.pointMachine.thresholdConfiguration'),
            options: [
              { label: t('models.pointMachine.thresholdConfiguration-complete'), value: 'complete' },
              { label: t('models.pointMachine.thresholdConfiguration-incomplete'), value: 'incomplete' },
            ],
            path: 'thresholdConfiguration',
            type: 'select',
          },
          {
            icon: 'search',
            label: t('Search for PM ID'),
            path: 'engineeringId',
            type: 'text',
          },
        ]}
        onChange={setFilters}
        value={filters}
      />

      <div className="d-flex justify-content-end">
        <IxButton
          className="me-2"
          disabled={selectedPMIds == null}
          loading={resetThresholdsData.isMutating}
          onClick={onReset}
          outline
        >
          {t('Reset to default')}
        </IxButton>

        <Link
          to={
            selectedPMIds == null
              ? {}
              : generatePath(routes.editParameterThresholdConfigurationRoute.pathname, {
                  pointMachineId: selectedPMIds,
                })
          }
        >
          <IxButton disabled={selectedPMIds == null}>{t('Configure')}</IxButton>
        </Link>
      </div>

      <Table
        columnDefs={[
          {
            field: 'turnoutId',
            headerName: t('routes.Turnout') ?? undefined,
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) => turnouts?.find((turnout) => turnout.id === data?.turnoutId)?.name,
          },
          {
            field: 'engineeringId',
            headerName: 'PM ID',
            initialSort: 'asc',
            sortable: true,
            unSortIcon: true,
          },
          {
            field: 'id',
            headerName: layerItems?.find((item) => item.type === 'technicalRoom')?.name,
            valueGetter: ({ data }) =>
              turnouts?.find((turnout) => turnout.id === data?.turnoutId)?._technicalRoom?.name,
          },
          {
            field: 'type.name',
            headerName: t('Type') ?? undefined,
            sortable: true,
            unSortIcon: true,
          },
        ]}
        error={pagedPointMachinesData.error}
        isLoading={pagedPointMachinesData.isLoading}
        onSelectionChanged={onSelectionChanged}
        onSortChanged={onSort}
        paginationPageSize={pagedPointMachines?.size}
        paginationProps={{
          count: pagedPointMachines?.totalPages,
          onPageSelected: (event) => setPageNo(event.detail),
          selectedPage: pagedPointMachines?.number,
        }}
        rowData={pagedPointMachines?.content}
        selectable
      />

      <UpdateParameterThresholdsModal pointMachineIds={editPointMachineIds} visible={editPointMachineIds != null} />
    </>
  )
}
