import Table from 'components/Table'
import { useUserInterlockings, useUsers } from 'hooks'
import { JSX, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ActionsCell from './components/ActionsCell'

const PAGE_SIZE = 15

export interface UserConfigTableProps {}

export function UserConfigTable(props: UserConfigTableProps): JSX.Element {
  const [pageNo, setPageNo] = useState(0)
  const { t } = useTranslation()

  const { data: users, ...usersData } = useUsers()

  const pagedUsers = useMemo(() => {
    return users?.slice(pageNo * PAGE_SIZE, pageNo * PAGE_SIZE + PAGE_SIZE)
  }, [pageNo, users])

  const pagedUserIds = useMemo(() => {
    return pagedUsers?.map((user) => user.id)
  }, [pagedUsers])

  const { data: userInterlockings, ...userInterlockingsData } = useUserInterlockings(pagedUserIds)

  const usersWithInterlockings = useMemo(() => {
    return pagedUsers?.map((user, index) => {
      const interlockings = userInterlockings?.[index]
      return { ...user, interlockings }
    })
  }, [pagedUsers, userInterlockings])

  return (
    <Table
      columnDefs={[
        {
          field: 'username',
          headerName: t('User name') ?? undefined,
        },
        {
          field: 'interlockings',
          headerName: t('Alarms for') ?? undefined,
          valueGetter: ({ data }) =>
            data == null ? null : data.interlockings?.map((interlocking) => interlocking.name).join(', '),
        },
        {
          cellRenderer: ActionsCell,
          field: 'id',
          headerName: t('Actions') ?? undefined,
        },
      ]}
      paginationPageSize={PAGE_SIZE}
      paginationProps={
        users == null || users.length <= PAGE_SIZE
          ? false
          : {
              count: users == null ? undefined : Math.floor(users.length / PAGE_SIZE),
              onPageSelected: (event) => setPageNo(event.detail),
              selectedPage: pageNo,
            }
      }
      isLoading={usersData.isLoading || userInterlockingsData.isLoading}
      error={usersData.error ?? userInterlockingsData.error}
      rowData={usersWithInterlockings}
    />
  )
}
