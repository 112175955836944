import { IxBlind } from '@siemens/ix-react'
import GlobalPageHeader from 'components/GlobalPageHeader'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import en from 'lib/i18n/lang/en.json'

const route = routes.faqRoute

export function FaqPage(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        {en.translation.FAQ.map((item, index) => (
          <IxBlind collapsed key={item.question} label={t(`FAQ.${index}.question`) ?? undefined}>
            <Trans
              components={{
                li: <li />,
                p: <p />,
                ul: <ul />,
              }}
              i18nKey={`FAQ.${index}.answer`}
            />
          </IxBlind>
        ))}
      </GlobalPageHeader>
    </>
  )
}
