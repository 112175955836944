import { IxSelect } from '@siemens/ix-react'
import { styled } from 'styled-components'

export const Select = styled(IxSelect)`
  width: 100%;

  ix-filter-chip {
    display: none;
  }
`
