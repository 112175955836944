import * as t from 'io-ts'

export const ServiceErrorCodec = t.partial({
  code: t.number,
  errors: t.array(t.string),
  message: t.string,
  title: t.string,
})

export type ServiceError = t.TypeOf<typeof ServiceErrorCodec>

export const convertError = (error: unknown): ServiceError => {
  if (error instanceof Error) {
    const serviceError: ServiceError = {
      message: error.message,
      title: error.name,
    }

    return serviceError
  }

  const asServiceError = error as ServiceError
  if ((asServiceError.code != null && asServiceError.message != null) || Array.isArray(asServiceError.errors)) {
    return asServiceError
  }

  return {
    title: 'Error',
    message: JSON.stringify(error),
  }
}
