import { IxKeyValue, IxTile } from '@siemens/ix-react'
import GlobalPageHeader from 'components/GlobalPageHeader'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import LanguageSelect from './components/LanguageSelect'
import ThemeSelect from './components/ThemeSelect'

const route = routes.settingsRoute

export function SettingsPage(): JSX.Element {
  const { t } = useTranslation()
  const authDomain:string = sessionStorage.getItem("REACT_APP_AUTH_DOMAIN") ?? ''

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <div className="row g-3">
          <div className="col-6">
            <IxTile>
              <div slot="header">{t('App settings')}</div>

              <IxKeyValue label={t('Language')} style={{ border: 'none' }}>
                <span slot="custom-value">
                  <LanguageSelect />
                </span>
              </IxKeyValue>

              <IxKeyValue label={t('Theme')} style={{ border: 'none' }}>
                <span slot="custom-value">
                  <ThemeSelect />
                </span>
              </IxKeyValue>
            </IxTile>
          </div>

          <div className="col-6">
            <IxTile>
              <div slot="header">{t('User account settings')}</div>

              {t('Click on the following link to:')}

              <ul>
                <li>{t('Update password')}</li>
                <li>{t('Change e-mail address')}</li>
                <li>{t('Update display name')}</li>
              </ul>

              <a href={`${authDomain}/account`} rel="noreferrer" target="_blank">
                {t('Account management')}
              </a>
            </IxTile>
          </div>
        </div>
      </GlobalPageHeader>
    </>
  )
}
