import { DateTime } from 'luxon'
import { ChangeEventHandler, JSX, useCallback, useMemo } from 'react'
import { FieldProps } from '../Field'

export function DateField({ field, ...props }: FieldProps): JSX.Element {
  const value = useMemo(() => {
    if (props.value?.value == null || Array.isArray(props.value.value)) {
      return undefined
    }

    const date =
      typeof props.value?.value === 'string'
        ? DateTime.fromISO(props.value.value)
        : DateTime.isDateTime(props.value.value)
        ? props.value.value
        : undefined

    return date?.toISODate() ?? undefined
  }, [props.value])

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = DateTime.fromISO(event.target.value)
      props.onChange.call(null, field.path, value)
    },
    [field.path, props.onChange],
  )

  return <input className="form-control" onChange={onChange} type="date" value={value} />
}
