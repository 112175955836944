import ConcatenatedIncidentChart, {
  ConcatenatedIncidentChartProps,
  DataPoint,
} from 'components/ConcatenatedIncidentChart'
import { PointMachineEvent } from 'models'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

export interface MeanPowerDurationGraphProps extends Omit<ConcatenatedIncidentChartProps, 'height' | 'layers'> {
  height?: number
  powerLayer: GenericUnitSpec<Encoding<string>, AnyMark>
  pointMachineEvents: PointMachineEvent[]
}

export const MeanPowerDurationGraph: FC<MeanPowerDurationGraphProps> = ({
  height = 500,
  powerLayer,
  pointMachineEvents,
  ...rest
}) => {
  const { t } = useTranslation()

  const durationData = useMemo((): DataPoint[] => {
    const data = pointMachineEvents.flatMap((pmEvent): DataPoint => {
      const data: DataPoint = {
        start: pmEvent.start,
        type: pmEvent.pointMachine?.name,
        type2: t(`models.event.direction-${pmEvent.direction}`),
        value: pmEvent.throwingTime / 1000,
      }

      return data
    })

    return data
  }, [pointMachineEvents, t])

  const durationLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const encoding: Encoding<string> = {
      color: { field: 'type', scale: { scheme: 'accent' }, title: t('models.event.duration') },
      opacity: { condition: { param: 'highlightDuration', value: 0.9 }, value: 0.2 },
      tooltip: [
        { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
        { field: 'type2', title: t('models.event.direction') },
        { field: 'type', title: t('Point machine') },
        { field: 'value', title: `${t('models.event.duration')} [s]`, type: 'quantitative' },
      ],
      y: { field: 'value', title: `${t('models.event.duration')} [s]`, type: 'quantitative' },
    }

    return {
      data: { values: durationData },
      encoding,
      mark: { type: 'line' },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightDuration',
          select: { type: 'point', fields: ['type'] },
        },
      ],
    }
  }, [durationData, t])

  const layers = useMemo(() => [durationLayer, powerLayer], [powerLayer, durationLayer])

  return <ConcatenatedIncidentChart {...rest} height={height} layers={layers} />
}
