import { IxPill, IxTooltip } from '@siemens/ix-react'
import { ICellRendererParams } from 'ag-grid-community'
import { PointMachineEvent } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

export function EventDirectionCell({ data }: ICellRendererParams<PointMachineEvent>): JSX.Element | null {
  const { t } = useTranslation()

  if (data == null) {
    return null
  }

  return (
    <>
      <IxPill data-tooltip={`point-machine-event-table-direction-${data.id}`} style={{ padding: '4px 18px' }}>
        {data.direction[0].toUpperCase()}
      </IxPill>

      <IxTooltip for={`[data-tooltip='point-machine-event-table-direction-${data.id}']`} placement="right">
        {t(`models.event.direction-${data.direction}`)}
      </IxTooltip>
    </>
  )
}
