import { IxIconButton, IxTooltip } from '@siemens/ix-react'
import { ICellRendererParams } from 'ag-grid-community'
import * as routes from 'lib/routes'
import { Turnout } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

export function ActionsCell({ data }: ICellRendererParams<Turnout>): JSX.Element | null {
  const { t } = useTranslation()

  if (data == null) {
    return null
  }

  return (
    <>
      <Link to={generatePath(routes.editUserConfigurationRoute.pathname, { userId: String(data.id) })}>
        <IxIconButton data-tooltip={`turnout-config-table-edit-${data.id}`} icon="pen" ghost />
      </Link>

      <IxTooltip for={`[data-tooltip="turnout-config-table-edit-${data.id}"]`} placement="right">
        {t(`routes.${routes.editUserConfigurationRoute.name}`)}
      </IxTooltip>
    </>
  )
}
