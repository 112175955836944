import { de_DE } from 'lib/i18n/vega'
import { forwardRef, RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VegaLite } from 'react-vega'
import { VegaLiteProps } from 'react-vega/lib/VegaLite'
import { LegendOrient } from 'vega'
import * as S from './styles'

const config: VegaLiteProps['config'] = {
  font: 'Siemens Sans, sans-serif',
  axis: {
    domainColor: '#c3c3ca',
    domainWidth: 2,
    gridColor: '#d1d1d1',
    gridOpacity: 0.8,
    labelColor: '#8a8a9b',
    labelPadding: 8,
    labelSeparation: 1,
    tickColor: '#a8a8b3',
    ticks: false,
    titleColor: '#bfbfbf',
    titleFontSize: 8,
  },
  axisY: {
    domain: false,
  },
  legend: {
    labelColor: 'var(--theme-color-contrast-text)',
    labelFontSize: 12,
    titleColor: 'var(--theme-color-soft-text)',
    titleFontSize: 12,
  },
  view: {
    stroke: 'transparent',
  },
  background: 'transparent',
}

export interface VegaContainerProps extends VegaLiteProps {
  fit?: boolean
  legend?: LegendOrient
}

export const VegaContainer = forwardRef<HTMLDivElement, VegaContainerProps>(function VegaContainer(
  { fit, legend = 'bottom', ...props },
  forwardedRef,
) {
  const { i18n } = useTranslation()
  const [width, setWidth] = useState<number | undefined>()
  const ref = useRef<HTMLDivElement | null>()

  const mergedConfig = useMemo<VegaLiteProps['config']>(() => {
    if (legend === 'bottom') {
      return {
        ...config,
        legend: {
          ...config.legend,
          orient: legend,
          symbolDirection: 'horizontal',
          symbolType: 'circle',
        },
      }
    }

    return {
      ...config,
      legend: {
        ...config.legend,
        orient: legend,
      },
    }
  }, [legend])

  const calcWidth = useCallback(() => {
    setWidth(ref.current?.getBoundingClientRect().width)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', calcWidth)
    calcWidth()

    return () => {
      window.removeEventListener('resize', calcWidth)
    }
  }, [calcWidth])

  const onRef = useCallback<RefCallback<HTMLDivElement>>(
    (element) => {
      ref.current = element
      calcWidth()

      if (forwardedRef == null) {
        return
      }

      if (typeof forwardedRef === 'function') {
        return forwardedRef(element)
      }

      forwardedRef.current = element
    },
    [calcWidth, forwardedRef],
  )

  return (
    <S.VegaContainer ref={onRef}>
      <VegaLite
        width={fit && width != null ? width - 30 : undefined}
        renderer="svg"
        actions={false}
        config={mergedConfig}
        timeFormatLocale={i18n.language === 'de' ? de_DE : undefined}
        {...props}
      />
    </S.VegaContainer>
  )
})
