import VegaContainer from 'components/VegaContainer'
import { DateTime } from 'luxon'
import { IdType, PointMachineEvent } from 'models'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { VisualizationSpec } from 'react-vega'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

interface DataPoint {
  duration: number
  id: IdType
  pointMachine: string | null | undefined
  start: string
  timestamp: number
  value: number
}

const FREQUENCY = 50.0 // [Hz] @todo hard-coded

export interface PowerGraphProps {
  height?: number
  pointMachineEvents: PointMachineEvent[]
}

export const PowerGraph: FC<PowerGraphProps> = ({ height = 500, pointMachineEvents }) => {
  const { i18n, t } = useTranslation()

  const data = useMemo((): DataPoint[] => {
    const data = pointMachineEvents.flatMap((pmEvent): DataPoint[] => {
      const startDate = DateTime.fromISO(pmEvent.start)
      const start = startDate.setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
      const data = pmEvent.dataSeries.flatMap((series) =>
        series.values.map(
          (value, index): DataPoint => ({
            duration: index / FREQUENCY,
            id: pmEvent.id,
            pointMachine: pmEvent.pointMachine?.engineeringId,
            start,
            timestamp: new Date(series.start).getTime(),
            value,
          }),
        ),
      )

      return data
    })

    return data
  }, [i18n.language, pointMachineEvents])

  const layer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const encoding: Encoding<string> = {
      color: {
        field: 'start',
        title: t('Turn event'),
        scale: { range: pointMachineEvents.length > 1 ? 'diverging' : 'category' },
        sort: { field: 'timestamp' },
      },
      opacity: { condition: { param: 'highlightName', value: 1 }, value: 0.2 },
      strokeDash: { field: 'pointMachine', title: t('Point machine') },
      strokeOpacity: { field: 'id', scale: { domain: [1] } },
      tooltip: [
        { field: 'start', title: t('Timestamp') },
        { field: 'duration', title: `${t('models.event.duration')} [s]` },
        { field: 'value', title: `${t('Corrected power')} [W]` },
      ],
      x: { field: 'duration', type: 'quantitative', title: `${t('models.event.duration')} [s]` },
      y: { field: 'value', type: 'quantitative', title: `${t('Corrected power')} [W]` },
    }

    return {
      data: { values: data },
      encoding,
      mark: { type: 'line' },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightName',
          select: { type: 'point', fields: ['start'] },
        },

        // zoom
        {
          bind: 'scales',
          name: 'grid',
          select: 'interval',
        },
      ],
    }
  }, [t, pointMachineEvents.length, data])

  // const referenceLines = useMemo((): LayerSpec<any>[] => {
  //   if (pointMachines.length === 0 || pointMachines.length > 1) {
  //     return []
  //   }

  //   const reg = /p\dStart|End/
  //   const referenceLines = Object.entries(pointMachines[0])
  //     .filter(([key, value]) => key.search(reg) >= 0 && typeof value === 'number')
  //     .map(([key, value]): LayerSpec<any> => {
  //       return {
  //         data: { values: [{}] },
  //         layer: [
  //           { mark: { type: 'rule', color: 'red' } },
  //           {
  //             mark: {
  //               align: 'center',
  //               baseline: 'bottom',
  //               color: 'red',
  //               dx: -2,
  //               dy: -2,
  //               text: key,
  //               type: 'text',
  //               y: 20,
  //             },
  //           },
  //         ],
  //         encoding: { x: { datum: (value as number) / 1000 } },
  //       }
  //     })

  //   return referenceLines
  // }, [pointMachines])

  const spec = useMemo((): VisualizationSpec => {
    return {
      layer: [layer],
      width: 'container',
    }
  }, [layer])

  return <VegaContainer height={height} legend="right" spec={spec} />
}
