import { AgGridReact } from 'ag-grid-react'
import { DateTime } from 'luxon'
import { MutableRefObject, useCallback } from 'react'

export const useExportData = <T = any>(gridRef: MutableRefObject<AgGridReact<T> | null>) => {
  const onClickExport = useCallback(() => {
    const columnKeys = gridRef.current?.columnApi
      .getColumns()
      ?.map((column) => column.getId())
      .filter((column) => column !== 'eventColor')

    const iso = DateTime.now().toISO({ includeOffset: false })
    const fileName = `export-${iso}.csv`

    const onlySelectedAllPages = gridRef.current?.api.getSelectedRows().length === 0 ? false : true

    gridRef.current?.api.exportDataAsCsv({ columnKeys, fileName, onlySelectedAllPages })
  }, [gridRef])

  return { onClickExport }
}
