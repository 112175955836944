import { ConfigValuesProps } from 'components/ConfigValues'
import { Direction, Threshold } from 'models'

export type ThresholdType = 'time' | 'power' | 'work'

export type ThresholdAllValues = Record<ThresholdType, Record<Direction, number[]>>
export type ThresholdValues = Record<ThresholdType, Record<Direction, number | undefined>>
export type ThresholdConfigValues = Record<ThresholdType, ConfigValuesProps['values']>

export const thresholdsToConfigValues = (thresholds: Threshold[]): ThresholdConfigValues => {
  const allValues = thresholds.reduce(
    (map, threshold) => {
      return {
        time: { ...map.time, [threshold.direction]: [...map.time[threshold.direction], threshold.throwingTime] },
        power: { ...map.power, [threshold.direction]: [...map.power[threshold.direction], threshold.meanPower] },
        work: { ...map.work, [threshold.direction]: [...map.work[threshold.direction], threshold.work] },
      }
    },
    {
      time: { left: [], right: [] },
      power: { left: [], right: [] },
      work: { left: [], right: [] },
    } as ThresholdAllValues,
  )

  const { time, power, work } = allValues

  const values: ThresholdValues = {
    time: {
      left: time.left.every((value, _, arr) => value === arr[0]) ? time.left[0] : undefined,
      right: time.right.every((value, _, arr) => value === arr[0]) ? time.right[0] : undefined,
    },
    power: {
      left: power.left.every((value, _, arr) => value === arr[0]) ? power.left[0] : undefined,
      right: power.right.every((value, _, arr) => value === arr[0]) ? power.right[0] : undefined,
    },
    work: {
      left: work.left.every((value, _, arr) => value === arr[0]) ? work.left[0] : undefined,
      right: work.right.every((value, _, arr) => value === arr[0]) ? work.right[0] : undefined,
    },
  }

  const getPlaceholder = (type: ThresholdType, direction: Direction): string | undefined => {
    if (values[type][direction] == null) {
      return allValues[type][direction].length > 1 ? '<multiple>' : '<unset>'
    }
  }

  return {
    time: [
      {
        label: null,
        placeholder: getPlaceholder('time', 'left'),
        value: values.time.left,
      },
      {
        label: null,
        placeholder: getPlaceholder('time', 'right'),
        value: values.time.right,
      },
    ],
    power: [
      {
        label: null,
        placeholder: getPlaceholder('power', 'left'),
        value: values.power.left,
      },
      {
        label: null,
        placeholder: getPlaceholder('power', 'right'),
        value: values.power.right,
      },
    ],
    work: [
      {
        label: null,
        placeholder: getPlaceholder('work', 'left'),
        value: values.work.left,
      },
      {
        label: null,
        placeholder: getPlaceholder('work', 'right'),
        value: values.work.right,
      },
    ],
  }
}
