import Empty from 'components/Empty'
import GlobalPageHeader from 'components/GlobalPageHeader'
import { useParseNumber } from 'hooks/use-parse-number'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import TurnoutDetail from './components/TurnoutDetail'

const route = routes.turnoutRoute

export function TurnoutPage(): JSX.Element {
  const { turnoutId } = useParams()
  const turnoutIdNum = useParseNumber(turnoutId)
  const { t } = useTranslation()

  if (turnoutId == null || turnoutIdNum == null) {
    return <Empty />
  }

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} params={{ turnoutId }} title={t(`routes.${route.name}`)}>
        <TurnoutDetail turnoutId={turnoutIdNum} />
      </GlobalPageHeader>
    </>
  )
}
