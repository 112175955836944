import {
  IxButton,
  IxDropdown,
  IxDropdownHeader,
  IxDropdownQuickActions,
  IxIcon,
  IxIconButton,
  IxPill,
  IxSelect,
  IxSelectItem,
  IxTooltip,
  IxValidationTooltip,
} from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import ErrorInfo from 'components/ErrorInfo'
import { useCreateTurnout, useDeleteTurnouts, useTechnicalRooms, useUpdateTurnouts } from 'hooks'
import * as routes from 'lib/routes'
import { DateTime } from 'luxon'
import { Turnout } from 'models'
import { JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getDefaultFormValues, UpdateTurnout } from './lib'

const local = DateTime.local()
const aryIannaTimeZones = (Intl as any).supportedValuesOf('timeZone') as string[]
const zones = aryIannaTimeZones.map((timeZone) => {
  return { timeZone, offset: local.setZone(timeZone).toFormat('Z') }
})
zones.sort((a, b) => {
  const aNum = Number.parseFloat(a.offset)
  const bNum = Number.parseFloat(b.offset)
  return aNum - bNum
})

export interface UpdateTurnoutFormProps {
  onAfterDelete?: () => void
  onAfterSubmit?: () => void
  onCancel?: () => void
  onRemoveItem?: (item: Turnout, remaining?: Turnout[]) => void
  turnout?: Turnout | Turnout[]
}

export function UpdateTurnoutForm({ onAfterSubmit, onCancel, ...props }: UpdateTurnoutFormProps): JSX.Element {
  const [turnout, setTurnout] = useState(props.turnout)
  const defaultValues = useMemo(() => getDefaultFormValues(turnout), [turnout])

  const { data: technicalRooms, ...technicalRoomsData } = useTechnicalRooms()
  const { control, register, handleSubmit, formState, reset } = useForm<UpdateTurnout>({
    values: defaultValues,
  })
  const { t } = useTranslation()
  const { trigger: createTurnout, ...createTurnoutData } = useCreateTurnout()
  const { trigger: updateTurnout, ...updateTurnoutData } = useUpdateTurnouts()
  const { trigger: deleteTurnouts, ...deleteTurnoutsData } = useDeleteTurnouts()

  useEffect(() => {
    reset()
  }, [reset])

  const onFinish = useCallback<SubmitHandler<UpdateTurnout>>(
    async (formValues) => {
      if (turnout == null) {
        await createTurnout(formValues)
      } else if (Array.isArray(turnout)) {
        await updateTurnout(turnout.map((turnout) => ({ ...turnout, ...formValues })))
      } else {
        await updateTurnout([{ ...turnout, ...formValues }])
      }

      onAfterSubmit?.()
    },
    [createTurnout, onAfterSubmit, turnout, updateTurnout],
  )

  const onDelete = useCallback(async () => {
    if (turnout == null) {
      return
    }

    if (Array.isArray(turnout)) {
      await deleteTurnouts(turnout.map((turnout) => turnout.id))
    } else {
      await deleteTurnouts([turnout.id])
    }

    onAfterSubmit?.()
  }, [deleteTurnouts, onAfterSubmit, turnout])

  const onRemoveItem = useCallback(
    (item: Turnout) => {
      setTurnout((prev) => {
        if (Array.isArray(prev)) {
          const newTurnouts = prev.filter((turnout) => turnout.id !== item.id)
          props.onRemoveItem?.(item, newTurnouts)
          return newTurnouts
        }

        props.onRemoveItem?.(item)
        return prev
      })
    },
    [props],
  )

  const onClickCancel = useCallback(() => {
    if (turnout == null || Array.isArray(turnout)) {
      return onCancel?.()
    }

    reset()
  }, [onCancel, reset, turnout])

  return (
    <ContextWrapper data={true} error={technicalRoomsData.error} isLoading={technicalRoomsData.isLoading}>
      <form className="row gx-5 gy-3" onSubmit={handleSubmit(onFinish)}>
        {createTurnoutData.error != null && <ErrorInfo error={createTurnoutData.error} />}
        {updateTurnoutData.error != null && <ErrorInfo error={updateTurnoutData.error} />}
        {deleteTurnoutsData.error != null && <ErrorInfo error={deleteTurnoutsData.error} />}

        {Array.isArray(turnout) && (
          <div>
            {turnout.map((turnout) => (
              <IxPill key={turnout.id}>
                {turnout.name}{' '}
                <IxIcon
                  name="close"
                  onClick={onRemoveItem.bind(null, turnout)}
                  size="12"
                  style={{ cursor: 'pointer' }}
                />
              </IxPill>
            ))}
          </div>
        )}

        {turnout != null && (
          <div className="col-12 d-flex justify-content-between">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <input
                className="form-control"
                type="checkbox"
                id="connectionCscAllowed"
                {...register('connectionCscAllowed')}
              />

              <label className="form-label" htmlFor="connectionCscAllowed">
                {t('models.turnout.connectionCscAllowed')}{' '}
                <IxIcon data-tooltip="update-turnout-form-connectionCscAllowed" name="about" />
                <IxTooltip for="[data-tooltip='update-turnout-form-connectionCscAllowed']">
                  <p>
                    {t(
                      'Once you have successfully entered all mandatory information about the point machine(s) that constitute this turnout, the connection to CSC will be established as soon as possible.',
                    )}
                  </p>

                  <p>{t("Uncheck this box if you don't want to approve of the automatic connection to CSC.")}</p>
                </IxTooltip>
              </label>
            </IxValidationTooltip>

            {!Array.isArray(turnout) && <IxPill>{t(`models.turnout.state-${turnout.connectionState}`)}</IxPill>}
          </div>
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <label htmlFor="name" className="form-label">
                {t('Name')} *
              </label>

              <input
                className={`form-control ${formState.errors.name ? 'is-invalid' : ''}`}
                id="name"
                type="text"
                {...register('name', { required: true })}
              />
            </IxValidationTooltip>
          </div>
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <label htmlFor="engineeringId" className="form-label">
                ID *
              </label>

              <input
                className={`form-control ${formState.errors.engineeringId ? 'is-invalid' : ''}`}
                id="engineeringId"
                type="text"
                {...register('engineeringId', { required: true })}
              />
            </IxValidationTooltip>
          </div>
        )}

        <div className="col-12 col-lg-6">
          <div>
            <label htmlFor="timezone" className="form-label">
              {t('Timezone')} *
            </label>

            <Controller
              control={control}
              name="timezone"
              rules={{ required: true }}
              render={({ field: { name, onChange, onBlur, ref, value } }) => (
                <IxSelect
                  className={`w-100 ${formState.errors.timezone ? 'is-invalid' : ''}`}
                  hideListHeader
                  i18nPlaceholder=""
                  id={name}
                  onBlur={onBlur}
                  onValueChange={(data) => onChange(data.detail)}
                  ref={ref}
                  value={value}
                >
                  {zones.map((zone) => (
                    <IxSelectItem
                      key={zone.timeZone}
                      label={`${zone.timeZone} GMT${zone.offset}`}
                      value={zone.timeZone}
                    />
                  ))}
                </IxSelect>
              )}
            />
          </div>
        </div>

        <div className="col-12"></div>

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <div>
              <label htmlFor="technicalRoom" className="form-label">
                {t('Technical room')} *
              </label>

              <Controller
                control={control}
                name="technicalRoom"
                rules={{ required: true }}
                render={({ field: { name, onChange, onBlur, ref, value } }) => (
                  <IxSelect
                    className={`w-100 ${formState.errors.technicalRoom ? 'is-invalid' : ''}`}
                    hideListHeader
                    i18nPlaceholder=""
                    id={name}
                    onBlur={onBlur}
                    onValueChange={(data) =>
                      onChange({ id: Number(Array.isArray(data.detail) ? data.detail[0] : data.detail) })
                    }
                    ref={ref}
                    value={value?.id == null ? [] : [String(value.id)]}
                  >
                    {technicalRooms?.map((entry) => (
                      <IxSelectItem key={entry.id} label={entry.name} value={String(entry.id)} />
                    ))}
                  </IxSelect>
                )}
              />
            </div>
          </div>
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <label htmlFor="waypoint" className="form-label">
                {t('models.turnout.waypoint')} *
              </label>

              <input
                className={`form-control ${formState.errors.waypoint ? 'is-invalid' : ''}`}
                id="waypoint"
                type="text"
                {...register('waypoint' as any, { required: true })}
              />
            </IxValidationTooltip>
          </div>
        )}

        <div>(*) - {t('Required field')}</div>

        <div className="d-flex justify-content-between mt-5">
          <div>
            {turnout != null && (
              <>
                <IxButton
                  id="UpdateTurnoutForm_Delete_turnout"
                  loading={deleteTurnoutsData.isMutating}
                  outline
                  variant="secondary"
                >
                  {t('Delete turnout', { count: Array.isArray(turnout) ? turnout.length : 1 })}
                </IxButton>

                <IxDropdown placement="top-start" trigger="UpdateTurnoutForm_Delete_turnout">
                  <IxDropdownHeader label={t('Are you sure?') ?? undefined} />

                  <IxDropdownQuickActions>
                    <IxIconButton icon="cancel" ghost />
                    <IxIconButton icon="single-check" ghost onClick={onDelete} />
                  </IxDropdownQuickActions>
                </IxDropdown>
              </>
            )}
          </div>

          <div>
            <IxButton className="me-2" onClick={onClickCancel} outline>
              {t('Cancel')}
            </IxButton>

            <IxButton
              disabled={turnout != null && !formState.isDirty}
              loading={createTurnoutData.isMutating || updateTurnoutData.isMutating}
              type="submit"
            >
              {turnout == null
                ? t(`routes.${routes.createTurnoutConfigurationRoute.name}`)
                : t('Save turnout', { count: Array.isArray(turnout) ? turnout.length : 1 })}
            </IxButton>
          </div>
        </div>
      </form>
    </ContextWrapper>
  )
}
