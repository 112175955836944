import { DateTime } from 'luxon'
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

interface PageHeaderParams {
  routeConfig: { [key: string]: any }
  addRouteConfig: (key: string, value: any) => void

  avatar?: ReactNode
  setAvatar: Dispatch<SetStateAction<PageHeaderParams['avatar']>>

  extra?: ReactNode
  setExtra: Dispatch<SetStateAction<PageHeaderParams['extra']>>

  lastUpdate?: DateTime
  setLastUpdate: Dispatch<SetStateAction<PageHeaderParams['lastUpdate']>>

  lastUpdateExtra?: ReactNode
  setLastUpdateExtra: Dispatch<SetStateAction<PageHeaderParams['lastUpdateExtra']>>

  title: ReactNode
  setTitle: Dispatch<SetStateAction<PageHeaderParams['title']>>

  subTitle?: ReactNode
  setSubTitle: Dispatch<SetStateAction<PageHeaderParams['subTitle']>>

  resetState: () => void
}

const defaultValue: PageHeaderParams = {
  routeConfig: {},
  addRouteConfig: () => null,

  setAvatar: () => null,
  setExtra: () => null,
  setSubTitle: () => null,
  setLastUpdate: () => null,
  setLastUpdateExtra: () => null,

  title: '',
  setTitle: () => null,

  resetState: () => null,
}

export const PageHeaderContext = createContext(defaultValue)
export const usePageHeader = () => useContext(PageHeaderContext)

interface PropTypes {}

export const PageHeaderProvider: FC<PropsWithChildren<PropTypes>> = ({ children }) => {
  const [avatar, setAvatar] = useState(defaultValue.avatar)
  const [extra, setExtra] = useState(defaultValue.extra)
  const [lastUpdate, setLastUpdate] = useState(defaultValue.lastUpdate)
  const [lastUpdateExtra, setLastUpdateExtra] = useState(defaultValue.lastUpdateExtra)
  const [routeConfig, setRouteConfig] = useState(defaultValue.routeConfig)
  const [subTitle, setSubTitle] = useState(defaultValue.subTitle)
  const [title, setTitle] = useState(defaultValue.title)

  const resetState = useCallback(() => {
    setAvatar(defaultValue.avatar)
    setExtra(defaultValue.extra)
    setLastUpdate(defaultValue.lastUpdate)
    setLastUpdateExtra(defaultValue.lastUpdateExtra)
    setRouteConfig(defaultValue.routeConfig)
    setSubTitle(defaultValue.subTitle)
    setTitle(defaultValue.title)
  }, [])

  const addRouteConfig = useCallback<PageHeaderParams['addRouteConfig']>((key, value) => {
    setRouteConfig((routeConfig) => ({ ...routeConfig, [key]: value }))
  }, [])

  return (
    <PageHeaderContext.Provider
      value={useMemo(
        () => ({
          routeConfig,
          addRouteConfig,

          avatar,
          setAvatar,

          extra,
          setExtra,

          lastUpdate,
          setLastUpdate,

          lastUpdateExtra,
          setLastUpdateExtra,

          title,
          setTitle,

          subTitle,
          setSubTitle,

          resetState,
        }),
        [addRouteConfig, avatar, extra, lastUpdate, lastUpdateExtra, resetState, routeConfig, subTitle, title],
      )}
    >
      {children}
    </PageHeaderContext.Provider>
  )
}
