import * as t from 'io-ts'

export const ConnectionStateStatisticsCodec = t.type({
  connected: t.number,
  disconnected: t.number,
})

export const OperationStateStatisticsCodec = t.type({
  green: t.number,
  yellow: t.number,
  red: t.number,
})

export const TurnoutStateStatisticsCodec = t.type({
  connectionState: ConnectionStateStatisticsCodec,
  operationState: OperationStateStatisticsCodec,
})
export type TurnoutStateStatistics = t.TypeOf<typeof TurnoutStateStatisticsCodec>
