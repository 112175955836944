import { JSX, PropsWithChildren, ReactNode, useMemo } from 'react'
import * as S from './styles'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import ContextWrapper, { ContextWrapperProps } from 'components/ContextWrapper'

export interface EventKpiTileProps extends IxJSX.IxTile, Omit<ContextWrapperProps, 'data'> {
  eventColor?: string
  precision?: number
  title?: ReactNode
  type?: 'inner'
  unit?: string
  value?: string | number
}

export function EventKpiTile({
  children,
  error,
  isLoading,
  precision = 0,
  unit,
  title,
  value,
  ...props
}: PropsWithChildren<EventKpiTileProps>): JSX.Element {
  const fixed = useMemo(() => {
    if (typeof value === 'number') {
      return value.toFixed(precision)
    }

    return value
  }, [precision, value])

  return (
    <S.Tile {...props}>
      <div slot="header">{title}</div>

      <ContextWrapper data={true} error={error} isLoading={isLoading} spinnerProps={{ size: 'small' }}>
        <div>
          {fixed != null && (
            <>
              <span className={`fs-${props.size === 'small' ? 5 : 3} fw-bold me-1`}>{fixed}</span>
              <span className={`fs-${props.size === 'small' ? 6 : 5} fw-bold`}>{unit}</span>
            </>
          )}

          {children != null && <div className="mt-1">{children}</div>}
        </div>
      </ContextWrapper>
    </S.Tile>
  )
}
