import { PointMachineApiClient, QueryPointMachineFilterOpts } from 'api'
import { Filter, SearchField } from 'components/DataFilter'
import { SWRResponse, useSWR } from 'lib/swr'
import {
  BaseModel,
  BasePointMachine,
  IdType,
  PagedPointMachines,
  PointMachine,
  RawPointMachine,
  ServiceError,
} from 'models'
import { PointMachineChangeLog } from 'models/point-machine-change-log'
import { useMemo } from 'react'
import { Key, useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { SWRMutationResponse } from 'swr/mutation/dist/mutation'
import { usePointMachineType, usePointMachineTypes } from './use-point-machine-type'
import { useMainProject } from './use-project'
import { useTrackLayerItems } from './use-tack-layer-items'
import { useTrackLayers } from './use-track-layer'
import { useTurnout, useTurnouts } from './use-turnout'

export const getPointMachinesKey = (parentId: Parameters<typeof usePointMachines>[0], parents?: BaseModel[]): Key => {
  if (parentId == null) {
    if (parents == null) {
      return null
    }

    return ['usePointMachines', ...parents.map((parent) => parent.id)]
  }

  return ['usePointMachines', parentId]
}

export const usePointMachines = (
  parentId?: IdType | null,
  pointMachineIds?: IdType[] | null,
): SWRResponse<PointMachine[]> => {
  const { data: parents, ...parentsData } = useTurnouts()
  const { data: types, ...typesData } = usePointMachineTypes()

  const { data: rawEntries, ...rawEntriesData } = useSWR(getPointMachinesKey(parentId, parents), async () => {
    if (parentId == null) {
      const responses = await Promise.all(parents!.map((parent) => PointMachineApiClient.list(parent.id)))
      const allEntries = responses.flatMap((entry) => entry.data)
      return { data: allEntries, dateTime: responses[0].dateTime }
    }

    return PointMachineApiClient.list(parentId)
  })

  const data = useMemo<PointMachine[] | undefined>(() => {
    const ids = pointMachineIds?.map(String)

    return rawEntries
      ?.filter((entry) => ids == null || ids.includes(String(entry.id)))
      ?.map((entry) => ({
        ...entry,
        turnout: parents?.find((parent) => parent.id === entry.turnoutId),
        type: types?.find((type) => type.id === entry.typeId),
      }))
  }, [pointMachineIds, rawEntries, parents, types])

  return useMemo(() => {
    return {
      data,
      ...rawEntriesData,
      isLoading: rawEntriesData.isLoading || parentsData.isLoading || typesData.isLoading,
      isValidating: rawEntriesData.isValidating || parentsData.isValidating || typesData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntriesData.mutate(), parentsData.mutate(), typesData.mutate()])
        return undefined
      },
    }
  }, [data, rawEntriesData, parentsData, typesData])
}

export const getPointMachineKey = (id: Parameters<typeof usePointMachine>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['usePointMachine', id]
}

export const usePointMachine = (id: IdType | undefined | null): SWRResponse<PointMachine> => {
  const { data: rawEntry, ...rawEntryData } = useSWR(getPointMachineKey(id), () => {
    return PointMachineApiClient.read(id!)
  })

  const { data: parent, ...parentData } = useTurnout(rawEntry?.turnoutId)
  const { data: type, ...typeData } = usePointMachineType(rawEntry?.typeId)

  const data = useMemo<PointMachine | undefined>(() => {
    if (rawEntry == null) {
      return undefined
    }

    return { ...rawEntry, turnout: parent, type }
  }, [rawEntry, parent, type])

  return useMemo(() => {
    return {
      data,
      ...rawEntryData,
      isLoading: rawEntryData.isLoading || parentData.isLoading || typeData.isLoading,
      isValidating: rawEntryData.isValidating || parentData.isValidating || typeData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntryData.mutate(), parentData.mutate(), typeData.mutate()])
        return undefined
      },
    }
  }, [data, rawEntryData, parentData, typeData])
}

export const getQueryPointMachinesKey = (opts: Parameters<typeof useQueryPointMachines>[0], parentId?: IdType): Key => {
  if (parentId == null) {
    return null
  }

  return ['useQueryPointMachines', parentId, JSON.stringify(opts)]
}

export const useQueryPointMachines = (
  opts?: Partial<QueryPointMachineFilterOpts> | null,
): SWRResponse<PagedPointMachines> => {
  const { data: mainProject } = useMainProject()
  const { data: types, ...typesData } = usePointMachineTypes()

  const swrResponse = useSWR(getQueryPointMachinesKey(opts, mainProject?.id), () => {
    return PointMachineApiClient.query(mainProject!.id, opts)
  })

  const data = useMemo<PagedPointMachines | undefined>(() => {
    if (swrResponse.data == null) {
      return undefined
    }

    return {
      ...swrResponse.data,
      content: swrResponse.data.content.map((entry) => ({
        ...entry,
        turnout: null,
        type: types?.find((type) => type.id === entry.typeId),
      })),
    }
  }, [swrResponse.data, types])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || typesData.isLoading,
      isValidating: swrResponse.isValidating || typesData.isValidating,
      mutate: async () => {
        await Promise.all([swrResponse.mutate(), typesData.mutate()])
        return undefined
      },
    }
  }, [data, swrResponse, typesData])
}

export const getPointMachineChangeLogsKey = (id: Parameters<typeof usePointMachineChangeLogs>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['usePointMachineChangeLogs', id]
}

export const usePointMachineChangeLogs = (id: IdType | undefined | null): SWRResponse<PointMachineChangeLog[]> => {
  return useSWR(getPointMachineChangeLogsKey(id), () => {
    return PointMachineApiClient.getChangeLogs(id!)
  })
}

export const useCreatePointMachine = (): SWRMutationResponse<RawPointMachine, ServiceError, BasePointMachine, Key> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useCreatePointMachine', async (_, { arg }) => {
    const { data: createdPointMachine } = await PointMachineApiClient.create(arg, arg.turnoutId)

    for (let key of cache.keys()) {
      if (key.includes('usePointMachine') || key.includes('useTurnout') || key.includes('useQueryTurnouts')) {
        mutate(key)
      }
    }

    return createdPointMachine
  })
}

export const useUpdatePointMachine = (): SWRMutationResponse<RawPointMachine, ServiceError, RawPointMachine, Key> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useUpdatePointMachine', async (_, { arg }) => {
    const { data: updatedPointMachine } = await PointMachineApiClient.update(arg)

    for (let key of cache.keys()) {
      if (key.includes('usePointMachine') || key.includes('useTurnout') || key.includes('useQueryTurnouts')) {
        mutate(key)
      }
    }

    return updatedPointMachine
  })
}

export const useDeletePointMachine = (): SWRMutationResponse<void, ServiceError, IdType, Key> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useDeletePointMachine', async (_, { arg }) => {
    await PointMachineApiClient.delete(arg)

    for (let key of cache.keys()) {
      if (key.includes('usePointMachine') || key.includes('useTurnout') || key.includes('useQueryTurnouts')) {
        mutate(key)
      }
    }
  })
}

export const usePointMachineSearchFields = (filters: Filter[], collapsible = false): SearchField[] => {
  const { data: trackLayers } = useTrackLayers()
  const { data: trackLayerItems } = useTrackLayerItems()

  return useMemo(() => {
    if (trackLayers == null) {
      return []
    }

    return trackLayers.reduce((searchFields, trackLayer, index) => {
      const parentSearchField = searchFields[index - 1] as SearchField | undefined
      const parentFilter = filters.find((filter) => filter.path === `${trackLayers[index - 1]?.type}Name`)
      const layerItems = trackLayerItems?.at(index)

      const filteredLayerItems = layerItems?.filter((layerItem) => {
        if (parentFilter != null && Array.isArray(parentFilter.value) && parentFilter.value.length > 0) {
          return parentFilter.value.some((filterValue) => filterValue === layerItem.parent?.name)
        }

        if (parentSearchField?.options != null) {
          return parentSearchField.options.some((option) => option.value === layerItem.parent?.name)
        }

        return true
      })

      return [
        ...searchFields,
        {
          collapsible,
          label: trackLayer.name,
          options: filteredLayerItems?.map((layerItem) => ({ label: layerItem.name, value: layerItem.name })),
          path: `${trackLayer.type}Name`,
          type: 'select',
        },
      ]
    }, [] as SearchField[])
  }, [collapsible, filters, trackLayerItems, trackLayers])
}
