import ContextWrapper from 'components/ContextWrapper'
import { useTrackLayerItems, useTrackLayers } from 'hooks'
import { TrackLayerItem } from 'models'
import { JSX, useEffect, useMemo, useRef, useState } from 'react'
import CreateTrackLayerItemModal from '../CreateTrackLayerItemModal'
import TrackLayerColumn from '../TrackLayerColumn'
import TrackLayerTile from '../TrackLayerTile'
import UpdateTrackLayerItemModal from '../UpdateTrackLayerItemModal'
import UpdateTrackLayerModal from '../UpdateTrackLayerModal'

export interface TrackConfigProps {
  showCreateTrackLayerItem: boolean
  showEditTrackLayerItem: boolean
  showEditTrackLayer: boolean
  trackLayer?: number
  trackLayerItemId?: string
}

export function TrackConfig({
  showCreateTrackLayerItem,
  showEditTrackLayer,
  showEditTrackLayerItem,
  trackLayer,
  trackLayerItemId,
}: TrackConfigProps): JSX.Element {
  const [selection, setSelection] = useState<Record<number, number | undefined>>({})
  const { data: trackLayers, ...trackLayersData } = useTrackLayers()
  const { data: layerItems, ...layerItemsData } = useTrackLayerItems()
  const isInitRef = useRef(false)

  const editTrackLayer = useMemo(() => {
    return trackLayer == null ? undefined : trackLayers?.at(trackLayer - 1)
  }, [trackLayer, trackLayers])

  useEffect(() => {
    const firstItem = layerItems?.at(0)?.at(0)

    if (!isInitRef.current && firstItem != null) {
      setSelection({ 1: firstItem.id })
      isInitRef.current = true
    }
  }, [layerItems])

  return (
    <div className="row g-3">
      <ContextWrapper data={trackLayers} error={trackLayersData.error} isLoading={trackLayersData.isLoading}>
        {(trackLayers) => (
          <>
            {trackLayers.map((layer) => (
              <div className="col-12 col-lg-4" key={layer.depth}>
                <TrackLayerColumn layer={layer}>
                  <ContextWrapper data={layerItems} error={layerItemsData.error} isLoading={layerItemsData.isLoading}>
                    {(layerItems) => {
                      return (
                        <div className="row g-2">
                          {layerItems
                            .at(layer.depth - 1)
                            ?.filter(
                              (layerItem) =>
                                layerItem.type === 'area' || layerItem.parent?.id === selection[layer.depth - 1],
                            )
                            .map((item) => (
                              <TrackLayerTile
                                active={selection[layer.depth] === item.id}
                                item={item}
                                key={item.id}
                                layer={layer}
                                onClick={() =>
                                  setSelection((prev) => {
                                    return trackLayers.reduce((selection, _layer) => {
                                      if (_layer.depth > layer.depth) {
                                        return { ...selection, [layer.depth]: item.id, [_layer.depth]: undefined }
                                      }

                                      return { ...selection, [layer.depth]: item.id }
                                    }, prev)
                                  })
                                }
                              />
                            ))}
                        </div>
                      )
                    }}
                  </ContextWrapper>
                </TrackLayerColumn>
              </div>
            ))}

            <CreateTrackLayerItemModal
              layer={editTrackLayer}
              layerItem={
                selection[(trackLayer ?? 0) - 1] == null
                  ? undefined
                  : ({ parent: selection[(trackLayer ?? 0) - 1] } as TrackLayerItem)
              }
              visible={showCreateTrackLayerItem}
            />

            <UpdateTrackLayerItemModal
              layer={editTrackLayer}
              layerItemId={trackLayerItemId}
              visible={showEditTrackLayerItem}
            />

            <UpdateTrackLayerModal layer={editTrackLayer} layers={trackLayers} visible={showEditTrackLayer} />
          </>
        )}
      </ContextWrapper>
    </div>
  )
}
