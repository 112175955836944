import GlobalPageHeader from 'components/GlobalPageHeader'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import AlarmsListing from './components/AlarmsListing'

const route = routes.alarmsRoute

export function AlarmsPage(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <AlarmsListing />
      </GlobalPageHeader>
    </>
  )
}
