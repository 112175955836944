import { IxBasicNavigation, IxMenuCategory, IxMenuItem } from '@siemens/ix-react'
import { useIsAdmin } from 'hooks'
import * as routes from 'lib/routes'
import { JSX, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom'
import OsBar from './components/OsBar'
import { getActiveKeys } from './lib'
import * as S from './styles'

export interface PageLayoutProps {}

export function PageLayout({ children }: PropsWithChildren<PageLayoutProps>): JSX.Element {
  const { t } = useTranslation()
  const isAdmin = useIsAdmin()
  const location = useLocation()
  const keys = useMemo(() => getActiveKeys(location), [location])
  const navigate = useNavigate()

  return (
    <>
      <OsBar />

      <IxBasicNavigation hideHeader>
        <S.Menu>
          <Link to={generatePath(routes.homeRoute.pathname)}>
            <IxMenuItem active={keys.join('').replaceAll('/', '').length === 0} icon={routes.homeRoute.icon}>
              {t(`routes.${routes.homeRoute.name}`)}
            </IxMenuItem>
          </Link>
          <Link to={generatePath(routes.turnoutsRoute.pathname)}>
            <IxMenuItem active={keys?.includes(routes.turnoutsRoute.pathname)} icon={routes.turnoutsRoute.icon}>
              {t(`routes.${routes.turnoutsRoute.name}`)}
            </IxMenuItem>
          </Link>
          <Link to={generatePath(routes.alarmsRoute.pathname)}>
            <IxMenuItem active={keys?.includes(routes.alarmsRoute.pathname)} icon={routes.alarmsRoute.icon}>
              {t(`routes.${routes.alarmsRoute.name}`)}
            </IxMenuItem>
          </Link>

          {isAdmin && (
            <IxMenuCategory
              label={t(`routes.${routes.configurationRoute.name}`) ?? undefined}
              icon={routes.configurationRoute.icon}
            >
              <IxMenuItem
                active={keys?.includes(routes.trackConfigurationRoute.pathname)}
                icon={routes.trackConfigurationRoute.icon}
                onClick={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
              >
                {t(`routes.${routes.trackConfigurationRoute.name}`)}
              </IxMenuItem>

              <IxMenuItem
                active={keys?.includes(routes.assetConfigurationRoute.pathname)}
                icon={routes.assetConfigurationRoute.icon}
                onClick={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
              >
                {t(`routes.${routes.assetConfigurationRoute.name}`)}
              </IxMenuItem>

              <IxMenuItem
                active={keys?.includes(routes.thresholdConfigurationRoute.pathname)}
                icon={routes.thresholdConfigurationRoute.icon}
                onClick={() => navigate(generatePath(routes.thresholdConfigurationRoute.pathname))}
              >
                {t(`routes.${routes.thresholdConfigurationRoute.name}`)}
              </IxMenuItem>

              <IxMenuItem
                active={keys?.includes(routes.userConfigurationRoute.pathname)}
                icon={routes.userConfigurationRoute.icon}
                onClick={() => navigate(generatePath(routes.userConfigurationRoute.pathname))}
              >
                {t(`routes.${routes.userConfigurationRoute.name}`)}
              </IxMenuItem>
            </IxMenuCategory>
          )}
        </S.Menu>

        <S.MainContent>
          <div className="container">{children}</div>
        </S.MainContent>
      </IxBasicNavigation>
    </>
  )
}
