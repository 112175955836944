import { IxTile } from '@siemens/ix-react'
import { QueryAlarmEventsFilterOpts } from 'api'
import ContextWrapper from 'components/ContextWrapper'
import EventKpiTile from 'components/EventKpiTile'
import VegaContainer from 'components/VegaContainer'
import { useProjectConfiguration, useQueryAlarms } from 'hooks'
import { DateTime } from 'luxon'
import { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const from = DateTime.now().minus({ hour: 24 })

export interface AlarmsKpiBarProps {
  filterOpts: Partial<QueryAlarmEventsFilterOpts>
}

export function AlarmsKpiBar({ filterOpts }: AlarmsKpiBarProps): JSX.Element {
  const { data: allAlarms, ...allAlarmsData } = useQueryAlarms({
    ...filterOpts,
    from,
    size: 1,
  })

  const { data: resolvedAlarms, ...resolvedAlarmsData } = useQueryAlarms({
    ...filterOpts,
    from,
    size: 1,
    state: 'resolved',
  })

  const { data: failureAlarms, ...failureAlarmsData } = useQueryAlarms({
    ...filterOpts,
    size: 1,
    state: 'active',
    severity: 'failure',
  })

  const { data: warningAlarms, ...warningAlarmsData } = useQueryAlarms({
    ...filterOpts,
    size: 1,
    state: 'active',
    severity: 'warning',
  })

  const { data: projectConfiguration, ...projectConfigurationData } = useProjectConfiguration()

  const { t } = useTranslation()

  const values = useMemo(() => {
    if (failureAlarms == null || warningAlarms == null) {
      return undefined
    }

    return [
      {
        count: failureAlarms.totalElements,
        severity: t('models.alarmEvent.severity-failure-with-count', { count: failureAlarms.totalElements }),
      },
      {
        count: warningAlarms.totalElements,
        severity: t('models.alarmEvent.severity-warning-with-count', { count: warningAlarms.totalElements }),
      },
    ]
  }, [failureAlarms, t, warningAlarms])

  return (
    <>
      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          error={allAlarmsData.error}
          isLoading={allAlarmsData.isLoading}
          title={t('Alarms triggered last 24h')}
          value={allAlarms?.totalElements}
        />
      </div>

      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          error={resolvedAlarmsData.error}
          isLoading={resolvedAlarmsData.isLoading}
          title={t('Alarms recovered last 24h')}
          value={resolvedAlarms?.totalElements}
        />
      </div>

      <div className="col-12 col-sm-6 col-xl-3">
        <IxTile>
          <div slot="header">{t('Active alarms')}</div>

          <ContextWrapper
            data={true}
            error={failureAlarmsData.error ?? warningAlarmsData.error}
            isLoading={failureAlarmsData.isLoading || warningAlarmsData.isLoading}
          >
            <div>
              {values != null && values.length > 0 && (
                <VegaContainer
                  spec={{
                    data: { values },
                    mark: 'bar',
                    encoding: {
                      x: {
                        aggregate: 'sum',
                        field: 'count',
                        axis: null,
                        title: null,
                      },
                      color: {
                        field: 'severity',
                        scale: { range: ['var(--theme-color-alarm)', 'var(--theme-color-warning)'] },
                        sort: [values[0].severity, values[1].severity],
                        title: null,
                        type: 'nominal',
                      },
                    },
                  }}
                />
              )}
            </div>
          </ContextWrapper>
        </IxTile>
      </div>

      <div className="col-12 col-sm-6 col-xl-3">
        <EventKpiTile
          error={projectConfigurationData.error}
          isLoading={projectConfigurationData.isLoading}
          title={t('routes.Alarm thresholds')}
        >
          <div>
            <svg className="me-1" height={10} width={10}>
              <circle fill="var(--theme-color-warning)" r={5} cx={5} cy={5} />
            </svg>
            {t('Health score below {threshold}', { threshold: projectConfiguration?.thresholdWarning })}
          </div>

          <div className="mt-2">
            <svg className="me-1" height={10} width={10}>
              <circle fill="var(--theme-color-alarm)" r={5} cx={5} cy={5} />
            </svg>
            {t('Health score below {threshold}', { threshold: projectConfiguration?.thresholdFailure })}
          </div>
        </EventKpiTile>
      </div>
    </>
  )
}
