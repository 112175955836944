import { IxToggle } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import { JSX, useCallback, useMemo } from 'react'
import { Option } from '../../types'
import { FieldProps } from '../Field'
import * as S from './styles'

export function ToggleField({ field, value, ...props }: FieldProps): JSX.Element {
  const options = useMemo(() => {
    return field.options?.map((option) => ({ ...option, value: String(option.value) })) ?? []
  }, [field.options])

  const onCheckedChange = useCallback(
    (option: Option): NonNullable<IxJSX.IxToggle['onCheckedChange']> => {
      return (event) => {
        const arrValue = Array.isArray(value?.value) ? value?.value ?? [] : []

        const newValue = event.detail
          ? Array.from(new Set([...arrValue, option.value]))
          : arrValue.filter((item) => item !== option.value)
        return props.onChange(field.path, newValue)
      }
    },
    [field.path, props, value],
  )

  return (
    <div className="row gx-2">
      {options.map((option) => (
        <div className="col-auto" key={option.value}>
          <S.ToggleWrapper>
            <IxToggle
              checked={Array.isArray(value?.value) ? value?.value.includes(option.value) : false}
              textOff={option.label ?? String(option.value)}
              textOn={option.label ?? String(option.value)}
              onCheckedChange={onCheckedChange(option)}
            />
          </S.ToggleWrapper>
        </div>
      ))}
    </div>
  )
}
