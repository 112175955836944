import * as t from 'io-ts'
import { BaseModelCodec, NamedTypeCodec, nullableType } from './base'

export const TrackLayerTypeCodec = t.union([t.literal('area'), t.literal('interlocking'), t.literal('technicalRoom')])
export type TrackLayerType = t.TypeOf<typeof TrackLayerTypeCodec>

export const TrackLayerCodec = t.type({
  depth: t.number,
  name: t.string,
  type: TrackLayerTypeCodec,
})

export type TrackLayer = t.TypeOf<typeof TrackLayerCodec>

export const TrackLayerItemCodec = t.intersection([
  BaseModelCodec,
  NamedTypeCodec,
  t.type({
    parent: nullableType(t.intersection([BaseModelCodec, NamedTypeCodec])),
    type: TrackLayerTypeCodec,
  }),
  t.partial({
    technicalId: t.string,
  }),
])
export type TrackLayerItem = t.TypeOf<typeof TrackLayerItemCodec>
