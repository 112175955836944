import * as t from 'io-ts'
import { BaseModelCodec, IdTypeCodec, NamedTypeCodec, nullableType } from './base'
import { ProjectCodec } from './project'

export const BaseAreaCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    projectId: IdTypeCodec,
  }),
])
export const BaseAreasCodec = t.array(BaseAreaCodec)
export type BaseArea = t.TypeOf<typeof BaseAreaCodec>

export const RawAreaCodec = t.intersection([BaseModelCodec, BaseAreaCodec])
export const RawAreasCodec = t.array(RawAreaCodec)
export type RawArea = t.TypeOf<typeof RawAreaCodec>

export const AreaCodec = t.intersection([
  RawAreaCodec,
  t.type({
    project: nullableType(ProjectCodec),
  }),
])
export const AreasCodec = t.array(AreaCodec)
export type Area = t.TypeOf<typeof AreaCodec>
