import { RouteConfig } from '..'
import { configurationRoute } from './lib'

export const userConfigurationRoute: RouteConfig<
  `${typeof configurationRoute.pathname}/user`,
  typeof configurationRoute
> = {
  icon: 'user-settings',
  name: 'User configuration',
  parent: configurationRoute,
  pathname: `${configurationRoute.pathname}/user`,
}

export const editUserConfigurationRoute: RouteConfig<
  `${typeof userConfigurationRoute.pathname}/edit/:userId`,
  typeof userConfigurationRoute
> = {
  name: 'Edit user configuration',
  parent: userConfigurationRoute,
  pathname: `${userConfigurationRoute.pathname}/edit/:userId`,
}
