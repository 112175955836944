import { IxSpinner, IxToggle, showToast } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import { ICellRendererParams } from 'ag-grid-community'
import { useUpdatePointMachineEvents } from 'hooks'
import { PointMachineEvent, RawPointMachineEvent } from 'models'
import { JSX, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function OutlierCell({ data }: ICellRendererParams<PointMachineEvent>): JSX.Element | null {
  const [model, setModel] = useState<RawPointMachineEvent>()
  const { t } = useTranslation()
  const { trigger } = useUpdatePointMachineEvents()

  useEffect(() => {
    setModel(data)
  }, [data])

  const onCheckedChange = useCallback<NonNullable<IxJSX.IxToggle['onCheckedChange']>>(
    async (event) => {
      if (model == null) {
        return
      }

      let loadingToast: Awaited<ReturnType<typeof showToast>>

      try {
        loadingToast = await showToast({
          message: (
            <div className="d-flex align-items-center">
              {t('Updating event')} <IxSpinner className="ms-1" />
            </div>
          ),
          autoClose: false,
        })
      } catch (error) {
        console.error(error)
      }

      try {
        const updatedModels = await trigger([{ ...model, isOutlier: event.detail }])
        setModel(updatedModels?.[0])

        setTimeout(() => {
          loadingToast.close()
          showToast({ message: 'Successfully updated event', type: 'success' })
        }, 500)
      } catch (error) {
        setTimeout(() => {
          loadingToast.close()
          showToast({ message: 'Updating event was unsuccessful', type: 'error' })
        }, 500)
      }
    },
    [model, t, trigger],
  )

  if (model == null) {
    return null
  }

  return <IxToggle checked={model.isOutlier} hideText onCheckedChange={onCheckedChange} />
}
