import { IxTile } from '@siemens/ix-react'
import { TurnoutStateStatisticsFilterOpts } from 'api'
import ContextWrapper from 'components/ContextWrapper'
import { useTurnoutStateStatistics } from 'hooks'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import HealthChart from '../HealthChart'

export interface HealthKpiTileProps {
  filterOpts: Partial<TurnoutStateStatisticsFilterOpts>
}

export function HealthKpiTile({ filterOpts }: HealthKpiTileProps): JSX.Element {
  const { t } = useTranslation()
  const { data: stateStatistics, ...stateStatisticsData } = useTurnoutStateStatistics(filterOpts)

  return (
    <IxTile>
      <div slot="header">{t('Overview all turnouts')}</div>

      <div>
        <ContextWrapper
          data={stateStatistics}
          error={stateStatisticsData.error}
          isLoading={stateStatisticsData.isLoading}
        >
          {(stateStatistics) => <HealthChart stateStatistics={stateStatistics} />}
        </ContextWrapper>
      </div>
    </IxTile>
  )
}
