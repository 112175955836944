import GlobalPageHeader from 'components/GlobalPageHeader'
import UserConfigTable from 'components/UserConfigTable'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import UpdateUserModal from './components/UpdateUserModal'

const route = routes.userConfigurationRoute

export function UserConfigurationPage(): JSX.Element {
  const { t } = useTranslation()
  const editUserMatch = useMatch(routes.editUserConfigurationRoute.pathname)

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <UserConfigTable />

        <UpdateUserModal userId={editUserMatch?.params.userId as any} visible={editUserMatch != null} />
      </GlobalPageHeader>
    </>
  )
}
