import { IxPagination, IxSpinner } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import { ColDef, GridOptions, IRowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import Empty, { EmptyProps } from 'components/Empty'
import ErrorInfo from 'components/ErrorInfo'
import { BaseModel } from 'models'
import { CSSProperties, ForwardedRef, JSX, RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as S from './styles'

interface SelectionItem<T extends BaseModel> {
  data: T
  selected: boolean
}

export interface TableProps<T extends BaseModel> extends GridOptions<T> {
  className?: string
  emptyProps?: EmptyProps
  error?: Error
  gridRef?: ForwardedRef<AgGridReact<T>>
  isLoading?: boolean
  paginationProps?: Partial<IxJSX.IxPagination> | false
  selectable?: boolean | { columnIndex: number }
  style?: CSSProperties
}

export function Table<T extends BaseModel>({
  className,
  emptyProps,
  error,
  gridRef,
  isLoading,
  paginationPageSize = 15,
  paginationProps,
  selectable,
  style,
  ...props
}: TableProps<T>): JSX.Element {
  const [selectedAll, setSelectedAll] = useState(false)
  const [selection, setSelection] = useState<SelectionItem<T>[]>([])
  const ref = useRef<AgGridReact<T> | null>(null)

  const columnDefs = useMemo<GridOptions<T>['columnDefs']>(() => {
    if (props.columnDefs == null || !selectable) {
      return props.columnDefs
    }

    const exportableColumnIndex = typeof selectable === 'boolean' ? 0 : selectable.columnIndex

    return [
      ...props.columnDefs.slice(0, exportableColumnIndex),
      {
        ...props.columnDefs[exportableColumnIndex],
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      ...props.columnDefs.slice(exportableColumnIndex + 1),
    ]
  }, [selectable, props.columnDefs])

  const pageCount = useMemo(() => {
    return props.rowData == null ? undefined : Math.ceil(props.rowData.length / paginationPageSize)
  }, [paginationPageSize, props.rowData])

  useEffect(() => {
    if (isLoading) {
      return ref.current?.api?.showLoadingOverlay()
    }

    if (props.rowData != null && props.rowData.length === 0) {
      return ref.current?.api?.showNoRowsOverlay()
    }

    ref.current?.api?.hideOverlay()
  }, [isLoading, props.rowData])

  useEffect(() => {
    const selectedNodes: IRowNode<T>[] = []
    const deselectedNodes: IRowNode<T>[] = []

    ref.current?.api?.forEachNode((node) => {
      const item = selection.find((item) => item.data.id === node.data?.id)
      if (item == null && !selectedAll) {
        return
      }

      if (item?.selected === false) {
        return deselectedNodes.push(node)
      }

      selectedNodes.push(node)
    })

    ref.current?.api?.setNodesSelected({ nodes: selectedNodes, newValue: true })
    ref.current?.api?.setNodesSelected({ nodes: deselectedNodes, newValue: false })
  }, [paginationProps, selectedAll, selection])

  const fit = useCallback(() => {
    if ((props.columnDefs ?? []).filter((column) => (column as ColDef<T>).flex != null).length === 0) {
      // ref.current?.api?.sizeColumnsToFit()
    }
  }, [props.columnDefs])

  useEffect(() => {
    window.addEventListener('resize', fit)
    fit()

    return () => {
      window.removeEventListener('resize', fit)
    }
  })

  const loadingOverlay = useCallback(() => <IxSpinner />, [])
  const noRowsOverlay = useCallback(() => <Empty {...emptyProps} />, [emptyProps])

  const onPageSelected = useCallback<NonNullable<IxJSX.IxPagination['onPageSelected']>>((event) => {
    ref.current?.api.paginationGoToPage(event.detail)
  }, [])

  const onRef = useCallback<RefCallback<AgGridReact<T>>>(
    (element) => {
      ref.current = element

      if (gridRef == null) {
        return
      }

      if (typeof gridRef === 'function') {
        return gridRef(element)
      }

      gridRef.current = element
    },
    [gridRef],
  )

  const onSelectionChanged = useCallback<NonNullable<TableProps<T>['onSelectionChanged']>>(
    (event) => {
      if (event.source.startsWith('api')) {
        return
      }

      const selectedRows = event.api.getSelectedRows().map((row): SelectionItem<T> => ({ data: row, selected: true }))
      const deselectedRows = (props.rowData ?? [])
        .filter((row) => !selectedRows.some((selectedRow) => selectedRow.data.id === row.id))
        .map((row): SelectionItem<T> => ({ data: row, selected: false }))

      if (event.source === 'uiSelectAll') {
        setSelectedAll(selectedRows.length === props.rowData?.length)
        return setSelection([])
      }

      setSelection((prev) => {
        if (paginationProps == null) {
          return selectedRows
        }

        const notOnPage = prev.filter((item) => !props.rowData?.some((row) => item.data.id === row.id)) ?? []

        if (selectedAll) {
          return [...notOnPage, ...deselectedRows]
        }

        return [...notOnPage, ...selectedRows]
      })
    },
    [paginationProps, props.rowData, selectedAll],
  )

  if (error != null) {
    return <ErrorInfo error={error} />
  }

  return (
    <S.TableWrapper className={`ag-theme-alpine-dark ag-theme-ix ${className ?? ''}`} style={style}>
      <AgGridReact
        animateRows
        domLayout="autoHeight"
        loadingOverlayComponent={loadingOverlay}
        noRowsOverlayComponent={noRowsOverlay}
        onGridReady={fit}
        onSelectionChanged={onSelectionChanged}
        pagination
        paginationPageSize={paginationPageSize}
        ref={onRef}
        rowSelection={Boolean(selectable) ? 'multiple' : undefined}
        suppressMovableColumns
        suppressPaginationPanel
        suppressRowClickSelection
        {...props}
        columnDefs={columnDefs}
      />

      <div className="d-flex justify-content-end">
        {paginationProps !== false && props.rowData != null && props.rowData.length > 0 && (
          <IxPagination
            count={pageCount}
            itemCount={paginationPageSize}
            onPageSelected={onPageSelected}
            showItemCount
            {...paginationProps}
          />
        )}
      </div>
    </S.TableWrapper>
  )
}
