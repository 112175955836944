import Modal, { ModalContent } from 'components/Modal'
import UpdateTurnoutForm from 'components/UpdateTurnoutForm'
import * as routes from 'lib/routes'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

export interface CreateTurnoutModalProps {
  visible: boolean
}

export function CreateTurnoutModal({ visible }: CreateTurnoutModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.createTurnoutConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
        title={t(`routes.${routes.createTurnoutConfigurationRoute.name}`)}
      >
        <UpdateTurnoutForm
          onAfterSubmit={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
