import { turnoutStateToColorMap } from 'models'

export type ConditionTypes = 'healthy' | 'warning' | 'interference'

export interface Condition {
  color: string
}

export type Conditions = {
  [x in ConditionTypes]: Condition
}

export const conditions: Conditions = {
  healthy: { color: `var(--theme-${turnoutStateToColorMap.healthy})` },
  warning: { color: `var(--theme-${turnoutStateToColorMap.warning})` },
  interference: { color: `var(--theme-${turnoutStateToColorMap.alert})` },
}
