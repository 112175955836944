import { IdType, LevelNames, LevelNamesCodec } from 'models'
import { ApiClientResponse, BaseApiClient } from './base'
import { ProjectApiClient } from './project'
import { Methods } from './utils'

class LevelNamesApiClient extends BaseApiClient {
  async list(projectId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<LevelNames>> {
    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), this.endpointUrl].join('/')
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, LevelNamesCodec) }
  }

  async update(projectId: IdType, value: LevelNames, signal?: AbortSignal): Promise<ApiClientResponse<LevelNames>> {
    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), this.endpointUrl].join('/')
    const { data, ...rest } = await this.request(path, Methods.PUT, value, undefined, signal)
    return { ...rest, data: await this.decode(data, LevelNamesCodec) }
  }
}

const instance = new LevelNamesApiClient('level-names')

export { instance as LevelNamesApiClient }
