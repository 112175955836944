import { styled } from 'styled-components'

export const EventColumn = styled.div<{ color: string }>`
  background-color: var(--theme-${({ color }) => color});
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  height: 100%;
  max-width: 0.5rem;
  min-width: 0.5rem;
  width: 0.5rem;
`
