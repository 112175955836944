import { ICellRendererParams } from 'ag-grid-community'
import * as routes from 'lib/routes'
import { AlarmEvent } from 'models'
import { JSX } from 'react'
import { generatePath, Link } from 'react-router-dom'

export function TurnoutIdCell({ data }: ICellRendererParams<AlarmEvent>): JSX.Element | null {
  if (data == null) {
    return null
  }

  return (
    <Link to={generatePath(routes.turnoutRoute.pathname, { turnoutId: String(data.turnout.id) })}>
      {data.turnout.engineeringId}
    </Link>
  )
}
