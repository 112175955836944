import styled from 'styled-components'

export const UserMenuWrapper = styled.div`
  padding: 2rem;
`

export const RedButtonWrapper = styled.div`
  --theme-btn-invisible-primary--color--active: var(--theme-color-alarm--active);
  --theme-btn-invisible-primary--color--hover: var(--theme-color-alarm--hover);
  --theme-btn-invisible-primary--color: var(--theme-color-alarm);
`
