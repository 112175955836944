import { Location } from 'react-router-dom'

export const getActiveKeys = (location: Location): string[] => {
  if (location.pathname.length === 0) {
    return []
  }

  const parts = location.pathname.split('/')

  const keys = [
    location.pathname,
    ...parts.map((v) => `/${v}`),
    ...location.pathname.split('/').map((_, index, arr) => arr.slice(0, index + 1).join('/')),
  ]

  return Array.from(new Set(keys))
}
