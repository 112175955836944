import { IxTabItem, IxTabs } from '@siemens/ix-react'
import GlobalPageHeader from 'components/GlobalPageHeader'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { generatePath, useMatch, useNavigate } from 'react-router-dom'
import AlarmThresholdConfig from './components/AlarmThresholdConfig'
import ParameterThresholdConfig from './components/ParameterThresholdConfig'
import ReferenceCurveConfig from './components/ReferenceCurveConfig'

const route = routes.thresholdConfigurationRoute

export function ThresholdConfigurationPage(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const alarmMatch = useMatch(routes.alarmThresholdConfigurationRoute.pathname)
  const referenceMatch = useMatch(routes.referenceThresholdConfigurationRoute.pathname)
  const editTurnoverMatch = useMatch(routes.editParameterThresholdConfigurationRoute.pathname)

  const editTurnoverPMIds = useMemo(() => {
    return editTurnoverMatch?.params.pointMachineId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
  }, [editTurnoverMatch])

  const tab = useMemo(() => {
    if (referenceMatch != null) {
      return { index: 1, content: <ReferenceCurveConfig /> }
    }

    if (alarmMatch != null) {
      return { index: 2, content: <AlarmThresholdConfig /> }
    }

    return { index: 0, content: <ParameterThresholdConfig editPointMachineIds={editTurnoverPMIds} /> }
  }, [alarmMatch, editTurnoverPMIds, referenceMatch])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <IxTabs selected={tab.index}>
          <IxTabItem onClick={() => navigate(generatePath(routes.parameterThresholdConfigurationRoute.pathname))}>
            {t(`routes.${routes.parameterThresholdConfigurationRoute.name}`)}
          </IxTabItem>

          <IxTabItem onClick={() => navigate(generatePath(routes.referenceThresholdConfigurationRoute.pathname))}>
            {t(`routes.${routes.referenceThresholdConfigurationRoute.name}`)}
          </IxTabItem>

          <IxTabItem onClick={() => navigate(generatePath(routes.alarmThresholdConfigurationRoute.pathname))}>
            {t(`routes.${routes.alarmThresholdConfigurationRoute.name}`)}
          </IxTabItem>
        </IxTabs>

        <div className="mt-4">{tab.content}</div>
      </GlobalPageHeader>
    </>
  )
}
