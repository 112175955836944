import { IxSpinner, IxTooltip } from '@siemens/ix-react'
import AlarmTabs from 'components/AlarmTabs'
import ContextWrapper from 'components/ContextWrapper'
import PointMachineEventCharts from 'components/PointMachineEventCharts'
import { usePageHeader } from 'contexts/PageHeader'
import { useProjectConfiguration, useTurnout } from 'hooks'
import * as routes from 'lib/routes'
import { DateTime } from 'luxon'
import { IdType } from 'models'
import { JSX, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PointMachineInfoTile from '../PointMachineInfoTile'
import TurnoutHealthTile from '../TurnoutHealthTile'
import TurnoutKpiBar from '../TurnoutKpiBar'
import { useLastTurnoutEvent } from './hooks'

export interface TurnoutDetailProps {
  turnoutId: IdType
}

export function TurnoutDetail({ turnoutId }: TurnoutDetailProps): JSX.Element {
  const { addRouteConfig, setLastUpdate, setLastUpdateExtra, setTitle } = usePageHeader()
  const { data: turnout, ...turnoutData } = useTurnout(turnoutId)
  const { i18n, t } = useTranslation()
  const { data: lastTurnoutEvent, ...lastTurnoutEventData } = useLastTurnoutEvent(turnout)
  const { data: projectConfiguration, ...projectConfigurationData } = useProjectConfiguration()

  useEffect(() => {
    if (turnout != null) {
      window.requestAnimationFrame(() => {
        addRouteConfig(routes.turnoutRoute.pathname, turnout)
        setTitle(routes.turnoutRoute.render?.(turnout))
      })
    }
  }, [addRouteConfig, setTitle, turnout])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      setLastUpdate(turnoutData.dateTime)
    })
  }, [setLastUpdate, turnoutData.dateTime])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (lastTurnoutEventData.isLoading || projectConfigurationData.isLoading) {
        return setLastUpdateExtra(
          <>
            ;&nbsp;&nbsp;
            <IxSpinner size="xx-small" style={{ display: 'inline-block' }} />
          </>,
        )
      }

      if (lastTurnoutEventData.error != null) {
        return setLastUpdateExtra(<>;&nbsp;&nbsp;{t('Error while loading last status')}</>)
      }

      if (lastTurnoutEvent == null) {
        return setLastUpdateExtra(<>;&nbsp;&nbsp;{t('Last status not found')}</>)
      }

      const lastTurnoutEventDateTime = DateTime.fromISO(lastTurnoutEvent.start)
      const timeString = lastTurnoutEventDateTime.setLocale(i18n.language).toRelative()

      const healthStatus =
        projectConfiguration == null || lastTurnoutEvent.healthScore == null
          ? undefined
          : lastTurnoutEvent.healthScore < projectConfiguration.thresholdFailure
          ? t('models.turnout.state-alert')
          : lastTurnoutEvent.healthScore < projectConfiguration.thresholdWarning
          ? t('models.turnout.state-warning')
          : t('models.turnout.state-healthy')

      setLastUpdateExtra(
        <>
          <span>
            ;&nbsp;&nbsp;
            <span data-tooltip="last-connection-tooltip">
              {t('Last connection')}: {timeString}
            </span>
            <IxTooltip for="[data-tooltip='last-connection-tooltip']">
              {lastTurnoutEventDateTime.setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)}
            </IxTooltip>
          </span>

          {healthStatus != null && (
            <span>
              ;&nbsp;&nbsp;
              <span data-tooltip="last-status-tooltip">
                {t('Last status received')}: {healthStatus}
              </span>
              <IxTooltip for="[data-tooltip='last-status-tooltip']">
                {t('Health score')}: {lastTurnoutEvent.healthScore.toFixed(2)}
              </IxTooltip>
            </span>
          )}
        </>,
      )
    })
  }, [
    i18n.language,
    lastTurnoutEvent,
    lastTurnoutEventData.error,
    lastTurnoutEventData.isLoading,
    projectConfiguration,
    projectConfigurationData.isLoading,
    setLastUpdateExtra,
    t,
  ])

  return (
    <div className="row g-5">
      <div className="col-12">
        <div style={{ width: '100%' }}>
          <ContextWrapper data={turnout} error={turnoutData.error} isLoading={turnoutData.isLoading}>
            {(turnout) => <TurnoutKpiBar turnout={turnout} />}
          </ContextWrapper>
        </div>
      </div>

      <div className="col-12">
        {turnout != null && (
          <AlarmTabs filterOpts={{ turnoutName: turnout.name }} title={t('routes.Alarms')} turnout={turnout} />
        )}
      </div>

      <div className="col-12">{turnout != null && <TurnoutHealthTile turnout={turnout} />}</div>

      <div className="col-12">
        <PointMachineEventCharts turnoutId={turnoutId} />
      </div>

      <div className="col-12">
        <PointMachineInfoTile turnoutId={turnoutId} />
      </div>
    </div>
  )
}
