import ContextWrapper from 'components/ContextWrapper'
import Modal, { ModalContent } from 'components/Modal'
import UpdateParameterThresholdsForm, {
  UpdateParameterThresholdsFormProps,
} from 'components/UpdateParameterThresholdsForm'
import { usePointMachines, useThresholds } from 'hooks'
import * as routes from 'lib/routes'
import { IdType } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

type FormDataWrapperProps = Pick<UpdateParameterThresholdsFormProps, 'onAfterSubmit' | 'onCancel' | 'onRemoveItem'> &
  Pick<UpdateParameterThresholdsModalProps, 'pointMachineIds'>

function FormDataWrapper({ pointMachineIds, ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: pointMachines, ...pointMachinesData } = usePointMachines(null, pointMachineIds)
  const { data: thresholds, ...thresholdsData } = useThresholds(pointMachineIds)

  return (
    <ContextWrapper data={pointMachines} error={pointMachinesData.error} isLoading={pointMachinesData.isLoading}>
      {(pointMachines) => (
        <ContextWrapper data={true} error={thresholdsData.error} isLoading={thresholdsData.isLoading}>
          <UpdateParameterThresholdsForm pointMachines={pointMachines} thresholds={thresholds ?? []} {...rest} />
        </ContextWrapper>
      )}
    </ContextWrapper>
  )
}

export interface UpdateParameterThresholdsModalProps {
  pointMachineIds: IdType[] | undefined
  visible: boolean
}

export function UpdateParameterThresholdsModal({
  pointMachineIds,
  visible,
}: UpdateParameterThresholdsModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.editParameterThresholdConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.parameterThresholdConfigurationRoute.pathname))}
        title={t(`routes.${routes.editParameterThresholdConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          onAfterSubmit={() => navigate(generatePath(routes.parameterThresholdConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.parameterThresholdConfigurationRoute.pathname))}
          onRemoveItem={(_, pointMachines) => {
            if (pointMachines == null) {
              return
            }

            if (pointMachines.length === 0) {
              return navigate(generatePath(routes.parameterThresholdConfigurationRoute.pathname))
            }

            navigate(
              generatePath(routes.editParameterThresholdConfigurationRoute.pathname, {
                pointMachineId: pointMachines.map((pm) => pm.id).join(','),
              }),
            )
          }}
          pointMachineIds={pointMachineIds}
        />
      </ModalContent>
    </Modal>
  )
}
