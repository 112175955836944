import { UserApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, Interlocking, ServiceError, User } from 'models'
import { useMemo } from 'react'
import { Key, useSWRConfig } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'
import { useAreas } from './use-area'

export const getUsersKey = (): Key => {
  return ['useUsers']
}

export const useUsers = (): SWRResponse<User[]> => {
  return useSWR(getUsersKey(), async () => {
    return UserApiClient.list()
  })
}

export const getUserInterlockingsKey = (ids: Parameters<typeof useUserInterlockings>[0]): Key => {
  if (ids == null) {
    return null
  }

  return ['useUserInterlockings', ...ids]
}

export const useUserInterlockings = (userIds: IdType[] | undefined | null): SWRResponse<Interlocking[][]> => {
  const { data: parents, ...parentsData } = useAreas()

  const { data: rawEntries, ...rawEntriesData } = useSWR(getUserInterlockingsKey(userIds), async () => {
    const responses = await Promise.all(userIds!.map((userId) => UserApiClient.getInterlockings(userId)))
    const allEntries = responses.map((entry) => entry.data)
    return { data: allEntries, dateTime: responses[0].dateTime }
  })

  const data = useMemo<Interlocking[][] | undefined>(() => {
    return rawEntries?.map((arr) =>
      arr.map((entry) => ({ ...entry, _area: parents?.find((parent) => parent.id === entry.area.id) })),
    )
  }, [rawEntries, parents])

  return useMemo(() => {
    return {
      data,
      ...rawEntriesData,
      isLoading: rawEntriesData.isLoading || parentsData.isLoading,
      isValidating: rawEntriesData.isValidating || parentsData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntriesData.mutate(), parentsData.mutate()])
        return undefined
      },
    }
  }, [rawEntriesData, data, parentsData])
}

interface UpdateUserInterlockingOpts {
  interlockings: IdType[]
  nextInterlockings: IdType[]
  prevInterlockings: IdType[]
  userId: IdType
}

export const useUpdateUserInterlockings = (): SWRMutationResponse<
  void,
  ServiceError,
  UpdateUserInterlockingOpts,
  Key
> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useUpdateUserInterlockings', async (_, { arg }) => {
    const { interlockings, nextInterlockings, prevInterlockings, userId } = arg

    const promises = interlockings?.map((interlocking) => {
      const prevInterlocking = prevInterlockings?.find((prevInterlocking) => prevInterlocking === interlocking)
      const nextInterlocking = nextInterlockings?.find((nextInterlocking) => nextInterlocking === interlocking)

      if (prevInterlocking == null && nextInterlocking != null) {
        return UserApiClient.associateInterlockingWithUser(userId, interlocking)
      }

      if (prevInterlocking != null && nextInterlocking == null) {
        return UserApiClient.disassociateInterlockingWithUser(userId, interlocking)
      }

      return undefined
    })

    await Promise.all(promises)

    for (let key of cache.keys()) {
      if (key.includes('useUserInterlockings')) {
        mutate(key)
      }
    }
  })
}
