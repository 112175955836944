import * as t from 'io-ts'
import * as tPromise from 'io-ts-promise'
import type { JSONSchema7Object, JSONSchema7Type } from 'json-schema'
import { DateTime } from 'luxon'
import type { ServiceError } from 'models'
import type { BaseModel, IdType } from 'models/base'
import { User } from 'oidc-client-ts'
import { Methods } from './utils'

export class BaseApiClient {
  protected dataServiceUrl:string = sessionStorage.getItem("REACT_APP_DATA_SERVICE_URL") ?? ''
  protected authClientId:string = sessionStorage.getItem("REACT_APP_AUTH_CLIENT_ID") ?? ''
  protected authDomain:string = sessionStorage.getItem("REACT_APP_AUTH_DOMAIN") ?? ''
  protected baseUrl = [this.dataServiceUrl, 'api', 'v1'].join('/')
  public endpointUrl: string

  constructor(endpointUrl: string) {
    this.endpointUrl = endpointUrl
  }

  private buildUrl(path: string): string {
    if (path.startsWith(this.dataServiceUrl)) {
      return path
    }

    if (path.length === 0) {
      return [this.baseUrl, this.endpointUrl].join('/')
    }

    return [this.baseUrl, this.endpointUrl, path].join('/')
  }

  private async buildFetchOptions(method: Methods, body?: JSONSchema7Type, signal?: AbortSignal): Promise<RequestInit> {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${this.authDomain}:${this.authClientId}`)
    const user = User.fromStorageString(oidcStorage ?? '')
    const token = user.access_token

    if (token == null) {
      throw new Error(`Expected valid token, got ${token}`)
    }

    const options: RequestInit = {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,

        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Charset': 'utf-8',
      },
      method,
      mode: 'cors',
    }

    if ([Methods.PATCH, Methods.POST, Methods.PUT].includes(method)) {
      options.body = typeof body === 'string' ? body : JSON.stringify(body)
    }

    // TODO: Fix react 18 useAbortSignal issue
    options.signal = signal

    return options
  }

  private getQueryString(params?: Record<string, any> | null): string {
    if (params == null) {
      return ''
    }

    if (typeof params !== 'object') {
      throw new TypeError('params must be an object')
    }

    const searchParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
      if (value == null) {
        return
      }

      if (typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string') {
        return searchParams.set(key, String(value))
      }

      if (Array.isArray(value)) {
        if (value.length > 0) {
          return searchParams.set(key, String(value))
        }

        return
      }

      if (DateTime.isDateTime(value)) {
        const dateString = value.toISO()

        return dateString == null ? null : searchParams.set(key, dateString)
      }

      searchParams.set(key, JSON.stringify(value))
    })

    const search = searchParams.toString()

    if (search.length === 0) {
      return ''
    }

    return `?${search}`
  }

  protected async request(
    path: string,
    method: Methods,
    body?: JSONSchema7Type | null,
    params?: Record<string, any> | null,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<JSONSchema7Type>> {
    try {
      const url = this.buildUrl(path)
      const queryString = this.getQueryString(params)
      const options = await this.buildFetchOptions(method, body, signal)

      const res = await fetch(url + queryString, options)

      const resCopy = res.clone()
      const json = await resCopy.json().catch((_) => res.text())

      if (res.status >= 400) {
        if (json == null || (typeof json === 'string' && json.length === 0)) {
          const error: ServiceError = {
            code: res.status,
            message: res.statusText,
            title: res.type,
          }
          throw error
        }

        throw json
      }

      return { dateTime: DateTime.now(), data: json }
    } catch (error) {
      if (error instanceof Error) {
        const serviceError: ServiceError = {
          message: error.message,
          title: error.name,
        }

        throw serviceError
      }

      throw error
    }
  }

  protected async decode<Output, Input>(json: any, typeCodec: t.Decoder<Input, Output>): Promise<Output> {
    try {
      return await tPromise.decode(typeCodec, json)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.info(
          `Error decoding json. Original error:\n${error}\nOriginal json:\n${
            typeof json === 'object' ? JSON.stringify(json) : json
          }`,
        )
      }

      return json
    }
  }
}

export class CrudApiClient<
  Model extends BaseModel,
  Parent extends BaseModel = BaseModel,
  PagedModel = null,
  QueryOpts = null,
> extends BaseApiClient {
  protected codec: t.Type<Model>
  protected pagedCodec?: t.Type<PagedModel>
  protected parent?: CrudApiClient<Parent, Parent, any, any>
  protected parentIdName?: string

  constructor(
    modelPath: string,
    codec: t.Type<Model>,
    parent?: CrudApiClient<Parent, Parent, any, any>,
    parentIdName?: string,
    pagedCodec?: t.Type<PagedModel>,
  ) {
    super(modelPath)
    this.codec = codec
    this.pagedCodec = pagedCodec
    this.parent = parent
    this.parentIdName = parentIdName
  }

  async list(parentId?: IdType, signal?: AbortSignal): Promise<ApiClientResponse<Model[]>> {
    const path =
      this.parent == null ? '' : [this.baseUrl, this.parent.endpointUrl, String(parentId), this.endpointUrl].join('/')
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, t.array(this.codec)) }
  }

  async create(model: Omit<Model, 'id'>, parentId?: IdType, signal?: AbortSignal): Promise<ApiClientResponse<Model>> {
    const body = this.getCreateBody(model)
    const path =
      this.parent == null ? '' : [this.baseUrl, this.parent.endpointUrl, String(parentId), this.endpointUrl].join('/')
    const params = this.parentIdName == null || parentId == null ? undefined : { [this.parentIdName]: parentId }

    const { data, ...rest } = await this.request(path, Methods.POST, body, params, signal)
    return { ...rest, data: await this.decode(data, this.codec) }
  }

  async read(id: IdType, signal?: AbortSignal): Promise<ApiClientResponse<Model>> {
    const { data, ...rest } = await this.request(String(id), Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, this.codec) }
  }

  async update(model: Model, signal?: AbortSignal): Promise<ApiClientResponse<Model>> {
    const body = this.getUpdateBody(model)
    const { data, ...rest } = await this.request(String(model.id), Methods.PUT, body, undefined, signal)
    return { ...rest, data: await this.decode(data, this.codec) }
  }

  async delete(id: IdType, signal?: AbortSignal): Promise<ApiClientResponse<void>> {
    const { data, ...rest } = await this.request(String(id), Methods.DELETE, undefined, undefined, signal)
    return { ...rest, data: undefined }
  }

  async query(
    projectId: IdType,
    opts: Partial<QueryOpts> | undefined | null,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<PagedModel>> {
    if (this.pagedCodec == null) {
      throw new Error('Codec not given')
    }

    const { ProjectApiClient } = await import('./project')

    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), this.endpointUrl].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts, signal)
    return { ...rest, data: await this.decode(data, this.pagedCodec) }
  }

  protected getCreateBody(model: Model | Omit<Model, 'id'>): JSONSchema7Object {
    return model as JSONSchema7Object
  }

  protected getUpdateBody(model: Model | Omit<Model, 'id'>): JSONSchema7Object {
    return model as JSONSchema7Object
  }
}

export interface ApiClientResponse<T> {
  dateTime: DateTime
  data: T
}
