import { ModalInstance } from '@siemens/ix'
import { IxButton, IxModalFooter, IxModalHeader, Modal as IxModal, ModalRef, showModal } from '@siemens/ix-react'
import { usePrevious } from 'hooks'
import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

export interface ModalContentProps {
  cancelButton?: ReactNode
  okButton?: ReactNode
  onCancel?: () => void | Promise<void>
  onOk?: () => void | Promise<void>
  title?: ReactNode
}

export const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
  children,
  onCancel,
  onOk,
  title,
  ...props
}) => {
  const modalRef = useRef<ModalRef>(null)
  const { t } = useTranslation()

  const _onOk = useCallback(async () => {
    await onOk?.()
    modalRef.current?.close('close payload!')
  }, [onOk])

  const _onCancel = useCallback(async () => {
    await onCancel?.()
    modalRef.current?.dismiss('dismiss payload')
  }, [onCancel])

  const cancelButton = useMemo(() => {
    if (props.cancelButton === null) {
      return null
    }

    if (props.cancelButton == null) {
      return (
        <IxButton outline onClick={_onCancel}>
          {t('Cancel')}
        </IxButton>
      )
    }

    return <div onClick={_onCancel}>{cancelButton}</div>
  }, [_onCancel, props.cancelButton, t])

  const okButton = useMemo(() => {
    if (props.okButton === null) {
      return null
    }

    if (props.okButton == null) {
      return <IxButton onClick={_onOk}>{t('Ok')}</IxButton>
    }

    return <div onClick={_onOk}>{okButton}</div>
  }, [_onOk, props.okButton, t])

  return (
    <IxModal ref={modalRef}>
      <IxModalHeader onCloseClick={_onCancel}>{title}</IxModalHeader>

      <S.ModalContent>{children}</S.ModalContent>

      {(cancelButton != null || okButton != null) && (
        <IxModalFooter>
          {cancelButton}

          {okButton}
        </IxModalFooter>
      )}
    </IxModal>
  )
}

export interface ModalProps {
  title: string
  visible: boolean
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ children, title, visible }) => {
  const isInit = useRef(false)
  const prevVisible = usePrevious(visible)
  const ref = useRef<ModalInstance>()

  useEffect(() => {
    if (visible && (!prevVisible || !isInit.current)) {
      requestAnimationFrame(() => {
        showModal({
          animation: false,
          content: children,
          keyboard: false,
          size: '720',
          title,
        }).then((element) => (ref.current = element))
      })
    }

    isInit.current = true
  }, [children, prevVisible, title, visible])

  useEffect(() => {
    if (prevVisible && !visible) {
      setTimeout(() => {
        ref.current?.htmlElement.remove()
      })
    }
  }, [prevVisible, visible])

  return null
}
