import { QueryAlarmEventsFilterOpts } from 'api'
import DataFilter, { Filter } from 'components/DataFilter'
import { usePointMachineSearchFields, useFilterOpts } from 'hooks'
import { JSX, useState } from 'react'
import AlarmsKpiTile from '../AlarmsKpiTile'
import AlarmsTile from '../AlarmsTile'
import HealthKpiTile from '../HealthKpiTile'
import HealthPointsKpiTile from '../HealthPointsKpiTile'

export function Dashboard(): JSX.Element {
  const [filters, setFilters] = useState<Filter[]>([])
  const filterOpts = useFilterOpts<Partial<QueryAlarmEventsFilterOpts>>(filters)

  return (
    <div className="row g-3">
      <div className="col-12 mb-5">
        <DataFilter fields={usePointMachineSearchFields(filters)} onChange={setFilters} value={filters} />
      </div>

      {filterOpts != null && (
        <>
          <div className="col-12 col-md-5 col-lg-4">
            <HealthKpiTile filterOpts={filterOpts} />
          </div>

          <div className="col-12 col-md-7 col-lg-8">
            <HealthPointsKpiTile filterOpts={filterOpts} />
          </div>

          <div className="col-12">
            <AlarmsTile filterOpts={filterOpts} />
          </div>

          <div className="col-12">
            <AlarmsKpiTile filterOpts={filterOpts} />
          </div>
        </>
      )}
    </div>
  )
}
