import * as t from 'io-ts'
import { BaseModelCodec, IdTypeCodec, nullableType, PagedResultCodec } from './base'
import { DataSeriesListCodec } from './data-series'
import { BaseEventCodec } from './event'
import { PointMachineCodec } from './point-machine'

export const ProcessingStateCodec = t.union([
  t.literal('IMPORTED'),
  t.literal('MISSING_REFERENCES'),
  t.literal('JOB_STARTED'),
  t.literal('JOB_FINISHED'),
])

export const BasePointMachineEventCodec = t.intersection([
  BaseEventCodec,
  t.type({
    dataSeries: DataSeriesListCodec,
    fitnessScore: t.number,
    isOutlier: t.boolean,
    meanPower: t.number,
    pointMachineId: IdTypeCodec,
    processingState: ProcessingStateCodec,
    reference: t.boolean,
    turnoutEventId: IdTypeCodec,
    work: t.number,
  }),
])
export const BasePointMachineEventsCodec = t.array(BasePointMachineEventCodec)
export type BasePointMachineEvent = t.TypeOf<typeof BasePointMachineEventCodec>

export const RawPointMachineEventCodec = t.intersection([BaseModelCodec, BasePointMachineEventCodec])
export const RawPointMachineEventsCodec = t.array(RawPointMachineEventCodec)
export type RawPointMachineEvent = t.TypeOf<typeof RawPointMachineEventCodec>

export const PointMachineEventCodec = t.intersection([
  RawPointMachineEventCodec,
  t.type({
    pointMachine: nullableType(PointMachineCodec),
  }),
])
export const PointMachineEventsCodec = t.array(PointMachineEventCodec)
export type PointMachineEvent = t.TypeOf<typeof PointMachineEventCodec>

export const PagedPointMachineEventsCodec = PagedResultCodec(PointMachineEventsCodec)
export type PagedPointMachineEvents = t.TypeOf<typeof PagedPointMachineEventsCodec>
