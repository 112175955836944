import EventKpiTile from 'components/EventKpiTile'
import { useTurnoutStatistics } from 'hooks'
import { getTurnoutState, Turnout, turnoutStateToColorMap } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import PointMachineKpiBar from '../PointMachineKpiBar'

export interface TurnoutKpiBarProps {
  turnout: Turnout
}

export function TurnoutKpiBar({ turnout }: TurnoutKpiBarProps): JSX.Element {
  const { data: statistics, ...statisticsData } = useTurnoutStatistics(turnout.id)
  const { t } = useTranslation()

  return (
    <div className="row g-3">
      <div className="col-12">
        <div style={{ width: '100%' }}>
          <div className="row g-3">
            <div className="col-6 col-lg-3">
              <EventKpiTile
                eventColor={turnoutStateToColorMap[getTurnoutState(turnout)]}
                title={t('Turnout name')}
                value={turnout.name}
              />
            </div>

            <div className="col-6 col-lg-3">
              <EventKpiTile
                isLoading={statisticsData.isLoading}
                precision={2}
                title={t('Turnout health score')}
                unit={statistics?.averageHealthScore == null ? undefined : '/ 1'}
                value={statistics?.averageHealthScore ?? 'n/A'}
              />
            </div>

            <div className="col-6 col-lg-3">
              <EventKpiTile
                isLoading={statisticsData.isLoading}
                title={t('Alarms since installation')}
                value={statistics?.noOfAlarms ?? 'n/A'}
              />
            </div>

            <div className="col-6 col-lg-3">
              <EventKpiTile
                isLoading={statisticsData.isLoading}
                title={t('Average turn time 24h')}
                value={statistics?.averageTurnoverTime ?? 'n/A'}
              />
            </div>
          </div>
        </div>
      </div>

      {statistics?.pointMachines?.map((pm, index) => (
        <PointMachineKpiBar key={pm.id} index={index} pointMachine={pm} />
      ))}
    </div>
  )
}
