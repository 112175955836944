import { Filter, isDateRange } from 'components/DataFilter'
import { useMemo } from 'react'

export function useFilterOpts<T = any>(
  filters: Filter[] | null | undefined,
  initialValue?: Partial<T>,
): Partial<T> | undefined {
  return useMemo(() => {
    if (filters == null) {
      return initialValue
    }

    return filters?.reduce((opts, filter) => {
      if (isDateRange(filter.value)) {
        return { ...opts, ...filter.value }
      }

      return {
        ...opts,
        [filter.path]: Array.isArray(filter.value) && filter.value.length > 0 ? String(filter.value) : filter.value,
      }
    }, (initialValue ?? {}) as T)
  }, [filters, initialValue])
}
