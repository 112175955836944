import * as t from 'io-ts'
import { BaseModelCodec } from './base'

export const RawUserCodec = t.type({
  active: t.boolean,
  email: t.string,
  firstName: t.string,
  idpId: t.string,
  lastName: t.string,
  username: t.string,
})
export const RawUsersCodec = t.array(RawUserCodec)
export type RawUser = t.TypeOf<typeof RawUserCodec>

export const UserCodec = t.intersection([BaseModelCodec, RawUserCodec])
export const UsersCodec = t.array(UserCodec)
export type User = t.TypeOf<typeof UserCodec>
