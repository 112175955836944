import * as t from 'io-ts'
import { BaseModelCodec, NamedTypeCodec, nullableType } from './base'
import { InterlockingCodec } from './interlocking'

export const BaseTechnicalRoomCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    interlocking: t.intersection([BaseModelCodec, NamedTypeCodec]),
  }),
])
export const BaseTechnicalRoomsCodec = t.array(BaseTechnicalRoomCodec)
export type BaseTechnicalRoom = t.TypeOf<typeof BaseTechnicalRoomCodec>

export const RawTechnicalRoomCodec = t.intersection([BaseModelCodec, BaseTechnicalRoomCodec])
export const RawTechnicalRoomsCodec = t.array(RawTechnicalRoomCodec)
export type RawTechnicalRoom = t.TypeOf<typeof RawTechnicalRoomCodec>

export const TechnicalRoomCodec = t.intersection([
  RawTechnicalRoomCodec,
  t.type({
    _interlocking: nullableType(InterlockingCodec),
  }),
])
export const TechnicalRoomsCodec = t.array(TechnicalRoomCodec)
export type TechnicalRoom = t.TypeOf<typeof TechnicalRoomCodec>
