import * as t from 'io-ts'
import { AreaCodec } from './area'
import { BaseModelCodec, NamedTypeCodec, nullableType } from './base'

export const BaseInterlockingCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    area: t.intersection([BaseModelCodec, NamedTypeCodec]),
  }),
  t.partial({
    technicalId: t.string,
  }),
])
export const BaseInterlockingsCodec = t.array(BaseInterlockingCodec)
export type BaseInterlocking = t.TypeOf<typeof BaseInterlockingCodec>

export const RawInterlockingCodec = t.intersection([BaseModelCodec, BaseInterlockingCodec])
export const RawInterlockingsCodec = t.array(RawInterlockingCodec)
export type RawInterlocking = t.TypeOf<typeof RawInterlockingCodec>

export const InterlockingCodec = t.intersection([
  RawInterlockingCodec,
  t.type({
    _area: nullableType(AreaCodec),
  }),
])
export const InterlockingsCodec = t.array(InterlockingCodec)
export type Interlocking = t.TypeOf<typeof InterlockingCodec>
