import { styled } from 'styled-components'

export const EventTableWrapper = styled.div`
  [class*='ag-theme-ix'] {
    --ag-background-color: var(--theme-color-component-1);
    --ag-odd-row-background-color: var(--theme-color-component-1);
    --ag-header-background-color: var(--theme-color-2);

    --ag-border-color: transparent;
    --ag-row-border-color: var(--theme-color-2);
    --ag-row-border-width: 5px;
  }

  .ag-root-wrapper {
    background: transparent;
  }

  .ag-paging-panel {
    background: var(--ag-header-background-color);
  }

  .ag-cell {
    display: flex;
    align-items: center;

    &[col-id='eventColor'] {
      padding: 0;
    }
  }
`
