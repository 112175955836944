import styled from 'styled-components'
import type { AvatarProps } from './Avatar'

export const AvatarWrapper = styled.div<Required<AvatarProps>>`
  background: var(--theme-color-soft-text);
  border-radius: 50%;
  color: var(--theme-color-1);
  display: inline-block;
  font-size: ${({ size }) => size / 32}rem;
  height: ${({ size }) => size}px;
  line-height: ${({ size }) => size}px;
  overflow: hidden;
  position: relative;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: ${({ size }) => size}px;
`

export const AvatarText = styled.div`
  left: 50%;
  position: absolute;
  transform-origin: 0 center;
  transform: translateX(-50%);
`
