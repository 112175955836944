import { JSONSchema7Object } from 'json-schema'
import { RawPagedPointMachines, RawPointMachine, RawTechnicalRoom, Threshold, ThresholdCodec } from 'models'
import { CrudApiClient } from './base'
import { PointMachineApiClient, QueryPointMachineFilterOpts } from './point-machine'

class ThresholdApiClient extends CrudApiClient<Threshold, RawPointMachine> {
  protected getCreateBody({ direction, meanPower, throwingTime, work }: Threshold): JSONSchema7Object {
    return { direction, meanPower, throwingTime, work }
  }

  protected getUpdateBody({ meanPower, throwingTime, work }: Threshold): JSONSchema7Object {
    return { meanPower, throwingTime, work }
  }
}

const instance = new ThresholdApiClient(
  'thresholds',
  ThresholdCodec,
  PointMachineApiClient as CrudApiClient<
    RawPointMachine,
    RawTechnicalRoom,
    RawPagedPointMachines,
    QueryPointMachineFilterOpts
  >,
  'pointMachineId',
)

export { instance as ThresholdApiClient }
