import { LevelNamesApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, LevelNames, ServiceError, TrackLayer } from 'models'
import { useMemo } from 'react'
import { Key, mutate } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'
import { useMainProject } from './use-project'

export const getUseTrackLayersKey = (projectId?: IdType): Key => {
  if (projectId == null) {
    return null
  }

  return ['useTrackLayers', projectId]
}

export const useTrackLayers = (): SWRResponse<TrackLayer[]> => {
  const { data: mainProject } = useMainProject()

  const { data: levelNames, ...levelNamesData } = useSWR(getUseTrackLayersKey(mainProject?.id), () => {
    return LevelNamesApiClient.list(mainProject!.id)
  })

  const data = useMemo<TrackLayer[] | undefined>(() => {
    if (levelNames == null) {
      return undefined
    }

    return [
      { depth: 1, name: levelNames.area, type: 'area' },
      { depth: 2, name: levelNames.interlocking, type: 'interlocking' },
      { depth: 3, name: levelNames.technicalRoom, type: 'technicalRoom' },
    ]
  }, [levelNames])

  return useMemo(() => {
    return {
      ...levelNamesData,
      data,
      mutate: async () => {
        await levelNamesData.mutate()
        return undefined
      },
    }
  }, [data, levelNamesData])
}

interface UpdateTrackLayersOpts {
  layers: TrackLayer[]
  projectId: IdType
}

export const useUpdateTrackLayers = (): SWRMutationResponse<TrackLayer[], ServiceError, UpdateTrackLayersOpts, Key> => {
  return useSWRMutation('useUpdateTrackLayers', async (_, { arg }) => {
    const value: LevelNames = {
      area: arg.layers[0].name,
      interlocking: arg.layers[1].name,
      technicalRoom: arg.layers[2].name,
    }

    const { data: levelNames } = await LevelNamesApiClient.update(arg.projectId, value)

    await mutate(getUseTrackLayersKey(arg.projectId))

    return [
      { depth: 1, name: levelNames.area, type: 'area' },
      { depth: 2, name: levelNames.interlocking, type: 'interlocking' },
      { depth: 3, name: levelNames.technicalRoom, type: 'technicalRoom' },
    ]
  })
}
