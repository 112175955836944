import { AreaApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { Area, IdType } from 'models'
import { useMemo } from 'react'
import { Key } from 'swr'
import { useMainProject, useProject } from './use-project'

export const getAreasKey = (parentId: Parameters<typeof useAreas>[0]): Key => {
  if (parentId == null) {
    return null
  }

  return ['useAreas', parentId]
}

export const useAreas = (parentId?: IdType | null): SWRResponse<Area[]> => {
  const { data: mainProject, ...mainProjectData } = useMainProject()
  const projectId = useMemo(() => parentId ?? mainProject?.id, [mainProject, parentId])

  const { data: rawEntries, ...rawEntriesData } = useSWR(getAreasKey(projectId), async () => {
    return AreaApiClient.list(projectId)
  })

  const data = useMemo<Area[] | undefined>(() => {
    return rawEntries?.map((entry) => ({ ...entry, project: mainProject }))
  }, [rawEntries, mainProject])

  return useMemo(() => {
    return {
      data,
      ...rawEntriesData,
      isLoading: rawEntriesData.isLoading || mainProjectData.isLoading,
      isValidating: rawEntriesData.isValidating || mainProjectData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntriesData.mutate(), mainProjectData.mutate()])
        return undefined
      },
    }
  }, [data, mainProjectData, rawEntriesData])
}

export const getAreaKey = (id: Parameters<typeof useProject>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['useArea', id]
}

export const useArea = (id: IdType | undefined | null): SWRResponse<Area> => {
  const { data: rawEntry, ...rawEntryData } = useSWR(getAreaKey(id), () => {
    return AreaApiClient.read(id!)
  })

  const { data: parent, ...parentData } = useProject(rawEntry?.projectId)

  const data = useMemo<Area | undefined>(() => {
    if (rawEntry == null) {
      return undefined
    }

    return { ...rawEntry, project: parent }
  }, [rawEntry, parent])

  return useMemo(() => {
    return {
      data,
      ...rawEntryData,
      isLoading: rawEntryData.isLoading || parentData.isLoading,
      isValidating: rawEntryData.isValidating || parentData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntryData.mutate(), parentData.mutate()])
        return undefined
      },
    }
  }, [data, rawEntryData, parentData])
}
