import ConcatenatedIncidentChart, {
  ConcatenatedIncidentChartProps,
  DataPoint,
} from 'components/ConcatenatedIncidentChart'
import { TurnoutTrendCurve } from 'models'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

export interface MeanPowerTrendGraphProps extends Omit<ConcatenatedIncidentChartProps, 'height' | 'layers'> {
  height?: number
  maxPower: number
  powerLayer: GenericUnitSpec<Encoding<string>, AnyMark>
  turnoutTrendCurve: TurnoutTrendCurve
}

export const MeanPowerTrendGraph: FC<MeanPowerTrendGraphProps> = ({
  height = 500,
  maxPower,
  powerLayer,
  turnoutTrendCurve,
  ...rest
}) => {
  const { t } = useTranslation()

  const trendData = useMemo((): DataPoint[] => {
    const data = Object.entries(turnoutTrendCurve.trendsByDirection).flatMap(
      ([direction, trendByDirection]): DataPoint[] => {
        if (trendByDirection.mean_power == null) {
          return []
        }

        return [
          {
            start: turnoutTrendCurve.from,
            type: t(`models.event.direction-${direction}`),
            value: trendByDirection.mean_power.start,
          },
          {
            start: turnoutTrendCurve.to,
            type: t(`models.event.direction-${direction}`),
            value: trendByDirection.mean_power.end,
          },
        ]
      },
    )

    return data
  }, [turnoutTrendCurve, t])

  const minPower = useMemo(() => {
    const minPower = Object.values(turnoutTrendCurve?.trendsByDirection ?? {}).reduce((min, trendByDirection) => {
      if (trendByDirection.mean_power == null) {
        return min
      }

      return Math.min(min, ...Object.values(trendByDirection.mean_power))
    }, 0)

    if (minPower < 0) {
      return minPower - 20
    }

    return minPower
  }, [turnoutTrendCurve])

  const trendLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const encoding: Encoding<string> = {
      color: {
        field: 'type',
        scale: { range: ['var(--theme-chart-7)', 'var(--theme-chart-13)'] },
        title: t('Trend'),
      },
      opacity: { condition: { param: 'highlightTrend', value: 0.5 }, value: 0.2 },
      y: {
        field: 'value',
        scale: { domain: [minPower, maxPower] },
        title: `${t('Mean power')} [W]`,
        type: 'quantitative',
      },
    }

    return {
      data: { values: trendData },
      encoding,
      mark: { type: 'line', strokeWidth: 20 },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightTrend',
          select: { type: 'point', fields: ['type'] },
        },
      ],
    }
  }, [t, minPower, maxPower, trendData])

  const layers = useMemo(() => [powerLayer, trendLayer], [powerLayer, trendLayer])

  return <ConcatenatedIncidentChart {...rest} height={height} layers={layers} />
}
