import * as t from 'io-ts'
import { DateTime } from 'luxon'
import { BaseModelCodec } from './base'

export const BaseSampleEntryCodec = t.type({
  active: t.boolean,
  date: t.string,
  description: t.string,
  keywords: t.array(t.string),
  name: t.string,
  temperature: t.number,
  user: t.string,
})
export const BaseSampleEntriesCodec = t.array(BaseSampleEntryCodec)
export type BaseSampleEntry = t.TypeOf<typeof BaseSampleEntryCodec>

export const RawSampleEntryCodec = t.intersection([BaseModelCodec, BaseSampleEntryCodec])
export const RawSampleEntriesCodec = t.array(RawSampleEntryCodec)
export type RawSampleEntry = t.TypeOf<typeof RawSampleEntryCodec>

export interface SampleEntry extends Omit<RawSampleEntry, 'date'> {
  date: DateTime
}

export const transformSampleEntry = (sampleEntry: RawSampleEntry): SampleEntry => {
  const { date, ...rest } = sampleEntry

  return { ...rest, date: DateTime.fromISO(date) }
}
