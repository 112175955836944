import GlobalPageHeader from 'components/GlobalPageHeader'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { generatePath, ParamParseKey, useMatch, useNavigate, useParams } from 'react-router-dom'
import TrackConfig from './components/TrackConfig'

const route = routes.trackConfigurationRoute

export function TrackConfigurationPage(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createTrackLayerItemMatch = useMatch(routes.createTrackLayerItemConfigurationRoute.pathname)
  const editTrackLayerItemMatch = useMatch(routes.editTrackLayerItemConfigurationRoute.pathname)
  const editTrackLayerMatch = useMatch(routes.editTrackLayerConfigurationRoute.pathname)

  const { trackLayer, trackLayerItemId } =
    useParams<ParamParseKey<typeof routes.editTrackLayerItemConfigurationRoute.pathname>>()

  const trackLayerNum = useMemo(() => {
    if (trackLayer == null) {
      return undefined
    }

    const num = parseInt(trackLayer)

    if (Number.isNaN(num)) {
      navigate(generatePath(route.pathname))
    }

    return num
  }, [navigate, trackLayer])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <TrackConfig
          showCreateTrackLayerItem={createTrackLayerItemMatch != null}
          showEditTrackLayer={editTrackLayerMatch != null}
          showEditTrackLayerItem={editTrackLayerItemMatch != null}
          trackLayer={trackLayerNum}
          trackLayerItemId={trackLayerItemId}
        />
      </GlobalPageHeader>
    </>
  )
}
