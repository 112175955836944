import { IxMenu } from '@siemens/ix-react'
import { isDesktopMediaQuery } from 'lib/responsive'
import styled from 'styled-components'

export const MainContent = styled.div`
  margin: 2rem auto 0;
  max-width: 1200px;
  min-height: 280px;
  padding: 24px 0;
  width: 100%;

  @media ${isDesktopMediaQuery} {
    padding: 24px;
  }
`

export const Menu = styled(IxMenu)`
  height: calc(100vh - 2rem);
  top: 2rem;

  a {
    color: var(--theme-color-contrast-bdr);
    text-decoration: none;
  }
`
