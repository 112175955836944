import {
  PagedTurnoutEvents,
  PagedTurnoutEventsCodec,
  RawPagedTurnouts,
  RawTechnicalRoom,
  RawTurnout,
  TurnoutEvent,
  TurnoutEventCodec,
} from 'models'
import { CrudApiClient } from './base'
import { QueryTurnoutFilterOpts, TurnoutApiClient } from './turnout'
import { DateRangeFilterOpts, PaginationFilterOpts, TrackConfigurationFilterOpts } from './utils'

export interface QueryTurnoutEventsFilterOpts
  extends TrackConfigurationFilterOpts,
    PaginationFilterOpts,
    DateRangeFilterOpts {}

const instance = new CrudApiClient<TurnoutEvent, RawTurnout, PagedTurnoutEvents, QueryTurnoutEventsFilterOpts>(
  'turnout-events',
  TurnoutEventCodec,
  TurnoutApiClient as CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts>,
  undefined,
  PagedTurnoutEventsCodec,
)

export { instance as TurnoutEventApiClient }
