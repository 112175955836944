import ContextWrapper from 'components/ContextWrapper'
import Modal, { ModalContent } from 'components/Modal'
import UpdateTrackLayerItemForm, { UpdateTrackLayerItemFormProps } from 'components/UpdateTrackLayerItemForm'
import { useTrackLayerItems } from 'hooks'
import * as routes from 'lib/routes'
import { TrackLayer } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

type FormDataWrapperProps = Pick<UpdateTrackLayerItemFormProps, 'onAfterSubmit' | 'onCancel'> &
  Pick<UpdateTrackLayerItemModalProps, 'layer' | 'layerItemId'>

function FormDataWrapper({ layerItemId, ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: layerItems, ...layerItemsData } = useTrackLayerItems()

  return (
    <ContextWrapper data={layerItems} error={layerItemsData.error} isLoading={layerItemsData.isLoading}>
      {(layerItems) => (
        <UpdateTrackLayerItemForm
          {...rest}
          layerItem={
            rest.layer == null
              ? undefined
              : layerItems.at(rest.layer?.depth - 1)?.find((layerItem) => String(layerItem.id) === layerItemId)
          }
        />
      )}
    </ContextWrapper>
  )
}

export interface UpdateTrackLayerItemModalProps {
  layer: TrackLayer | undefined
  layerItemId?: string
  visible: boolean
}

export function UpdateTrackLayerItemModal({ visible, ...rest }: UpdateTrackLayerItemModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.editTrackLayerItemConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
        title={t(`routes.${routes.editTrackLayerItemConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          {...rest}
          onAfterSubmit={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
