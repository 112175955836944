import { JSONSchema7Object } from 'json-schema'
import {
  DisconnectPeriod,
  DisconnectPeriodsCodec,
  HealthState,
  HealthStatesCodec,
  IdType,
  PagedRawTurnoutsCodec,
  RawPagedTurnouts,
  RawTechnicalRoom,
  RawTurnout,
  RawTurnoutCodec,
  Turnout,
  TurnoutStateStatistics,
  TurnoutStateStatisticsCodec,
  TurnoutStatistics,
  TurnoutStatisticsCodec,
} from 'models'
import { ApiClientResponse, CrudApiClient } from './base'
import { ProjectApiClient } from './project'
import { TechnicalRoomApiClient } from './technical-room'
import { DateRangeFilterOpts, Methods, PaginationFilterOpts, TrackConfigurationFilterOpts } from './utils'

export type QueryTurnoutFilterOpts = TrackConfigurationFilterOpts &
  PaginationFilterOpts &
  Pick<Turnout, 'operationState'> &
  Pick<Turnout, 'configurationStatus'>

export type TurnoutStateStatisticsFilterOpts = TrackConfigurationFilterOpts
export type TurnoutHealthStatesFilterOpts = DateRangeFilterOpts

class TurnoutApiClient extends CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts> {
  protected getCreateBody(model: RawTurnout): JSONSchema7Object {
    const { connectionCscAllowed = false, engineeringId, name, turnoutType = 'UNDEFINED', waypoint } = model
    return { connectionCscAllowed, engineeringId, name, turnoutType, waypoint }
  }

  protected getUpdateBody(model: RawTurnout): JSONSchema7Object {
    return this.getCreateBody(model)
  }

  async stateStatistics(
    projectId: IdType,
    opts: Partial<TurnoutStateStatisticsFilterOpts> | null | undefined,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<TurnoutStateStatistics>> {
    const path = [
      this.baseUrl,
      ProjectApiClient.endpointUrl,
      String(projectId),
      this.endpointUrl,
      'state-statistics',
    ].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts ?? undefined, signal)
    return { ...rest, data: await this.decode(data, TurnoutStateStatisticsCodec) }
  }

  async healthStates(
    turnoutId: IdType,
    opts: Partial<TurnoutHealthStatesFilterOpts>,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<HealthState[]>> {
    const path = [this.baseUrl, this.endpointUrl, String(turnoutId), 'health-states'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts, signal)
    return { ...rest, data: await this.decode(data, HealthStatesCodec) }
  }

  async disconnectPeriod(turnoutId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<DisconnectPeriod[]>> {
    const path = [this.baseUrl, this.endpointUrl, String(turnoutId), 'disconnect-periods'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, DisconnectPeriodsCodec) }
  }

  async turnoutStatistics(turnoutId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<TurnoutStatistics>> {
    const path = [this.baseUrl, this.endpointUrl, String(turnoutId), 'statistics'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, TurnoutStatisticsCodec) }
  }
}

const instance = new TurnoutApiClient(
  'turnouts',
  RawTurnoutCodec,
  TechnicalRoomApiClient as CrudApiClient<RawTechnicalRoom>,
  undefined,
  PagedRawTurnoutsCodec,
)

export { instance as TurnoutApiClient }
