import * as t from 'io-ts'
import { DirectionCodec } from './base'

export const TrendCurveCodec = t.type({
  end: t.number,
  start: t.number,
})
export type TrendCurve = t.TypeOf<typeof TrendCurveCodec>

export const TrendCurveByTypeCodec = t.partial({
  mean_power: TrendCurveCodec,
  throwing_time: TrendCurveCodec,
  work: TrendCurveCodec,
})
export type TrendCurveByType = t.TypeOf<typeof TrendCurveByTypeCodec>

export const TrendCurveByDirectionCodec = t.record(DirectionCodec, TrendCurveByTypeCodec)
export type TrendCurveByDirection = t.TypeOf<typeof TrendCurveByDirectionCodec>

export const TurnoutTrendCurveCodec = t.type({
  from: t.string,
  to: t.string,
  trendsByDirection: TrendCurveByDirectionCodec,
  turnoutEngineeringId: t.string,
  turnoutId: t.number,
  turnoutName: t.string,
})
export type TurnoutTrendCurve = t.TypeOf<typeof TurnoutTrendCurveCodec>
