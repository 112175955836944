import { JSONSchema7Object } from 'json-schema'
import {
  IdType,
  PagedRawPointMachinesCodec,
  RawPagedPointMachines,
  RawPagedTurnouts,
  RawPointMachine,
  RawPointMachineCodec,
  RawTechnicalRoom,
  RawTurnout,
} from 'models'
import { PointMachineChangeLog, PointMachineChangeLogsCodec } from 'models/point-machine-change-log'
import { ApiClientResponse, CrudApiClient } from './base'
import { QueryTurnoutFilterOpts, TurnoutApiClient } from './turnout'
import { Methods, PaginationFilterOpts, ThresholdConfigurationFilterOpts, TrackConfigurationFilterOpts } from './utils'

export type QueryPointMachineFilterOpts = TrackConfigurationFilterOpts &
  PaginationFilterOpts &
  ThresholdConfigurationFilterOpts

class PointMachineApiClient extends CrudApiClient<
  RawPointMachine,
  RawTurnout,
  RawPagedPointMachines,
  QueryPointMachineFilterOpts
> {
  protected getCreateBody(model: RawPointMachine): JSONSchema7Object {
    const { turnoutId, typeId } = model

    return {
      ...this.getUpdateBody(model),
      turnoutId,
      typeId,
    }
  }

  protected getUpdateBody(model: RawPointMachine): JSONSchema7Object {
    const { engineeringId, fixedDirection, name, position, serialNumber } = model

    return { engineeringId, fixedDirection, name, position, serialNumber }
  }

  async getChangeLogs(
    pointMachineId: IdType,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<PointMachineChangeLog[]>> {
    const path = [this.baseUrl, this.endpointUrl, pointMachineId, 'change-logs'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, PointMachineChangeLogsCodec) }
  }
}

const instance = new PointMachineApiClient(
  'point-machines',
  RawPointMachineCodec,
  TurnoutApiClient as CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts>,
  undefined,
  PagedRawPointMachinesCodec,
)

export { instance as PointMachineApiClient }
