import { IxButton, IxValidationTooltip } from '@siemens/ix-react'
import ErrorInfo from 'components/ErrorInfo'
import { useMainProject, useUpdateTrackLayers } from 'hooks'
import { TrackLayer } from 'models'
import { JSX, useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface UpdateTrackLayerFormProps {
  onAfterSubmit?: (layer: TrackLayer | undefined) => void
  onCancel?: () => void
  layer?: TrackLayer
  layers: TrackLayer[]
}

export function UpdateTrackLayerForm({
  onAfterSubmit,
  onCancel,
  layer,
  layers,
}: UpdateTrackLayerFormProps): JSX.Element {
  const { register, handleSubmit, formState, reset } = useForm<TrackLayer>({ values: layer })
  const { trigger: updateTrackLayers, ...updateTrackLayersData } = useUpdateTrackLayers()
  const { data: mainProject } = useMainProject()
  const { t } = useTranslation()

  const onFinish = useCallback<SubmitHandler<TrackLayer>>(
    async (formValues) => {
      if (mainProject == null) {
        return
      }

      const layersToUpdate = layers.slice()
      layersToUpdate.splice(formValues.depth - 1, 1, formValues)

      const newLayers = await updateTrackLayers({
        layers: layersToUpdate,
        projectId: mainProject.id,
      })

      onAfterSubmit?.(newLayers?.[formValues.depth - 1])
    },
    [layers, mainProject, onAfterSubmit, updateTrackLayers],
  )

  useEffect(() => {
    reset()
  }, [reset])

  return (
    <form className="row gx-5 gy-3" onSubmit={handleSubmit(onFinish)}>
      {updateTrackLayersData.error != null && <ErrorInfo error={updateTrackLayersData.error} />}

      <div className="col-12 col-lg-6">
        <IxValidationTooltip className="w-100" message="Name is required">
          <label htmlFor="name" className="form-label">
            Name
          </label>

          <input
            className={`form-control ${formState.errors.name ? 'is-invalid' : ''}`}
            id="name"
            type="text"
            {...register('name', { required: true })}
          />
        </IxValidationTooltip>
      </div>

      <div className="d-flex justify-content-end mt-5">
        <div>
          <IxButton className="me-2" onClick={onCancel} outline>
            {t('Cancel')}
          </IxButton>

          <IxButton
            disabled={layer != null && !formState.isDirty}
            loading={updateTrackLayersData.isMutating}
            type="submit"
          >
            {t(`Save layer`)}
          </IxButton>
        </div>
      </div>
    </form>
  )
}
