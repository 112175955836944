import jwtDecode from 'jwt-decode'
import { JwtClaims } from 'oidc-client-ts'
import { useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

const authClientId:string = sessionStorage.getItem("REACT_APP_AUTH_CLIENT_ID") ?? ''

export interface KeycloakClaims extends JwtClaims {
  resource_access?: Record<typeof authClientId, { roles?: Array<'spm_admin' | 'spm_maintainer'> } | undefined>
}

export const useToken = () => {
  const { user } = useAuth()

  return useMemo(() => {
    if (user?.access_token == null) {
      return undefined
    }

    return jwtDecode<KeycloakClaims>(user?.access_token)
  }, [user])
}

export const useIsAdmin = () => {
  const claims = useToken()
  return claims?.resource_access?.[authClientId]?.roles?.includes('spm_admin') ?? false
}
