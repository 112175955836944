import * as t from 'io-ts'
import { nullableType, PagedResultCodec } from './base'
import { PointMachineTypeCodec } from './point-machine-type'
import { RawPointMachineCodec, RawPointMachinesCodec } from './point-machine.base'
import { TurnoutCodec } from './turnout'

export const PointMachineCodec = t.intersection([
  RawPointMachineCodec,
  t.type({
    turnout: nullableType(TurnoutCodec),
    type: nullableType(PointMachineTypeCodec),
  }),
])
export const PointMachinesCodec = t.array(PointMachineCodec)
export type PointMachine = t.TypeOf<typeof PointMachineCodec>

export const PagedRawPointMachinesCodec = PagedResultCodec(RawPointMachinesCodec)
export type RawPagedPointMachines = t.TypeOf<typeof PagedRawPointMachinesCodec>

export const PagedPointMachinesCodec = PagedResultCodec(PointMachinesCodec)
export type PagedPointMachines = t.TypeOf<typeof PagedPointMachinesCodec>
