import { IxButton, IxIconButton, IxTile, IxTooltip } from '@siemens/ix-react'
import * as routes from 'lib/routes'
import { TrackLayer } from 'models'
import { JSX, PropsWithChildren, RefCallback, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

export interface TrackLayerColumnProps {
  layer: TrackLayer
}

export function TrackLayerColumn({ children, layer }: PropsWithChildren<TrackLayerColumnProps>): JSX.Element {
  const { t } = useTranslation()
  const [contentHeight, setContentHeight] = useState<number>()
  const contentRef = useRef<HTMLDivElement | null>(null)

  const calcHeight = useCallback(() => {
    const { top } = contentRef.current?.getBoundingClientRect() ?? {}

    if (top == null || top === 0) {
      return setContentHeight(undefined)
    }

    setContentHeight(window.innerHeight - top - 100)
  }, [])

  useEffect(calcHeight)

  useEffect(() => {
    window.addEventListener('resize', calcHeight)

    return () => {
      window.removeEventListener('resize', calcHeight)
    }
  }, [calcHeight])

  const onContentRef = useCallback<RefCallback<HTMLDivElement>>(
    (element) => {
      contentRef.current = element
      requestAnimationFrame(() => calcHeight())
    },
    [calcHeight],
  )

  return (
    <IxTile>
      <div className="d-flex align-items-center justify-content-between w-100" slot="header">
        <>
          {t('Layer')} {layer.depth} - {layer.name}
        </>

        <div>
          <Link
            data-tooltip={`track-config-layer-${layer.depth}`}
            to={generatePath(routes.editTrackLayerConfigurationRoute.pathname, { trackLayer: String(layer.depth) })}
          >
            <IxIconButton icon="pen" ghost />
          </Link>

          <IxTooltip for={`[data-tooltip="track-config-layer-${layer.depth}"]`} placement="top">
            {t('Edit {name}', { name: layer.name.toLowerCase() })}
          </IxTooltip>
        </div>
      </div>

      <div ref={onContentRef} style={{ height: contentHeight, overflowX: 'hidden', overflowY: 'auto' }}>
        {children}
      </div>

      <div className="d-flex h-100 align-items-center justify-content-end" slot="footer">
        <Link
          to={generatePath(routes.createTrackLayerItemConfigurationRoute.pathname, { trackLayer: String(layer.depth) })}
        >
          <IxButton ghost>{t('Add {item}', { item: layer.name })}</IxButton>
        </Link>
      </div>
    </IxTile>
  )
}
