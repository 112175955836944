import { IxButton, IxIcon, IxIconButton, IxTooltip } from '@siemens/ix-react'
import { CSSProperties, JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Field, { FieldProps } from './components/Field'
import { Filter, SearchField } from './types'

export interface DataFilterProps {
  className?: string
  fields?: SearchField[]
  onChange?: (value: Filter[]) => void
  onExport?: () => void
  removeGrid?: boolean
  style?: CSSProperties
  value?: Filter[]
}

export function DataFilter({ className, removeGrid = false, style, ...props }: DataFilterProps): JSX.Element {
  const { t } = useTranslation()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [])

  const onChange = useCallback<FieldProps['onChange']>(
    (path, value) => {
      setValue((prev) => {
        const field = props.fields?.find((field) => field.path === path)
        const filtered = prev?.filter((item) => item.path !== path)

        if (field == null || value == null || (typeof value === 'string' && value.length === 0)) {
          props.onChange?.call(null, filtered ?? [])
          return filtered
        }

        const newFilters = [...(filtered ?? []), { ...field, value }]
        props.onChange?.call(null, newFilters)
        return newFilters
      })
    },
    [props.fields, props.onChange],
  )

  const fields = useMemo(() => {
    return (props.fields ?? []).filter((field) => !field.collapsible)
  }, [props.fields])

  const collapsibleFields = useMemo(() => {
    return (props.fields ?? []).filter((field) => field.collapsible)
  }, [props.fields])

  const fieldsJsx = useMemo(() => {
    return (
      <>
        {fields.map((field) => (
          <div
            className={removeGrid ? 'col-auto' : `col-6 col-lg-4 col-xl-${field.type === 'select' ? 2 : 4}`}
            key={field.path}
          >
            <Field field={field} onChange={onChange} value={value?.find((item) => item.path === field.path)} />
          </div>
        ))}

        {collapsibleFields.length > 0 && (
          <div className="col">
            <IxButton ghost={isCollapsed} onClick={toggleCollapse}>
              <div className="d-flex align-items-center">
                <IxIcon name="filter" />
                <span>{t('Filter')}</span>
              </div>
            </IxButton>
          </div>
        )}

        {props.onExport != null && (
          <div className="col d-flex align-items-center justify-content-end h-100">
            <IxIconButton
              data-tooltip="DataFilter-export"
              ghost
              icon="export"
              onClick={props.onExport}
              variant="primary"
            />

            <IxTooltip for='[data-tooltip="DataFilter-export"]'>{t('Export')}</IxTooltip>
          </div>
        )}
      </>
    )
  }, [collapsibleFields.length, fields, isCollapsed, onChange, props.onExport, removeGrid, t, toggleCollapse, value])

  if (removeGrid) {
    return fieldsJsx
  }

  return (
    <div className={className ?? 'w-100'} style={style}>
      <div className="row g-3">
        <div className="col-12">
          <div className="w-100">
            <div className="row g-3">{fieldsJsx}</div>
          </div>
        </div>

        {collapsibleFields.length > 0 && (
          <div className="col-12 m-0">
            <div style={{ width: '100%' }}>
              <div
                className="row g-3 mt-0"
                style={{
                  maxHeight: isCollapsed ? 0 : 100,
                  opacity: isCollapsed ? 0 : 1,
                  pointerEvents: isCollapsed ? 'none' : undefined,
                  touchAction: isCollapsed ? 'none' : undefined,
                  transition: '150ms ease-in all',
                }}
              >
                {collapsibleFields.map((field) => (
                  <div className={`col-6 col-lg-4 col-xl-${field.type === 'select' ? 2 : 4}`} key={field.path}>
                    <Field field={field} onChange={onChange} value={value?.find((item) => item.path === field.path)} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
