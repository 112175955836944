import {
  ChangeEventHandler,
  CSSProperties,
  JSX,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

interface Value {
  defaultValue?: number
  label: ReactNode
  placeholder?: string
  value?: number
}

export interface ConfigValuesProps {
  className?: string
  description?: ReactNode
  name: string
  onBlur?: (name: string, values: Array<number | undefined>) => void
  onChange?: (name: string, values: Array<number | undefined>) => void
  style?: CSSProperties
  title: ReactNode
  values: Value[] | undefined
}

export function ConfigValues({
  className,
  description,
  name,
  style,
  title,
  values,
  ...props
}: ConfigValuesProps): JSX.Element {
  const [state, setState] = useState<Array<string | undefined> | undefined>(() =>
    values?.map((item) => (item?.defaultValue == null ? undefined : String(item.defaultValue))),
  )
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setState((prev) =>
      values?.map((item, index) => {
        const element = item?.value ?? prev?.[index]
        return element == null ? undefined : String(element)
      }),
    )
  }, [values])

  const onChange = useCallback(
    (index: number): ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        setState((prev) => {
          if (prev == null) {
            return prev
          }

          const state = prev.slice(0)
          state[index] = event.target.value.trim().length === 0 ? undefined : event.target.value

          requestAnimationFrame(() => {
            props.onChange?.(
              name,
              state.map((value) => {
                const number = Number(value)
                return Number.isNaN(number) || String(number).length !== value?.length ? undefined : number
              }),
            )
          })

          setIsDirty(true)
          return state
        })
      },
    [name, props],
  )

  const onBlur = useCallback(() => {
    if (state != null && isDirty && props.onBlur != null) {
      props.onBlur(
        name,
        state.map((value) => {
          const number = Number(value)
          return Number.isNaN(number) || String(number).length !== value?.length ? undefined : number
        }),
      )

      setIsDirty(false)
    }
  }, [isDirty, name, props, state])

  const onKeyUp = useCallback<KeyboardEventHandler>(
    (event) => {
      if (event.key === 'Enter') {
        onBlur()
      }
    },
    [onBlur],
  )

  return (
    <div className={`row gy-2 ${className}`} style={style}>
      <div className="col-6">
        <div className="fs-6">{title}</div>
      </div>
      {values?.map(({ label }, index, arr) => (
        <div className={`col-${6 / arr.length} text-muted`} key={`label-${index}`}>
          {label}
        </div>
      ))}

      <div className="col-6">{description}</div>
      {values?.map(({ placeholder }, index, arr) => (
        <div className={`col-${6 / arr.length}`} key={`value-${index}`}>
          <input
            className="form-control"
            onBlur={onBlur}
            onChange={onChange(index)}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            style={{ width: 100 }}
            type="text"
            value={state?.at(index)}
          />
        </div>
      ))}
    </div>
  )
}
