import * as t from 'io-ts'
import { nullableType } from './base'

export const HealthStateCodec = t.type({
  healthState: nullableType(t.number),
  score: nullableType(t.number),
  timestamp: t.string,
})

export const HealthStatesCodec = t.array(HealthStateCodec)
export type HealthState = t.TypeOf<typeof HealthStateCodec>
