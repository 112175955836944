import * as t from 'io-ts'
import { RawPointMachineCodec } from './point-machine.base'
import { BaseTurnoutCodec } from './turnout'

export const PointMachineStatisticsCodec = t.intersection([
  RawPointMachineCodec,
  t.partial({
    fitnessScore: t.number,
  }),
])
export type PointMachineStatistics = t.TypeOf<typeof PointMachineStatisticsCodec>

export const TurnoutStatisticsCodec = t.intersection([
  BaseTurnoutCodec,
  t.type({
    pointMachines: t.array(PointMachineStatisticsCodec),
  }),
  t.partial({
    averageTurnoverTime: t.number,
    averageHealthScore: t.number,
    noOfAlarms: t.number,
  }),
])
export type TurnoutStatistics = t.TypeOf<typeof TurnoutStatisticsCodec>
