import React, { StrictMode } from 'react'
import Main from './Main'
import { IxSpinner } from '@siemens/ix-react'
import ErrorInfo from './components/ErrorInfo'
import { AuthProvider } from 'react-oidc-context'
import { authRedirectUri } from './lib/config'
import AuthContainer from './containers/AuthContainer'
import useSWRImmutable from 'swr/immutable'

const fetcher = (url:string) => fetch(url).then((res) => res.json());

interface Config {
  REACT_APP_AUTH_CLIENT_ID:string
  REACT_APP_AUTH_DOMAIN:string
  REACT_APP_DATA_SERVICE_URL:string
}

function App(): React.JSX.Element {

  /**
   * Load dynamic configuration
   */
  const { data, error, isLoading } = useSWRImmutable<Config>(
    "/config.json",
    fetcher
  );

  if(isLoading) {
    return <div className={'d-flex justify-content-center p-4'}><IxSpinner size={'large'} /></div>
  }

  if (error) {
    return <ErrorInfo error={{
      title: "Configuration error", message: `Unable to load or parse ./config.json`
    }} />
  }

  if(
    data
    && data.REACT_APP_AUTH_CLIENT_ID
    && data.REACT_APP_AUTH_DOMAIN
    && data.REACT_APP_DATA_SERVICE_URL
  ) {
    /**
     * Set variables (formerly defined by 'src/lib/config.ts') as session variables to reduce the impact on the current code
     * (less refactoring as possible...)
     */
    sessionStorage.setItem("REACT_APP_AUTH_CLIENT_ID", data.REACT_APP_AUTH_CLIENT_ID);
    sessionStorage.setItem("REACT_APP_AUTH_DOMAIN", data.REACT_APP_AUTH_DOMAIN);
    sessionStorage.setItem("REACT_APP_DATA_SERVICE_URL", data.REACT_APP_DATA_SERVICE_URL);

    return (
      <AuthProvider
        authority={data.REACT_APP_AUTH_DOMAIN}
        client_id={data.REACT_APP_AUTH_CLIENT_ID}
        onSigninCallback={() => window.history.replaceState({}, document.title, window.location.pathname)}
        redirect_uri={authRedirectUri}
        scope="openid profile email"
      >
        <AuthContainer autoSignIn>
          <StrictMode>
            <Main />
          </StrictMode>
        </AuthContainer>
      </AuthProvider>
    )
  } else {

    const missing:string[] = []
    if (data) {
      if(!data.REACT_APP_AUTH_CLIENT_ID) {
        missing.push('REACT_APP_AUTH_CLIENT_ID is not defined')
      }
      if(!data.REACT_APP_AUTH_DOMAIN) {
        missing.push('REACT_APP_AUTH_DOMAIN is not defined')
      }
      if(!data.REACT_APP_DATA_SERVICE_URL) {
        missing.push('REACT_APP_DATA_SERVICE_URL is not defined')
      }
    } else {
      missing.push('config.json not found')
    }

    return <ErrorInfo error={{
      title: "Configuration missing", message: `Required parameters missing: ${missing.join(", ")}`
    }} />
  }

}

export default App
