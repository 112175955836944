import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  .sc-ix-validation-tooltip-h {
    display: block;
  }

  ix-tile {
    height: auto;
    max-height: none;
    max-width: none;
    padding-bottom: 1rem;
    width: 100%;
  }

  .modal-body {
    & > ix-spinner {
      padding-top: 78px;
    }
  }

  .row > [class*="col-"] {
    display: flex;
  }

  [class*="ag-theme-ix"] .ag-input-wrapper input {
    max-height: none;
    max-width: none;
  }
`
