import { IxTabItem, IxTabs, IxTile } from '@siemens/ix-react'
import { PaginationFilterOpts, QueryAlarmEventsFilterOpts } from 'api'
import AlarmTable, { AlarmTableProps } from 'components/AlarmTable'
import { useQueryAlarms } from 'hooks'
import { DateTime } from 'luxon'
import { Turnout } from 'models'
import { JSX, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const pageSize = 5
const from = DateTime.now().minus({ days: 7 })

export interface AlarmTabsProps {
  filterOpts: Partial<QueryAlarmEventsFilterOpts>
  title?: string | null
  turnout?: Turnout
}

export function AlarmTabs({ filterOpts, title, turnout }: AlarmTabsProps): JSX.Element {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)
  const [activePageNo, setActivePageNo] = useState(0)
  const [recoveredPageNo, setRecoveredPageNo] = useState(0)
  const [sortField, setSortField] = useState<string | undefined>('eventTime')
  const [sortOrder, setSortOrder] = useState<PaginationFilterOpts['sortingDirection'] | undefined>('desc')

  const pagedFilterOpts = useMemo<Partial<QueryAlarmEventsFilterOpts>>(() => {
    return {
      size: pageSize,
      sortBy: sortField,
      sortingDirection: sortOrder,
    }
  }, [sortField, sortOrder])

  const activeFilterOpts = useMemo<Partial<QueryAlarmEventsFilterOpts>>(() => {
    return { ...filterOpts, ...pagedFilterOpts, page: activePageNo, state: 'active' }
  }, [activePageNo, filterOpts, pagedFilterOpts])

  const recoveredFilterOpts = useMemo<Partial<QueryAlarmEventsFilterOpts>>(() => {
    return { ...filterOpts, ...pagedFilterOpts, from, page: recoveredPageNo, state: 'resolved' }
  }, [filterOpts, pagedFilterOpts, recoveredPageNo])

  const { data: activeAlarms, ...activeAlarmsData } = useQueryAlarms(activeFilterOpts)
  const { data: recoveredAlarms, ...recoveredAlarmsData } = useQueryAlarms(recoveredFilterOpts)

  const onSort = useCallback<NonNullable<AlarmTableProps['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortField(sortedColumn?.colId)
    setSortOrder(sortedColumn?.sort ?? undefined)
  }, [])

  return (
    <IxTile>
      <div slot="header">{title}</div>

      <div>
        <IxTabs selected={tab}>
          <IxTabItem onClick={setTab.bind(null, 0)}>
            <div>
              {t('models.alarmEvent.state-active')} {activeAlarms != null && <>({activeAlarms.totalElements})</>}
            </div>
          </IxTabItem>

          <IxTabItem onClick={setTab.bind(null, 1)}>
            <div>
              {t('models.alarmEvent.state-resolved')}{' '}
              {recoveredAlarms != null && (
                <>
                  ({recoveredAlarms.totalElements} - {t('Last 7d')})
                </>
              )}
            </div>
          </IxTabItem>
        </IxTabs>

        {tab === 0 && (
          <AlarmTable
            data={activeAlarms?.content}
            emptyProps={{ header: t('No active alarms') ?? '', icon: 'success' }}
            error={activeAlarmsData.error}
            initialSort={{ eventTime: 'desc' }}
            isLoading={activeAlarmsData.isLoading}
            onSortChanged={onSort}
            paginationPageSize={pageSize}
            paginationProps={{
              count: activeAlarms?.totalPages,
              onPageSelected: (event) => setActivePageNo(event.detail),
              selectedPage: activeAlarms?.number,
            }}
            sortingOrder={sortOrder == null ? undefined : [sortOrder]}
            turnout={turnout}
          />
        )}

        {tab === 1 && (
          <AlarmTable
            data={recoveredAlarms?.content}
            emptyProps={{ header: t('No recovered alarms') ?? '' }}
            error={recoveredAlarmsData.error}
            initialSort={{ eventTime: 'desc' }}
            isLoading={recoveredAlarmsData.isLoading}
            onSortChanged={onSort}
            paginationPageSize={pageSize}
            paginationProps={{
              count: recoveredAlarms?.totalPages,
              onPageSelected: (event) => setRecoveredPageNo(event.detail),
              selectedPage: recoveredAlarms?.number,
            }}
            sortingOrder={sortOrder == null ? undefined : [sortOrder]}
            turnout={turnout}
          />
        )}
      </div>
    </IxTile>
  )
}
