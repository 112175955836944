import { IxButton } from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import ErrorInfo from 'components/ErrorInfo'
import { useInterlockings, useUpdateUserInterlockings } from 'hooks'
import { IdType, Interlocking } from 'models'
import { JSX, useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FormData {
  interlockings?: string[]
}

export interface UpdateUserInterlockingsFormProps {
  onAfterSubmit?: () => void
  onCancel?: () => void
  userId?: IdType
  userInterlockings?: Interlocking[]
}

export function UpdateUserInterlockingsForm({
  onAfterSubmit,
  onCancel,
  userId,
  userInterlockings,
}: UpdateUserInterlockingsFormProps): JSX.Element {
  const { data: interlockings, ...interlockingsData } = useInterlockings()
  const { register, handleSubmit, formState, reset } = useForm<FormData>({
    values: { interlockings: userInterlockings?.map((entry) => String(entry.id)) },
  })
  const { t } = useTranslation()
  const { trigger: updateUserInterlockings, ...updateUserInterlockingsData } = useUpdateUserInterlockings()

  useEffect(() => {
    reset()
  }, [reset])

  const onFinish = useCallback<SubmitHandler<FormData>>(
    async (formValues) => {
      if (userId == null || interlockings == null || userInterlockings == null || formValues.interlockings == null) {
        return
      }

      await updateUserInterlockings({
        interlockings: interlockings.map((entry) => entry.id),
        nextInterlockings: formValues.interlockings.map((id) => Number(id)),
        prevInterlockings: userInterlockings?.map((entry) => entry.id),
        userId,
      })

      onAfterSubmit?.()
    },
    [interlockings, onAfterSubmit, updateUserInterlockings, userId, userInterlockings],
  )

  const onClickCancel = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  return (
    <ContextWrapper data={true} error={interlockingsData.error} isLoading={interlockingsData.isLoading}>
      <form className="row gx-5 gy-3" onSubmit={handleSubmit(onFinish)}>
        {updateUserInterlockingsData.error != null && <ErrorInfo error={updateUserInterlockingsData.error} />}

        {interlockings?.map((interlocking) => (
          <div className="col-12" key={interlocking.id}>
            <input
              {...register('interlockings')}
              id={`UpdateUserInterlockingsForm-interlockings-checkbox-${interlocking.id}`}
              type="checkbox"
              value={interlocking.id}
            />
            <label htmlFor={`UpdateUserInterlockingsForm-interlockings-checkbox-${interlocking.id}`}>
              {interlocking.name}
            </label>
          </div>
        ))}

        <div className="col-12 d-flex justify-content-between">
          <IxButton className="me-2" onClick={onClickCancel} outline>
            {t('Cancel')}
          </IxButton>

          <IxButton disabled={!formState.isDirty} loading={updateUserInterlockingsData.isMutating} type="submit">
            {t('Save user')}
          </IxButton>
        </div>
      </form>
    </ContextWrapper>
  )
}
