import { IxButton } from '@siemens/ix-react'
import { AgGridReact } from 'ag-grid-react'
import { PaginationFilterOpts, QueryTurnoutFilterOpts } from 'api'
import DataFilter, { Filter } from 'components/DataFilter'
import GlobalPageHeader from 'components/GlobalPageHeader'
import TurnoutConfigTable, { TurnoutConfigTableProps } from 'components/TurnoutConfigTable'
import { useFilterOpts, useQueryTurnouts } from 'hooks'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import { JSX, useCallback, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useMatch } from 'react-router-dom'
import CreateTurnoutModal from './components/CreateTurnoutModal'
import UpdateTurnoutModal from './components/UpdateTurnoutModal'
import UpdateTurnoutsModal from './components/UpdateTurnoutsModal'

const pageSize = 15

const route = routes.assetConfigurationRoute

export function AssetConfigurationPage(): JSX.Element {
  const [pageNo, setPageNo] = useState(0)
  const [selectedTurnoutIds, setSelectedTurnoutIds] = useState<string>()
  const [sortField, setSortField] = useState<string | undefined>('name')
  const [sortOrder, setSortOrder] = useState<PaginationFilterOpts['sortingDirection'] | undefined>('asc')
  const { t } = useTranslation()
  const createMatch = useMatch(routes.createTurnoutConfigurationRoute.pathname)
  const editMatch = useMatch(routes.editTurnoutConfigurationRoute.pathname)
  const ref = useRef<AgGridReact | null>(null)
  const [filters, setFilters] = useState<Filter[]>([])

  const filterOpts = useFilterOpts<QueryTurnoutFilterOpts>(filters, {
    page: pageNo,
    size: pageSize,
    sortBy: sortField,
    sortingDirection: sortOrder,
  })
  const { data: queryResponse, ...queryResponseData } = useQueryTurnouts(filterOpts)

  const turnoutIds = useMemo(() => {
    const turnoutIds = editMatch?.params.turnoutId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
    return turnoutIds == null || turnoutIds.length < 2 ? undefined : turnoutIds
  }, [editMatch?.params.turnoutId])

  const turnoutId = useMemo(() => {
    const turnoutIds = editMatch?.params.turnoutId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
    return turnoutIds?.length === 1 ? turnoutIds?.at(0) : undefined
  }, [editMatch?.params.turnoutId])

  const onSelectionChanged = useCallback<NonNullable<TurnoutConfigTableProps['onSelectionChanged']>>(({ api }) => {
    const turnouts = api.getSelectedRows()
    setSelectedTurnoutIds(turnouts.length === 0 ? undefined : turnouts.map((turnout) => turnout.id).join(','))
  }, [])

  const onSort = useCallback<NonNullable<TurnoutConfigTableProps['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortField(sortedColumn?.colId)
    setSortOrder(sortedColumn?.sort ?? undefined)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <>
          <div className="d-flex justify-content-between mb-4">
            <DataFilter
              className="mb-5"
              fields={[
                {
                  label: t('models.turnout.configurationStatus'),
                  options: [
                    {
                      label: t('models.turnout.configurationStatus-unfinished'),
                      value: 'unfinished',
                    },
                    {
                      label: t('models.turnout.configurationStatus-waiting_for_approval'),
                      value: 'waiting_for_approval',
                    },
                    {
                      label: t('models.turnout.configurationStatus-waiting_for_connection'),
                      value: 'waiting_for_connection',
                    },
                    {
                      label: t('models.turnout.configurationStatus-connected'),
                      value: 'connected',
                    },
                  ],
                  path: 'configurationStatus',
                  style: { minWidth: 250 },
                  type: 'select',
                },
              ]}
              onChange={setFilters}
              value={filters}
            />

            <div>
              <Link
                className="me-2"
                to={
                  selectedTurnoutIds == null
                    ? {}
                    : generatePath(routes.editTurnoutConfigurationRoute.pathname, { turnoutId: selectedTurnoutIds })
                }
              >
                <IxButton disabled={selectedTurnoutIds == null} outline>
                  {t('Bulk edit')}
                </IxButton>
              </Link>

              <Link to={generatePath(routes.createTurnoutConfigurationRoute.pathname)}>
                <IxButton>{t(`routes.${routes.createTurnoutConfigurationRoute.name}`)}</IxButton>
              </Link>
            </div>
          </div>

          <TurnoutConfigTable
            data={queryResponse?.content}
            error={queryResponseData.error}
            gridRef={ref}
            isLoading={queryResponseData.isLoading}
            onSelectionChanged={onSelectionChanged}
            onSortChanged={onSort}
            paginationPageSize={pageSize}
            paginationProps={{
              count: queryResponse?.totalPages,
              onPageSelected: (event) => setPageNo(event.detail),
              selectedPage: queryResponse?.number,
            }}
          />

          <CreateTurnoutModal visible={createMatch != null} />
          <UpdateTurnoutModal turnoutId={turnoutId} visible={turnoutId != null} />
          <UpdateTurnoutsModal turnoutIds={turnoutIds} visible={turnoutIds != null} />
        </>
      </GlobalPageHeader>
    </>
  )
}
