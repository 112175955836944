import styled from 'styled-components'

export const VegaContainer = styled.div`
  display: flex;
  width: 100%;

  .vega-embed {
    width: 100%;
  }
`
