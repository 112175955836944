import { PointMachineTypeApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, PointMachineType } from 'models'
import { Key } from 'swr'

export const getPointMachineTypesKey = (): Key => 'usePointMachineTypes'

export const usePointMachineTypes = (): SWRResponse<PointMachineType[]> => {
  return useSWR(getPointMachineTypesKey(), async () => {
    return PointMachineTypeApiClient.list()
  })
}

export const getPointMachineTypeKey = (id: Parameters<typeof usePointMachineType>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['usePointMachineType', id]
}

export const usePointMachineType = (id: IdType | undefined | null): SWRResponse<PointMachineType> => {
  return useSWR(getPointMachineTypeKey(id), () => {
    return PointMachineTypeApiClient.read(id!)
  })
}
