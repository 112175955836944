import { RouteConfig } from '..'

export const configurationRoute: RouteConfig<'/configuration'> = {
  icon: 'configuration',
  name: 'Configuration',
  pathname: '/configuration',
}

export const faqRoute: RouteConfig<'/faq'> = {
  icon: 'about',
  name: 'FAQ',
  pathname: '/faq',
}
