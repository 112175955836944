import { TurnoutTrendCurveApiClient, TurnoutTrendCurveFilterOpts } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, TurnoutTrendCurve } from 'models'
import { Key } from 'swr'

export const getTurnoutTrendCurveKey = (
  parentId: Parameters<typeof useTurnoutTrendCurve>[0],
  opts: Parameters<typeof useTurnoutTrendCurve>[1],
): Key => {
  if (parentId == null || opts == null) {
    return null
  }

  return ['useTurnoutTrendCurve', parentId, ...JSON.stringify(opts)]
}

export const useTurnoutTrendCurve = (
  parentId: IdType | undefined | null,
  opts?: Partial<TurnoutTrendCurveFilterOpts> | null,
): SWRResponse<TurnoutTrendCurve> => {
  return useSWR(getTurnoutTrendCurveKey(parentId, opts), () => {
    return TurnoutTrendCurveApiClient.trends(parentId!, opts)
  })
}
