import RadioButton, { RadioButtonProps } from 'components/RadioButton'
import { JSX, useCallback, useMemo } from 'react'
import { FieldProps } from '../Field'

export function RadioField({ field, ...props }: FieldProps): JSX.Element {
  const value = useMemo(() => {
    return typeof props.value?.value === 'string' ? props.value.value : undefined
  }, [props.value])

  const options = useMemo(() => {
    return field.options?.map((option) => ({ ...option, value: String(option.value) })) ?? []
  }, [field.options])

  const onChange = useCallback<NonNullable<RadioButtonProps['onChange']>>(
    (value) => {
      props.onChange(field.path, String(value))
    },
    [field.path, props],
  )

  return <RadioButton options={options} value={value} onChange={onChange} />
}
