import * as t from 'io-ts'
import { BaseModelCodec, NamedTypeCodec } from './base'

export const BasePointMachineTypeCodec = NamedTypeCodec
export const BasePointMachineTypesCodec = t.array(BasePointMachineTypeCodec)
export type BasePointMachineType = t.TypeOf<typeof BasePointMachineTypeCodec>

export const PointMachineTypeCodec = t.intersection([BaseModelCodec, BasePointMachineTypeCodec])
export const PointMachineTypesCodec = t.array(PointMachineTypeCodec)
export type PointMachineType = t.TypeOf<typeof PointMachineTypeCodec>
