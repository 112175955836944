import Modal, { ModalContent } from 'components/Modal'
import UpdateTrackLayerForm from 'components/UpdateTrackLayerForm'
import * as routes from 'lib/routes'
import { TrackLayer } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

export interface UpdateTrackLayerModalProps {
  layer: TrackLayer | undefined
  layers: TrackLayer[]
  visible: boolean
}

export function UpdateTrackLayerModal({ layer, layers, visible }: UpdateTrackLayerModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.editTrackLayerConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
        title={t(`routes.${routes.editTrackLayerConfigurationRoute.name}`)}
      >
        <UpdateTrackLayerForm
          layer={layer}
          layers={layers}
          onAfterSubmit={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
