import {
  IxButton,
  IxDropdown,
  IxDropdownHeader,
  IxDropdownQuickActions,
  IxIconButton,
  IxSelect,
  IxSelectItem,
  IxValidationTooltip,
} from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import ErrorInfo from 'components/ErrorInfo'
import { useCreatePointMachine, useDeletePointMachine, usePointMachineTypes, useUpdatePointMachine } from 'hooks'
import { RawPointMachine, Turnout } from 'models'
import { JSX, useCallback, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface UpdatePointMachineFormProps {
  onAfterSubmit?: () => void
  onCancel?: () => void
  pointMachine?: RawPointMachine
  turnout: Turnout
}

export function UpdatePointMachineForm({
  onAfterSubmit,
  onCancel,
  pointMachine,
  turnout,
}: UpdatePointMachineFormProps): JSX.Element {
  const { control, register, handleSubmit, formState, reset } = useForm<RawPointMachine>({ values: pointMachine })
  const { t } = useTranslation()
  const { data: pointMachineTypes, ...pointMachineTypesData } = usePointMachineTypes()
  const { trigger: createPointMachine, ...createPointMachineData } = useCreatePointMachine()
  const { trigger: updatePointMachine, ...updatePointMachineData } = useUpdatePointMachine()
  const { trigger: deletePointMachine, ...deletePointMachineData } = useDeletePointMachine()

  useEffect(() => {
    reset()
  }, [reset])

  const onFinish = useCallback<SubmitHandler<RawPointMachine>>(
    async (formValues) => {
      if (pointMachine == null) {
        await createPointMachine({ ...formValues, turnoutId: turnout.id })
      } else {
        await updatePointMachine({ ...pointMachine, ...formValues })
      }

      onAfterSubmit?.()
    },
    [createPointMachine, onAfterSubmit, pointMachine, turnout.id, updatePointMachine],
  )

  const onDelete = useCallback(async () => {
    if (pointMachine == null) {
      return
    }
    await deletePointMachine(pointMachine.id)

    onAfterSubmit?.()
  }, [deletePointMachine, onAfterSubmit, pointMachine])

  const onClickCancel = useCallback(() => {
    if (pointMachine == null) {
      return onCancel?.()
    }

    reset()
  }, [onCancel, pointMachine, reset])

  return (
    <ContextWrapper data={true} error={pointMachineTypesData.error} isLoading={pointMachineTypesData.isLoading}>
      <form className="row gx-5 gy-3" onSubmit={handleSubmit(onFinish)}>
        {createPointMachineData.error != null && <ErrorInfo error={createPointMachineData.error} />}
        {updatePointMachineData.error != null && <ErrorInfo error={updatePointMachineData.error} />}
        {deletePointMachineData.error != null && <ErrorInfo error={deletePointMachineData.error} />}

        <div className="col-12 col-lg-6">
          <IxValidationTooltip className="w-100" message="Required">
            <label htmlFor={`engineeringId-${pointMachine?.id}`} className="form-label">
              CSC ID *
            </label>

            <input
              className={`form-control ${formState.errors.engineeringId ? 'is-invalid' : ''}`}
              id={`engineeringId-${pointMachine?.id}`}
              type="text"
              {...register('engineeringId', { required: true })}
            />
          </IxValidationTooltip>
        </div>

        <div className="col-12 col-lg-6">
          <IxValidationTooltip className="w-100" message="Required">
            <label htmlFor={`name-${pointMachine?.id}`} className="form-label">
              {t('Name')} *
            </label>

            <input
              className={`form-control ${formState.errors.name ? 'is-invalid' : ''}`}
              id={`name-${pointMachine?.id}`}
              type="text"
              {...register('name', { required: true })}
            />
          </IxValidationTooltip>
        </div>

        <div className="col-12 col-lg-6">
          <div>
            <label htmlFor={`fixedDirection-${pointMachine?.id}`} className="form-label">
              {t('models.event.direction')} *
            </label>

            <Controller
              control={control}
              name="fixedDirection"
              rules={{ required: true }}
              render={({ field: { name, onChange, onBlur, ref, value } }) => (
                <IxSelect
                  className={`w-100 ${formState.errors.fixedDirection ? 'is-invalid' : ''}`}
                  hideListHeader
                  i18nPlaceholder=""
                  id={`${name}-${pointMachine?.id}`}
                  onBlur={onBlur}
                  onValueChange={(data) => onChange(data.detail)}
                  ref={ref}
                  value={String(value)}
                >
                  <IxSelectItem label={t('models.event.direction-left') ?? undefined} value="left" />
                  <IxSelectItem label={t('models.event.direction-right') ?? undefined} value="right" />
                </IxSelect>
              )}
            />
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <IxValidationTooltip className="w-100" message="Required">
            <label htmlFor={`position-${pointMachine?.id}`} className="form-label">
              {t('Position')} *
            </label>

            <input
              className={`w-100 ${formState.errors.position ? 'is-invalid' : ''}`}
              id={`position-${pointMachine?.id}`}
              type="number"
              {...register('position', { required: true })}
            />
          </IxValidationTooltip>
        </div>

        <div className="col-12 col-lg-6">
          <IxValidationTooltip className="w-100" message="Serial Required">
            <label htmlFor={`serialNumber-${pointMachine?.id}`} className="form-label">
              {t('Serial number')} *
            </label>

            <input
              className={`form-control ${formState.errors.serialNumber ? 'is-invalid' : ''}`}
              id={`serialNumber-${pointMachine?.id}`}
              type="text"
              {...register('serialNumber')}
            />
          </IxValidationTooltip>
        </div>

        <div className="col-12 col-lg-6">
          <div>
            <label htmlFor={`type-${pointMachine?.id}`} className="form-label">
              {t('Type')} *
            </label>

            <Controller
              control={control}
              name="typeId"
              rules={{ required: true }}
              render={({ field: { name, onChange, onBlur, ref, value } }) => (
                <IxSelect
                  className={`w-100 ${formState.errors.typeId ? 'is-invalid' : ''}`}
                  hideListHeader
                  i18nPlaceholder=""
                  id={`${name}-${pointMachine?.id}`}
                  onBlur={onBlur}
                  onValueChange={(data) => onChange(Number(Array.isArray(data.detail) ? data.detail[0] : data.detail))}
                  ref={ref}
                  value={String(value)}
                >
                  {pointMachineTypes?.map((entry) => (
                    <IxSelectItem key={entry.id} label={entry.name} value={String(entry.id)} />
                  ))}
                </IxSelect>
              )}
            />
          </div>
        </div>

        <div>(*) - {t('Required field')}</div>

        <div className="d-flex justify-content-between mt-5">
          <div>
            {pointMachine != null && (
              <>
                <IxButton
                  id={`UpdatePointMachine_Delete_pointMachine-${pointMachine.id}`}
                  loading={deletePointMachineData.isMutating}
                  variant="secondary"
                  outline
                >
                  {t('Delete point machine')}
                </IxButton>

                <IxDropdown placement="top-start" trigger={`UpdatePointMachine_Delete_pointMachine-${pointMachine.id}`}>
                  <IxDropdownHeader label={t('Are you sure?') ?? undefined} />

                  <IxDropdownQuickActions>
                    <IxIconButton icon="cancel" ghost />
                    <IxIconButton icon="single-check" ghost onClick={onDelete} />
                  </IxDropdownQuickActions>
                </IxDropdown>
              </>
            )}
          </div>

          <div>
            <IxButton className="me-2" onClick={onClickCancel} outline>
              {t('Cancel')}
            </IxButton>

            <IxButton
              disabled={pointMachine != null && !formState.isDirty}
              loading={createPointMachineData.isMutating || updatePointMachineData.isMutating}
              type="submit"
            >
              {pointMachine == null ? t('Create point machine') : t(`Save point machine`)}
            </IxButton>
          </div>
        </div>
      </form>
    </ContextWrapper>
  )
}
