import { JSONSchema7Object } from 'json-schema'
import {
  PagedPointMachineEvents,
  PagedPointMachineEventsCodec,
  PointMachine,
  PointMachineEvent,
  RawPagedPointMachines,
  RawPointMachine,
  RawPointMachineEvent,
  RawPointMachineEventCodec,
  RawTechnicalRoom,
} from 'models'
import { CrudApiClient } from './base'
import { PointMachineApiClient, QueryPointMachineFilterOpts } from './point-machine'
import { DateRangeFilterOpts, PaginationFilterOpts, TrackConfigurationFilterOpts } from './utils'

export type QueryPointMachineEventsFilterOpts = TrackConfigurationFilterOpts &
  DateRangeFilterOpts &
  PaginationFilterOpts &
  Pick<PointMachineEvent, 'isOutlier' | 'reference'> &
  Pick<PointMachine, 'engineeringId'>

class PointMachineEventApiClient extends CrudApiClient<
  RawPointMachineEvent,
  RawPointMachine,
  PagedPointMachineEvents,
  QueryPointMachineEventsFilterOpts
> {
  protected getUpdateBody({ isOutlier, reference }: PointMachineEvent): JSONSchema7Object {
    return { isOutlier, reference }
  }
}

const instance = new PointMachineEventApiClient(
  'point-machine-events',
  RawPointMachineEventCodec,
  PointMachineApiClient as CrudApiClient<
    RawPointMachine,
    RawTechnicalRoom,
    RawPagedPointMachines,
    QueryPointMachineFilterOpts
  >,
  undefined,
  PagedPointMachineEventsCodec,
)

export { instance as PointMachineEventApiClient }
