import * as t from 'io-ts'
import { BaseModelCodec } from './base'

export const ProjectResourceCodec = t.type({
  id: t.number,
  name: t.string,
  defaultLanguageCode: t.string,
})

export const BaseProjectConfigurationChangeLogCodec = t.type({
  changedAt: t.string,
  id: t.number,
  nameOfTheUser: t.string,
  newValue: t.number,
  oldValue: t.number,
  parameterType: t.string,
  project: ProjectResourceCodec,
})
export const BaseProjectConfigurationChangeLogsCodec = t.array(BaseProjectConfigurationChangeLogCodec)
export type BaseProjectConfigurationChangeLog = t.TypeOf<typeof BaseProjectConfigurationChangeLogCodec>

export const ProjectConfigurationChangeLogCodec = t.intersection([
  BaseModelCodec,
  BaseProjectConfigurationChangeLogCodec,
])
export const ProjectConfigurationChangeLogsCodec = t.array(ProjectConfigurationChangeLogCodec)
export type ProjectConfigurationChangeLog = t.TypeOf<typeof ProjectConfigurationChangeLogCodec>
