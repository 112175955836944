import { IxButton } from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import Modal, { ModalContent } from 'components/Modal'
import UpdatePointMachineForm from 'components/UpdatePointMachineForm'
import UpdateTurnoutForm, { UpdateTurnoutFormProps } from 'components/UpdateTurnoutForm'
import { useTurnout } from 'hooks'
import * as routes from 'lib/routes'
import { IdType } from 'models'
import { JSX, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

type FormDataWrapperProps = Pick<UpdateTurnoutFormProps, 'onAfterSubmit' | 'onCancel'> &
  Pick<UpdateTurnoutModalProps, 'turnoutId'>

function FormDataWrapper({ turnoutId, ...rest }: FormDataWrapperProps): JSX.Element {
  const { t } = useTranslation()
  const { data: turnout, ...turnoutData } = useTurnout(turnoutId)
  const [isCreatingPointMachine, setIsCreatingPointMachine] = useState(false)

  return (
    <ContextWrapper data={turnout} error={turnoutData.error} isLoading={turnoutData.isLoading}>
      {(turnout) => (
        <div>
          <UpdateTurnoutForm turnout={turnout} {...rest} />

          <hr />

          {(turnout.pointMachines.length > 0 || isCreatingPointMachine) && (
            <div className="mt-2 h5">{t('Edit point machines')}</div>
          )}

          {turnout.pointMachines.map((pm) => (
            <div key={pm.id}>
              <UpdatePointMachineForm pointMachine={pm} turnout={turnout} />

              <hr />
            </div>
          ))}

          {isCreatingPointMachine ? (
            <UpdatePointMachineForm
              onAfterSubmit={setIsCreatingPointMachine.bind(null, false)}
              onCancel={setIsCreatingPointMachine.bind(null, false)}
              turnout={turnout}
            />
          ) : (
            <IxButton outline onClick={setIsCreatingPointMachine.bind(null, true)}>
              {t('Create point machine')}
            </IxButton>
          )}
        </div>
      )}
    </ContextWrapper>
  )
}

export interface UpdateTurnoutModalProps {
  turnoutId: IdType | undefined
  visible: boolean
}

export function UpdateTurnoutModal({ turnoutId, visible }: UpdateTurnoutModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.editTurnoutConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
        title={t(`routes.${routes.editTurnoutConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          onAfterSubmit={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          turnoutId={turnoutId}
        />
      </ModalContent>
    </Modal>
  )
}
