import { RouteConfig } from '..'
import { configurationRoute } from './lib'

export const assetConfigurationRoute: RouteConfig<
  `${typeof configurationRoute.pathname}/asset`,
  typeof configurationRoute
> = {
  icon: 'hexagon-vertical-bars',
  name: 'Asset configuration',
  parent: configurationRoute,
  pathname: `${configurationRoute.pathname}/asset`,
}

export const createTurnoutConfigurationRoute: RouteConfig<
  `${typeof assetConfigurationRoute.pathname}/create`,
  typeof assetConfigurationRoute
> = {
  name: 'Create turnout configuration',
  parent: assetConfigurationRoute,
  pathname: `${assetConfigurationRoute.pathname}/create`,
}

export const editTurnoutConfigurationRoute: RouteConfig<
  `${typeof assetConfigurationRoute.pathname}/edit/:turnoutId`,
  typeof assetConfigurationRoute
> = {
  name: 'Edit turnout configuration',
  parent: assetConfigurationRoute,
  pathname: `${assetConfigurationRoute.pathname}/edit/:turnoutId`,
}
