import { IxButton, IxSelect, IxSelectItem, IxValidationTooltip } from '@siemens/ix-react'
import ErrorInfo from 'components/ErrorInfo'
import { useMainProject, useTrackLayerItems, useUpdateTrackLayerItem } from 'hooks'
import { TrackLayer, TrackLayerItem } from 'models'
import { JSX, useCallback, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormValues = Pick<TrackLayerItem, 'name' | 'parent' | 'technicalId'>

export interface UpdateTrackLayerItemFormProps {
  layer?: TrackLayer
  layerItem?: TrackLayerItem
  onAfterSubmit?: (layerItem: TrackLayerItem | undefined) => void
  onCancel?: () => void
}

export function UpdateTrackLayerItemForm({
  layer,
  layerItem,
  onAfterSubmit,
  onCancel,
}: UpdateTrackLayerItemFormProps): JSX.Element {
  const { control, register, handleSubmit, formState, reset } = useForm<FormValues>({ values: layerItem })
  const { data: layerItems } = useTrackLayerItems()
  const { data: mainProject } = useMainProject()
  const { t } = useTranslation()
  const { trigger: updateLayerItem, ...updateLayerItemData } = useUpdateTrackLayerItem()

  const onFinish = useCallback<SubmitHandler<FormValues>>(
    async (formValues) => {
      if (layer == null || mainProject == null) {
        return
      }

      const parent = layerItems?.[layer.depth - 2] == null ? mainProject : formValues.parent
      const item = await updateLayerItem({ ...layerItem, ...formValues, parent, type: layer.type })
      onAfterSubmit?.(item)
    },
    [layer, layerItem, layerItems, mainProject, onAfterSubmit, updateLayerItem],
  )

  useEffect(() => {
    reset()
  }, [reset])

  return (
    <form className="row gx-5 gy-3" onSubmit={handleSubmit(onFinish)}>
      {updateLayerItemData.error != null && <ErrorInfo error={updateLayerItemData.error} />}

      <div className="col-12 col-lg-6">
        <IxValidationTooltip className="w-100" message="Name is required">
          <label htmlFor="name" className="form-label">
            {t('Name')} *
          </label>

          <input
            className={`form-control ${formState.errors.name ? 'is-invalid' : ''}`}
            id="name"
            type="text"
            {...register('name', { required: true })}
          />
        </IxValidationTooltip>
      </div>

      {layer?.type === 'interlocking' && (
        <div className="col-12 col-lg-6">
          <IxValidationTooltip className="w-100">
            <label htmlFor="technicalId" className="form-label">
              {t('Technical ID')}
            </label>

            <input
              className={`form-control ${formState.errors.technicalId ? 'is-invalid' : ''}`}
              id="technicalId"
              type="text"
              {...register('technicalId')}
            />
          </IxValidationTooltip>
        </div>
      )}

      {layer != null && layerItems?.[layer.depth - 2] != null && (
        <div className="col-12 col-lg-6">
          <div>
            <label htmlFor="parent" className="form-label">
              {t('Parent')} *
            </label>

            <Controller
              control={control}
              name="parent"
              rules={{ required: true }}
              render={({ field: { name, onChange, onBlur, ref, value } }) => (
                <IxSelect
                  className={`w-100 ${formState.errors.parent ? 'is-invalid' : ''}`}
                  hideListHeader
                  i18nPlaceholder=""
                  id={name}
                  onBlur={onBlur}
                  onValueChange={(data) =>
                    onChange({ id: Number(Array.isArray(data.detail) ? data.detail[0] : data.detail) })
                  }
                  ref={ref}
                  value={value?.id == null ? [] : [String(value.id)]}
                >
                  {layerItems?.[layer.depth - 2].map((item) => (
                    <IxSelectItem key={item.id} label={item.name} value={String(item.id)} />
                  ))}
                </IxSelect>
              )}
            />
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end mt-5">
        <div>
          <IxButton className="me-2" onClick={onCancel} outline>
            {t('Cancel')}
          </IxButton>

          <IxButton
            disabled={layer != null && !formState.isDirty}
            loading={updateLayerItemData.isMutating}
            type="submit"
          >
            {t(`Save layer item`)}
          </IxButton>
        </div>
      </div>
    </form>
  )
}
