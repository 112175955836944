import { IxIconButton, IxTooltip } from '@siemens/ix-react'
import { AgGridReact } from 'ag-grid-react'
import Table, { TableProps } from 'components/Table'
import { DateTime } from 'luxon'
import { PointMachineEvent } from 'models'
import { JSX, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import EventDirectionCell from '../EventDirectionCell'
import OutlierCell from '../OutlierCell'
import ReferenceCell from '../ReferenceCell'
import { useExportEvents } from './hooks'

export interface PointMachineEventTableProps extends TableProps<PointMachineEvent> {
  pointMachineEvents: PointMachineEvent[]
}

export function PointMachineEventTable({ pointMachineEvents, ...rest }: PointMachineEventTableProps): JSX.Element {
  const { i18n, t } = useTranslation()
  const ref = useRef<AgGridReact<PointMachineEvent> | null>(null)
  const { onClickExport } = useExportEvents(ref, pointMachineEvents)

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <div className="fs-6 fw-bold">{t('Power curves displayed')}</div>

        <IxIconButton
          data-tooltip="PointMachineEventTable-export"
          ghost
          icon="export"
          onClick={onClickExport}
          variant="primary"
        />
        <IxTooltip for='[data-tooltip="PointMachineEventTable-export"]'>{t('Export')}</IxTooltip>
      </div>

      <hr />

      <Table
        columnDefs={[
          {
            field: 'pointMachine.engineeringId',
            headerName: 'PM ID',
            sortable: true,
            unSortIcon: true,
            width: 150,
          },
          {
            cellRenderer: EventDirectionCell,
            field: 'direction',
            headerName: t('models.event.direction') ?? undefined,
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) => (data == null ? undefined : t(`models.event.direction-${data.direction}`)),
            width: 130,
          },
          {
            comparator: (_, __, nodeA, nodeB) =>
              nodeA.data == null || nodeB.data == null
                ? 0
                : DateTime.fromISO(nodeA.data.start).diff(DateTime.fromISO(nodeB.data.start), 'milliseconds')
                    .milliseconds,
            field: 'start',
            headerName: t('models.event.startTime') ?? undefined,
            initialSort: 'desc',
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) =>
              data == null
                ? null
                : DateTime.fromISO(data.start)
                    .setLocale(i18n.language)
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
          },
          {
            field: 'throwingTime',
            headerName: `${t('models.event.duration') ?? undefined} (ms)`,
            sortable: true,
            unSortIcon: true,
            width: 150,
          },
          {
            cellRenderer: OutlierCell,
            field: 'isOutlier',
            headerName: t('Mark as "Outlier"') ?? undefined,
            sortable: true,
            unSortIcon: true,
          },
          {
            cellRenderer: ReferenceCell,
            field: 'reference',
            headerName: t('Mark as "Reference"') ?? undefined,
            sortable: true,
            unSortIcon: true,
          },
        ]}
        rowData={pointMachineEvents}
        gridRef={ref}
        selectable
        {...rest}
      />
    </>
  )
}
