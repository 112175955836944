import { AreaApiClient, InterlockingApiClient, TechnicalRoomApiClient } from 'api'
import { SWRResponse } from 'lib/swr'
import { ServiceError, TrackLayerItem } from 'models'
import { useMemo } from 'react'
import { Key, mutate } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'
import { getAreasKey, useAreas } from './use-area'
import { getInterlockingsKey, useInterlockings } from './use-interlocking'
import { getTechnicalRoomsKey, useTechnicalRooms } from './use-technical-room'

export const useTrackLayerItems = (): SWRResponse<TrackLayerItem[][]> => {
  const { data: areas, ...areasData } = useAreas()
  const { data: interlockings, ...interlockingsData } = useInterlockings()
  const { data: technicalRooms, ...technicalRoomsData } = useTechnicalRooms()

  const data = useMemo(() => {
    const data = [
      (areas ?? [])?.map(
        (layerItem): TrackLayerItem => ({
          ...layerItem,
          parent: layerItem.project,
          type: 'area',
        }),
      ),
      (interlockings ?? [])?.map(
        (layerItem): TrackLayerItem => ({
          ...layerItem,
          parent: layerItem._area,
          type: 'interlocking',
        }),
      ),
      (technicalRooms ?? [])?.map(
        (layerItem): TrackLayerItem => ({
          ...layerItem,
          parent: layerItem._interlocking,
          type: 'technicalRoom',
        }),
      ),
    ]

    data.forEach((items) => items.sort((a, b) => a.name.localeCompare(b.name)))

    return data
  }, [interlockings, areas, technicalRooms])

  return useMemo(() => {
    return {
      data,
      dateTime: areasData.dateTime,
      error: areasData.error ?? interlockingsData.error ?? technicalRoomsData.error,
      isLoading: areasData.isLoading || interlockingsData.isLoading || technicalRoomsData.isLoading,
      isValidating: areasData.isValidating || interlockingsData.isValidating || technicalRoomsData.isValidating,
      mutate: async () => {
        await Promise.all([areasData.mutate(), interlockingsData.mutate(), technicalRoomsData.mutate()])
        return undefined
      },
    }
  }, [data, interlockingsData, areasData, technicalRoomsData])
}

type Arg = Omit<TrackLayerItem, 'id'> & Partial<Pick<TrackLayerItem, 'id'>>

export const useUpdateTrackLayerItem = (): SWRMutationResponse<TrackLayerItem, ServiceError, Arg, Key> => {
  return useSWRMutation('useUpdateTrackLayerItem', async (_, { arg }) => {
    let updatedItem: TrackLayerItem

    if (arg.parent == null) {
      throw new Error('Parent not defined')
    }

    switch (arg.type) {
      case 'area':
        if (arg.id == null) {
          const { data: area } = await AreaApiClient.create({ name: arg.name, projectId: arg.parent.id }, arg.parent.id)
          updatedItem = { ...arg, ...area }
        } else {
          const { data: area } = await AreaApiClient.update({
            id: arg.id,
            name: arg.name,
            projectId: arg.parent.id,
          })
          updatedItem = { ...arg, ...area }
        }

        await mutate(getAreasKey(arg.parent.id))

        break

      case 'interlocking':
        if (arg.id == null) {
          const { data: area } = await InterlockingApiClient.create(
            { area: arg.parent, name: arg.name, technicalId: arg.technicalId },
            arg.parent.id,
          )
          updatedItem = { ...arg, ...area }
        } else {
          const { data: interlocking } = await InterlockingApiClient.update({
            area: arg.parent,
            id: arg.id,
            name: arg.name,
            technicalId: arg.technicalId,
          })
          updatedItem = { ...arg, ...interlocking }
        }

        await mutate(getInterlockingsKey(arg.parent.id))
        await mutate(getInterlockingsKey(null, []))

        break

      default:
        if (arg.id == null) {
          const { data: area } = await TechnicalRoomApiClient.create(
            { interlocking: arg.parent, name: arg.name },
            arg.parent.id,
          )
          updatedItem = { ...arg, ...area }
        } else {
          const { data: technicalRoom } = await TechnicalRoomApiClient.update({
            id: arg.id,
            interlocking: arg.parent,
            name: arg.name,
          })
          updatedItem = { ...arg, ...technicalRoom }
        }

        await mutate(getTechnicalRoomsKey(arg.parent.id))
        await mutate(getTechnicalRoomsKey(null, []))

        break
    }

    return updatedItem
  })
}
