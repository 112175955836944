import { IxTile } from '@siemens/ix-react'
import { QueryAlarmEventsFilterOpts } from 'api'
import ContextWrapper from 'components/ContextWrapper'
import RadioButton, { RadioButtonProps } from 'components/RadioButton'
import { useQueryAlarms } from 'hooks'
import { DateTime } from 'luxon'
import { JSX, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlarmsChart from '../AlarmsChart'

const options = {
  '24h': DateTime.now().minus({ hours: 24 }),
  '7d': DateTime.now().minus({ days: 7 }),
}

export interface AlarmsKpiTileProps {
  filterOpts: Partial<QueryAlarmEventsFilterOpts>
}

export function AlarmsKpiTile({ filterOpts }: AlarmsKpiTileProps): JSX.Element {
  const [alarmsFilter, setAlarmsFilter] = useState<keyof typeof options>('7d')

  const { data: failureAlarms, ...failureAlarmsData } = useQueryAlarms({
    ...filterOpts,
    from: options[alarmsFilter],
    size: 500,
    severity: 'failure',
  })

  const { data: warningAlarms, ...warningAlarmsData } = useQueryAlarms({
    ...filterOpts,
    from: options[alarmsFilter],
    size: 500,
    severity: 'warning',
  })

  const alarms = useMemo(() => {
    return [...(failureAlarms?.content ?? []), ...(warningAlarms?.content ?? [])]
  }, [failureAlarms, warningAlarms])

  const { t } = useTranslation()

  const onChangeAlarmsFilter = useCallback<NonNullable<RadioButtonProps['onChange']>>((value) => {
    setAlarmsFilter(value as keyof typeof options)
  }, [])

  return (
    <IxTile>
      <div slot="header">{t('Number of triggered alarms')}</div>

      <div className="d-flex flex-grow-1 justify-content-end">
        <RadioButton
          onChange={onChangeAlarmsFilter}
          options={Object.keys(options).map((value) => ({ value }))}
          value={alarmsFilter}
        />
      </div>

      <div>
        <ContextWrapper
          data={true}
          error={failureAlarmsData.error ?? warningAlarmsData.error}
          isLoading={failureAlarmsData.isLoading || warningAlarmsData.isLoading}
        >
          <AlarmsChart alarms={alarms} fromLabel={alarmsFilter} from={options[alarmsFilter]} />
        </ContextWrapper>
      </div>
    </IxTile>
  )
}
