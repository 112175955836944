import { ColDef } from 'ag-grid-community'
import Table, { TableProps } from 'components/Table'
import { BaseModel } from 'models'
import { JSX, useMemo } from 'react'
import EventCell from './components/EventCell'
import * as S from './styles'

export interface EventData {
  eventColor: string
}

export function EventTable<T extends EventData & BaseModel>(props: TableProps<T>): JSX.Element {
  const columnDefs = useMemo<ColDef<T>[] | undefined>(() => {
    if (props.columnDefs == null) {
      return undefined
    }

    return [
      {
        cellRenderer: EventCell,
        field: 'eventColor' as any,
        headerName: '',
        width: 0,
      },

      ...props.columnDefs,
    ]
  }, [props.columnDefs])

  return (
    <S.EventTableWrapper>
      <Table
        {...props}
        columnDefs={columnDefs}
        rowHeight={58}
        selectable={props.selectable === true ? { columnIndex: 1 } : props.selectable}
      />
    </S.EventTableWrapper>
  )
}
