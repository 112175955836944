import { RouteConfig } from '..'
import { configurationRoute } from './lib'

export const trackConfigurationRoute: RouteConfig<
  `${typeof configurationRoute.pathname}/track`,
  typeof configurationRoute
> = {
  icon: 'hierarchy',
  name: 'Track configuration',
  parent: configurationRoute,
  pathname: `${configurationRoute.pathname}/track`,
}

export const editTrackLayerConfigurationRoute: RouteConfig<
  `${typeof trackConfigurationRoute.pathname}/layers/:trackLayer/edit`,
  typeof trackConfigurationRoute
> = {
  name: 'Edit layer configuration',
  parent: trackConfigurationRoute,
  pathname: `${trackConfigurationRoute.pathname}/layers/:trackLayer/edit`,
}

export const trackLayerItemsConfigurationRoute: RouteConfig<
  `${typeof trackConfigurationRoute.pathname}/layers/:trackLayer/items`,
  typeof trackConfigurationRoute
> = {
  name: 'Track layer items configuration',
  parent: trackConfigurationRoute,
  pathname: `${trackConfigurationRoute.pathname}/layers/:trackLayer/items`,
}

export const createTrackLayerItemConfigurationRoute: RouteConfig<
  `${typeof trackLayerItemsConfigurationRoute.pathname}/create`,
  typeof trackLayerItemsConfigurationRoute
> = {
  name: 'Create layer item configuration',
  parent: trackLayerItemsConfigurationRoute,
  pathname: `${trackLayerItemsConfigurationRoute.pathname}/create`,
}

export const editTrackLayerItemConfigurationRoute: RouteConfig<
  `${typeof trackLayerItemsConfigurationRoute.pathname}/:trackLayerItemId/edit`,
  typeof trackLayerItemsConfigurationRoute
> = {
  name: 'Edit layer item configuration',
  parent: trackLayerItemsConfigurationRoute,
  pathname: `${trackLayerItemsConfigurationRoute.pathname}/:trackLayerItemId/edit`,
}
