import { IxTile } from '@siemens/ix-react'
import { css, styled } from 'styled-components'
import type { EventKpiTileProps } from './EventKpiTile'

export const Tile = styled(IxTile)<Pick<EventKpiTileProps, 'eventColor' | 'size' | 'type'>>`
  &&& {
    padding-bottom: ${({ size }) => (size === 'small' ? 12 : 24)}px;
    position: relative;

    ${({ type }) =>
      type === 'inner'
        ? css`
            background-color: var(--theme-color-4);
          `
        : ''}

    .tile-header {
      font-size: 0.88rem;
      font-weight: normal;
      margin-bottom: 8px;

      ${({ size }) =>
        size === 'small'
          ? css`
              height: 1.5rem;
            `
          : ''}
    }
  }

  ${({ eventColor }) =>
    eventColor == null
      ? ''
      : css`
          &:before {
            background: var(--theme-${eventColor});
            border-bottom-left-radius: var(--theme-tile--border-radius);
            border-top-left-radius: var(--theme-tile--border-radius);
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            top: 0;
            width: 0.5rem;
          }
        `}
`
