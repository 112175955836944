import { JSONSchema7Object } from 'json-schema'
import { RawInterlocking, RawTechnicalRoom, RawTechnicalRoomCodec } from 'models'
import { CrudApiClient } from './base'
import { InterlockingApiClient } from './interlocking'

class TechnicalRoomApiClient extends CrudApiClient<RawTechnicalRoom, RawInterlocking> {
  protected getCreateBody(model: RawTechnicalRoom): JSONSchema7Object {
    return { name: model.name }
  }

  protected getUpdateBody(model: RawTechnicalRoom): JSONSchema7Object {
    return this.getCreateBody(model)
  }
}

const instance = new TechnicalRoomApiClient(
  'technical-rooms',
  RawTechnicalRoomCodec,
  InterlockingApiClient as CrudApiClient<RawInterlocking>,
  'interlockingId',
)

export { instance as TechnicalRoomApiClient }
