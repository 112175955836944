import { JSX } from 'react'
import { Filter, SearchField } from '../../types'
import DateField from '../DateField'
import DateRangeField from '../DateRangeField'
import RadioField from '../RadioField'
import SelectField from '../SelectField'
import TextField from '../TextField'
import ToggleField from '../ToggleField'

export interface FieldProps {
  field: SearchField
  onChange: (path: string, value: Filter['value'] | null | undefined) => void
  value: Filter | undefined
}

export function Field(props: FieldProps): JSX.Element | null {
  switch (props.field.type) {
    case 'date':
      return <DateField {...props} />

    case 'date_range':
      return <DateRangeField {...props} />

    case 'radio':
      return <RadioField {...props} />

    case 'select':
      return <SelectField {...props} />

    case 'text':
      return <TextField {...props} />

    case 'toggle':
      return <ToggleField {...props} />

    default:
      return null
  }
}
