import { useMemo } from 'react'

export function useParseNumber(value: unknown): number | undefined {
  return useMemo(() => {
    const numValue = Number(value)
    if (Number.isNaN(numValue)) {
      return undefined
    }
    return numValue
  }, [value])
}
