import Modal, { ModalContent } from 'components/Modal'
import UpdateTrackLayerItemForm from 'components/UpdateTrackLayerItemForm'
import * as routes from 'lib/routes'
import { TrackLayer, TrackLayerItem } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

export interface CreateTrackLayerItemModalProps {
  layer: TrackLayer | undefined
  layerItem: TrackLayerItem | undefined
  visible: boolean
}

export function CreateTrackLayerItemModal({ visible, ...rest }: CreateTrackLayerItemModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.createTrackLayerItemConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
        title={t(`routes.${routes.createTrackLayerItemConfigurationRoute.name}`)}
      >
        <UpdateTrackLayerItemForm
          {...rest}
          onAfterSubmit={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.trackConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
