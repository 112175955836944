import { QueryAlarmEventsFilterOpts } from 'api'
import VegaContainer from 'components/VegaContainer'
import { useQueryAlarms } from 'hooks'
import { FC, RefCallback, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VisualizationSpec } from 'react-vega'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec, GenericVConcatSpec, NonNormalizedSpec } from 'vega-lite/build/src/spec'
import { useEncoding } from './hooks/use-encoding'

export interface DataPoint {
  start: string
  type: string | null | undefined
  type2?: string | null
  value: string | number | null | undefined
  tooltip?: Record<string, string>
}

export interface ConcatenatedIncidentChartProps {
  height: number
  layers: GenericUnitSpec<Encoding<string>, AnyMark>[]
  filterOpts: Partial<QueryAlarmEventsFilterOpts> | undefined
}

export const ConcatenatedIncidentChart: FC<ConcatenatedIncidentChartProps> = ({ height, layers, filterOpts }) => {
  const [baseEncoding, encoding] = useEncoding(filterOpts)
  const [width, setWidth] = useState<number>()
  const { t } = useTranslation()

  const alarmsQuery = useMemo(() => {
    if (filterOpts == null) {
      return undefined
    }

    const { sortBy, ...rest } = filterOpts
    return rest
  }, [filterOpts])
  const { data: pagedAlarms } = useQueryAlarms(alarmsQuery)

  const needsEncoding = useMemo(() => {
    return layers.filter((layer) => layer.encoding?.x == null).length === layers.length
  }, [layers])

  const alarmsData = useMemo((): DataPoint[] => {
    const data = (pagedAlarms?.content ?? []).map((alarm): DataPoint => {
      const data: DataPoint = {
        start: alarm.eventTime,
        type: t('routes.Alarms'),
        value: t('routes.Alarms'),
        tooltip: {
          alarmType: t(`models.alarmEvent.type-${alarm.alarmType}`),
          severity: t(`models.alarmEvent.severity-${alarm.severity}`),
          start: alarm.eventTime,
        },
      }

      return data
    })

    return data
  }, [pagedAlarms, t])

  const alarmsLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    return {
      data: { values: alarmsData },
      mark: {
        fill: 'var(--theme-color-component-6)',
        size: 200,
        strokeWidth: 0,
        type: 'point',
      },
      encoding: {
        shape: {
          // legend: null,
          field: 'type',
          scale: {
            range: [
              'M 0.58333327,0.02380913 V -0.1666717 c 0,-0.36820999 -0.26114416,-0.66666164 -0.5833233100004,-0.66666164 -4.9999998e-6,0 -4.9999998e-6,0 -9.9999996e-6,0 -4.9999998e-6,0 -4.9999998e-6,0 -1e-5,0 -0.32217915,0 -0.58332331,0.29845165 -0.58332331,0.66666164 v 0.19048083 l -0.16666666,0.47619081 h 0.41666665 c 0,0.18409249 0.14923583,0.33333332 0.33333332,0.33333332 0.18409749,0 0.33333332,-0.14924083 0.33333332,-0.33333332 H 0.74999993 Z M -4e-8,0.6666666 c -0.09189833,0 -0.16666666,-0.0747683 -0.16666666,-0.16666666 h 0.33333332 c 0,0.0918983 -0.07476833,0.16666666 -0.16666666,0.16666666 z m -0.51508581,-0.33333332 0.0890608,-0.25446582 0.009358,-0.02673834 V 0.02380913 -0.1666717 c 0,-0.27570082 0.18690999,-0.49999498 0.41665665,-0.49999498 5.0000002e-6,0 5.0000002e-6,0 1e-5,0 4.9999998e-6,0 4.9999998e-6,0 9.9999996e-6,0 C 0.22975662,-0.66666668 0.41666661,-0.44237252 0.41666661,-0.1666717 v 0.19048083 0.02831999 l 0.009358,0.02673834 0.0890608,0.25446582 z',
            ],
          },
          title: ' ',
        },
        tooltip: [
          { field: 'tooltip.alarmType', title: t('models.alarmEvent.type') },
          { field: 'tooltip.severity', title: t('models.alarmEvent.severity') },
          { field: 'tooltip.start', format: '%c', title: t('Timestamp'), type: 'temporal' },
        ],
        y: {
          axis: null,
          field: 'value',
          type: 'nominal',
        },
      },
    }
  }, [alarmsData, t])

  const propsView = useMemo((): GenericVConcatSpec<NonNormalizedSpec>['vconcat'][0] => {
    const view: GenericVConcatSpec<NonNormalizedSpec>['vconcat'][0] = {
      height,
      layer: layers,
      width,
    }

    if (needsEncoding) {
      view.encoding = baseEncoding
      view.resolve = { scale: { color: 'independent', y: 'independent' } }
    }

    return view
  }, [baseEncoding, needsEncoding, height, layers, width])

  const incidentView = useMemo((): GenericVConcatSpec<NonNormalizedSpec>['vconcat'][0] => {
    return {
      encoding,
      height: 50,
      layer: [alarmsLayer],
      width,
    }
  }, [encoding, alarmsLayer, width])

  const spec = useMemo((): VisualizationSpec => {
    return {
      vconcat: [propsView, incidentView],
      resolve: { scale: { color: 'independent', shape: 'independent', y: 'independent' } },
      spacing: 0,
    }
  }, [incidentView, propsView])

  const onRef = useCallback<RefCallback<HTMLDivElement>>((ref) => {
    if (ref == null) {
      return setWidth(undefined)
    }

    setWidth(ref.clientWidth - 250)
  }, [])

  return <VegaContainer fit height={height} legend="right" ref={onRef} spec={spec} width={width} />
}
