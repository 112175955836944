import * as t from 'io-ts'
import { BaseModelCodec, NamedTypeCodec, nullableType, PagedResultCodec } from './base'
import { RawPointMachinesCodec } from './point-machine.base'
import { TechnicalRoomCodec } from './technical-room'

export const TurnoutTypeCodec = t.union([t.literal('UNDEFINED'), t.literal('3P4W')])
export type TurnoutType = t.TypeOf<typeof TurnoutTypeCodec>

export const OperationStateCodec = t.union([t.literal('green'), t.literal('yellow'), t.literal('red')])
export type OperationState = t.TypeOf<typeof OperationStateCodec>

export const ConnectionStateCodec = t.union([t.literal('connected'), t.literal('disconnected')])
export type ConnectionState = t.TypeOf<typeof ConnectionStateCodec>

export const ConfigurationStatusCodec = t.union([
  t.literal('unfinished'),
  t.literal('waiting_for_approval'),
  t.literal('waiting_for_connection'),
  t.literal('connected'),
])
export type ConfigurationStatus = t.TypeOf<typeof ConfigurationStatusCodec>

export const BaseTurnoutCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    configurationStatus: ConfigurationStatusCodec,
    connectionCscAllowed: t.boolean,
    connectionState: ConnectionStateCodec,
    engineeringId: t.string,
    operationState: OperationStateCodec,
    technicalRoom: t.intersection([BaseModelCodec, NamedTypeCodec]),
    turnoutType: TurnoutTypeCodec,
    waypoint: t.string,
  }),
])
export const BaseTurnoutsCodec = t.array(BaseTurnoutCodec)
export type BaseTurnout = t.TypeOf<typeof BaseTurnoutCodec>

export const BaseTurnoutWithPmsCodec = t.intersection([
  BaseTurnoutCodec,
  t.type({
    pointMachines: RawPointMachinesCodec,
  }),
])
export type BaseTurnoutWithPms = t.TypeOf<typeof BaseTurnoutWithPmsCodec>

export const RawTurnoutCodec = t.intersection([BaseModelCodec, BaseTurnoutWithPmsCodec])
export const RawTurnoutsCodec = t.array(RawTurnoutCodec)
export type RawTurnout = t.TypeOf<typeof RawTurnoutCodec>

export const TurnoutCodec = t.intersection([
  RawTurnoutCodec,
  t.type({
    _technicalRoom: nullableType(TechnicalRoomCodec),
  }),
])
export const TurnoutsCodec = t.array(TurnoutCodec)
export type Turnout = t.TypeOf<typeof TurnoutCodec>

export const PagedRawTurnoutsCodec = PagedResultCodec(RawTurnoutsCodec)
export type RawPagedTurnouts = t.TypeOf<typeof PagedRawTurnoutsCodec>

export const PagedTurnoutsCodec = PagedResultCodec(TurnoutsCodec)
export type PagedTurnouts = t.TypeOf<typeof PagedTurnoutsCodec>

type State = 'alert' | 'warning' | 'disconnected' | 'healthy'

export const getTurnoutState = (data: Turnout): State => {
  if (data.connectionState === 'disconnected') {
    return 'disconnected'
  }

  if (data.operationState === 'red') {
    return 'alert'
  }

  if (data.operationState === 'yellow') {
    return 'warning'
  }

  return 'healthy'
}

export const turnoutStateToColorMap: Record<State, string> = {
  alert: 'color-alarm',
  disconnected: 'color-6',
  healthy: 'color-success--active',
  warning: 'color-warning',
}
