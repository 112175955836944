import type { ICellRendererParams } from 'ag-grid-community'
import type { BaseModel } from 'models'
import type { JSX } from 'react'
import type { EventData } from '../../EventTable'
import * as S from './styles'

export function EventCell<T extends EventData & BaseModel>({ data }: ICellRendererParams<T>): JSX.Element | null {
  if (data == null) {
    return null
  }

  return <S.EventColumn color={data.eventColor} />
}
