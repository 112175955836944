import { AlarmEvent, AlarmEventType, BaseModel, BaseModelCodec, PagedAlarmEvents, PagedAlarmEventsCodec } from 'models'
import { CrudApiClient } from './base'
import { DateRangeFilterOpts, PaginationFilterOpts, TrackConfigurationFilterOpts } from './utils'

export interface QueryAlarmEventsFilterOpts
  extends TrackConfigurationFilterOpts,
    PaginationFilterOpts,
    DateRangeFilterOpts,
    Pick<AlarmEvent, 'severity' | 'state'> {
  type: AlarmEventType
}

const instance = new CrudApiClient<BaseModel, BaseModel, PagedAlarmEvents, QueryAlarmEventsFilterOpts>(
  'alarm-events',
  BaseModelCodec,
  undefined,
  undefined,
  PagedAlarmEventsCodec,
)

export { instance as AlarmEventApiClient }
