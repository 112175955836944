import { IxContentHeader, IxSpinner, showToast } from '@siemens/ix-react'
import ConfigValues, { ConfigValuesProps } from 'components/ConfigValues'
import ContextWrapper from 'components/ContextWrapper'
import { createErrorObject } from 'components/ErrorInfo'
import Table from 'components/Table'
import {
  useMainProject,
  useProjectConfiguration,
  useProjectConfigurationChangeLogs,
  useUpdateProjectConfiguration,
} from 'hooks'
import { convertError } from 'models'
import { JSX, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export interface AlarmThresholdConfigProps {}

export function AlarmThresholdConfig(props: AlarmThresholdConfigProps): JSX.Element {
  const { data: changeLogs, ...changeLogsData } = useProjectConfigurationChangeLogs()
  const { data: mainProject } = useMainProject()
  const { data: projectConfiguration, ...projectConfigurationData } = useProjectConfiguration()
  const { t } = useTranslation()
  const { trigger: updateProjectConfiguration } = useUpdateProjectConfiguration()

  const onBlur = useCallback<NonNullable<ConfigValuesProps['onBlur']>>(
    async (name, values) => {
      if (mainProject == null || values.length === 0) {
        return
      }

      let loadingToast: Awaited<ReturnType<typeof showToast>>

      try {
        loadingToast = await showToast({
          message: (
            <div className="d-flex align-items-center">
              {t('Updating thresholds')} <IxSpinner className="ms-1" />
            </div>
          ),
          autoClose: false,
        })
      } catch (error) {
        console.error(error)
      }

      try {
        await updateProjectConfiguration({ configuration: { [name]: values[0] }, projectId: mainProject.id })

        setTimeout(() => {
          loadingToast.close()
          showToast({ message: t('Successfully updated thresholds'), type: 'success' })
        }, 500)
      } catch (error) {
        setTimeout(() => {
          loadingToast.close()
          showToast({
            title: t('Updating thresholds was unsuccessful') ?? undefined,
            message: createErrorObject(convertError(error)).message.join('\n\n'),
            type: 'error',
          })
        }, 500)
      }
    },
    [mainProject, t, updateProjectConfiguration],
  )

  return (
    <>
      <IxContentHeader className="mb-4" headerTitle={t('Configure thresholds for alarm types') ?? undefined} />

      <div className="row mb-5">
        <div className="col-8">
          <div>
            <Trans
              components={{
                li: <li />,
                p: <p />,
                ul: <ul />,
              }}
              i18nKey="AlarmThresholdConfig"
            />
          </div>
        </div>
      </div>

      <ContextWrapper
        data={projectConfiguration}
        error={projectConfigurationData.error}
        isLoading={projectConfigurationData.isLoading}
      >
        {(configuration) => (
          <>
            <ConfigValues
              className="mb-5"
              description={t(
                'A warning alarm is triggered if the health score for a turnout falls below the defined warning threshold.',
              )}
              name="thresholdWarning"
              onBlur={onBlur}
              title={t('Warning alarm')}
              values={[{ label: t('Threshold'), value: configuration.thresholdWarning }]}
            />

            <ConfigValues
              className="mb-5"
              description={t(
                'A failure alarm is triggered if the health score for a turnout falls below the defined alarm threshold.',
              )}
              name="thresholdFailure"
              onBlur={onBlur}
              title={t('Failure alarm')}
              values={[{ label: t('Threshold'), value: configuration.thresholdFailure }]}
            />
          </>
        )}
      </ContextWrapper>

      <div className="fs-6">{t('Changelog')}</div>

      <Table
        className="mt-2"
        columnDefs={[
          {
            field: 'nameOfTheUser',
            headerName: t('User') ?? undefined,
          },
          {
            field: 'oldValue',
            headerName: t('Value changed from') ?? undefined,
          },
          {
            field: 'newValue',
            headerName: t('Value changed to') ?? undefined,
          },
          {
            field: 'changedAt',
            headerName: t('Date') ?? undefined,
          },
        ]}
        error={changeLogsData.error}
        isLoading={changeLogsData.isLoading}
        rowData={changeLogs}
      />
    </>
  )
}
