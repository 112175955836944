import { AlarmEventApiClient, QueryAlarmEventsFilterOpts } from 'api'
import { Filter, SearchField } from 'components/DataFilter'
import { useMainProject, usePointMachineSearchFields, useTurnouts } from 'hooks'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, PagedAlarmEvents } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Key } from 'swr'

export const getQueryAlarmsKey = (opts: Parameters<typeof useQueryAlarms>[0], parentId?: IdType): Key => {
  if (parentId == null) {
    return null
  }

  return ['useQueryAlarms', parentId, JSON.stringify({ ...opts, from: opts?.from?.toISO(), to: opts?.to?.toISO() })]
}

export const useQueryAlarms = (opts?: Partial<QueryAlarmEventsFilterOpts> | null): SWRResponse<PagedAlarmEvents> => {
  const { data: mainProject, ...mainProjectData } = useMainProject()
  const { data: turnouts, ...turnoutsData } = useTurnouts()

  const swrResponse = useSWR(getQueryAlarmsKey(opts, mainProject?.id), async () => {
    return AlarmEventApiClient.query(mainProject!.id, opts!)
  })

  const data = useMemo<PagedAlarmEvents | undefined>(() => {
    if (swrResponse.data == null) {
      return undefined
    }

    return {
      ...swrResponse.data,
      content: swrResponse.data.content.map((entry) => ({
        ...entry,
        _turnout: turnouts?.find((turnout) => turnout.id === entry.turnout.id),
      })),
    }
  }, [swrResponse.data, turnouts])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || turnoutsData.isLoading || mainProjectData.isLoading,
      isValidating: swrResponse.isValidating || turnoutsData.isValidating || mainProjectData.isValidating,
      mutate: async () => {
        await Promise.all([swrResponse.mutate(), turnoutsData.mutate()])
        return undefined
      },
    }
  }, [data, mainProjectData.isLoading, mainProjectData.isValidating, swrResponse, turnoutsData])
}

export const useAlarmSearchFields = (filters: Filter[], collapsible = false): SearchField[] => {
  const pointMachineSearchFields = usePointMachineSearchFields(filters, collapsible)
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        collapsible,
        label: t('models.alarmEvent.state'),
        options: [
          { label: t('models.alarmEvent.state-active'), value: 'active' },
          { label: t('models.alarmEvent.state-resolved'), value: 'resolved' },
        ],
        path: 'state',
        type: 'select',
      },
      {
        collapsible,
        label: t('models.alarmEvent.type'),
        options: [
          { label: t('models.alarmEvent.severity-warning'), value: 'warning' },
          { label: t('models.alarmEvent.severity-failure'), value: 'failure' },
        ],
        path: 'severity',
        type: 'select',
      },
      ...pointMachineSearchFields,
    ]
  }, [collapsible, pointMachineSearchFields, t])
}
