import { IdType, PointMachineEvent, TurnoutTrendCurve, TurnoutTrendCurveCodec } from 'models'
import { ApiClientResponse, BaseApiClient } from './base'
import { DateRangeFilterOpts, Methods } from './utils'

export interface TurnoutTrendCurveFilterOpts extends DateRangeFilterOpts, Pick<PointMachineEvent, 'direction'> {
  pointMachineName: string
}

class TurnoutTrendCurveApiClient extends BaseApiClient {
  async trends(
    turnoutId: IdType,
    opts: Partial<TurnoutTrendCurveFilterOpts> | null | undefined,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<TurnoutTrendCurve>> {
    const path = [this.baseUrl, this.endpointUrl, turnoutId, 'trends-by-direction'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts, signal)
    return { ...rest, data: await this.decode(data, TurnoutTrendCurveCodec) }
  }
}

const instance = new TurnoutTrendCurveApiClient('turnouts')

export { instance as TurnoutTrendCurveApiClient }
