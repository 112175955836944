import { IxSpinner } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import Empty from 'components/Empty'
import ErrorInfo, { ErrorInfoProps } from 'components/ErrorInfo'
import { JSX, ReactNode } from 'react'

export interface ContextWrapperProps<T = any> {
  children?: ReactNode | ((v: T) => ReactNode)

  /**
   * The data to check. If it is `null` or `undefined` renders `emptyComponent`.
   */
  data: T | null | undefined

  /**
   * This will be checked before `data`. When the error is not null and is defined, renders
   * `errorComponent`.
   */
  error?: ErrorInfoProps['error'] | null

  /**
   * Is data currently being fetched? If true, renders `<Progress />`.
   */
  isLoading?: boolean

  spinnerProps?: IxJSX.IxSpinner
}

/**
 * Checks the passed parameters and renders a progress indicator or an error message before it
 * renders its children. By passing `data={false}` the data will not be checked for `null`.
 */
export function ContextWrapper<T>({
  children,
  data,
  error,
  isLoading,
  spinnerProps,
}: ContextWrapperProps<T>): JSX.Element {
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center p-4">
        <IxSpinner {...spinnerProps} size={spinnerProps?.size ?? 'large'} />
      </div>
    )
  }

  if (error != null) {
    return <ErrorInfo error={error} />
  }

  if (typeof data === 'boolean' && data === false) {
    if (typeof children === 'function') {
      return <>{children(data)}</>
    }

    return <>{children}</>
  }

  const hasNullElements = Array.isArray(data) && data.filter((d) => d == null).length === data.length

  if (data == null || hasNullElements) {
    return <Empty />
  }

  if (typeof children === 'function') {
    return <>{children(data)}</>
  }

  return <>{children}</>
}
