import * as t from 'io-ts'
import { BaseModelCodec, IdTypeCodec } from './base'

export const BaseDataSeriesCodec = t.type({
  sensorTypeId: IdTypeCodec,
  start: t.string,
  values: t.array(t.number),
})
export const BaseDataSeriesListCodec = t.array(BaseDataSeriesCodec)
export type BaseDataSeries = t.TypeOf<typeof BaseDataSeriesCodec>

export const DataSeriesCodec = t.intersection([BaseModelCodec, BaseDataSeriesCodec])
export const DataSeriesListCodec = t.array(DataSeriesCodec)
export type DataSeries = t.TypeOf<typeof DataSeriesCodec>
