import { IxIcon } from '@siemens/ix-react'
import { JSX, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'
import * as S from './styles'

export interface AvatarProps {
  size?: number
}

export function Avatar({ size = 32 }: AvatarProps): JSX.Element {
  const { user } = useAuth()

  const initials = useMemo(() => {
    if (user?.profile.given_name == null || user.profile.family_name == null) {
      return undefined
    }

    return (user.profile.given_name.charAt(0) + user.profile.family_name.charAt(0)).toUpperCase()
  }, [user])

  return (
    <S.AvatarWrapper size={size}>
      <S.AvatarText>{initials ?? <IxIcon name="user" />}</S.AvatarText>
    </S.AvatarWrapper>
  )
}
