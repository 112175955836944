import { QueryAlarmEventsFilterOpts } from 'api'
import AlarmTabs from 'components/AlarmTabs'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

export interface AlarmsTileProps {
  filterOpts: Partial<QueryAlarmEventsFilterOpts>
}

export function AlarmsTile({ filterOpts }: AlarmsTileProps): JSX.Element {
  const { t } = useTranslation()

  return <AlarmTabs filterOpts={filterOpts} title={t('Alarms overview')} />
}
