import Table, { TableProps } from 'components/Table'
import { Turnout } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import ActionsCell from './components/ActionsCell'

export interface TurnoutConfigTableProps extends TableProps<Turnout> {
  data?: Turnout[]
}

export function TurnoutConfigTable({ data, ...rest }: TurnoutConfigTableProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Table
      {...rest}
      columnDefs={[
        {
          field: 'name',
          headerName: t('Name') ?? undefined,
          initialSort: 'asc',
          sortable: true,
          unSortIcon: true,
        },
        {
          field: 'engineeringId',
          headerName: 'Turnout ID',
          sortable: true,
          unSortIcon: true,
        },
        {
          colId: 'technicalRoomInterlockingName',
          field: '_technicalRoom._interlocking.name',
          headerName: 'Interlocking',
          sortable: true,
          unSortIcon: true,
        },
        {
          field: 'configurationStatus',
          headerName: t('models.turnout.configurationStatus') ?? undefined,
          sortable: true,
          unSortIcon: true,
          valueGetter: ({ data }) =>
            data == null ? undefined : t(`models.turnout.configurationStatus-${data?.configurationStatus}`),
        },
        {
          cellRenderer: ActionsCell,
          field: 'id',
          headerName: t('Actions') ?? undefined,
        },
      ]}
      rowData={data}
      selectable
      sortingOrder={['desc', 'asc']}
    />
  )
}
