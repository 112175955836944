import { PackageId } from 'typescript'

// === AUTH ===
// export const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID ?? ''
// export const authDomain = process.env.REACT_APP_AUTH_DOMAIN ?? ''

export const authRedirectUri = window.location.origin + window.location.pathname

// === BACKEND INTEGRATION ===
// export const dataServiceUrl = process.env.REACT_APP_DATA_SERVICE_URL ?? ''

// === MISC ===
export const appTitle = 'APM'
export const storagePrefix = `[${appTitle}].`
export const gitHash = process.env.REACT_APP_GIT_HASH ?? process.env.NODE_ENV ?? ''
export const buildDate = process.env.REACT_APP_BUILT_AT ?? ''

let pJson: PackageId | undefined = undefined
try {
  pJson = require('root/package.json')
} catch (error) {
  console.error('package.json could not be found in the root.')
}

export { pJson }
