// This file exists to avoid circular imports with ./turnout.ts

import * as t from 'io-ts'
import { BaseModelCodec, DirectionCodec, IdTypeCodec, NamedTypeCodec } from './base'

export const BasePointMachineCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    engineeringId: t.string,
    fixedDirection: DirectionCodec,
    position: t.number,
    serialNumber: t.string,
    turnoutId: IdTypeCodec,
    typeId: IdTypeCodec,
  }),
])
export const BasePointMachinesCodec = t.array(BasePointMachineCodec)
export type BasePointMachine = t.TypeOf<typeof BasePointMachineCodec>

export const RawPointMachineCodec = t.intersection([BaseModelCodec, BasePointMachineCodec])
export const RawPointMachinesCodec = t.array(RawPointMachineCodec)
export type RawPointMachine = t.TypeOf<typeof RawPointMachineCodec>
